$font: 'Myriad', sans-serif !important;
$primary-color: #0070c9;
$green: #59b57e;

$overlay-bg: rgba(0, 0, 0, 0.8);
$dialog-radius: 16px;
$dialog-z: 9998;
$dialog-bg: white;
$yodal-z: 9999;
$ease: cubic-bezier(0.23, 1, 0.32, 1);
$button-height: 40px;
$header-height: 80px;
$footer-height: 80px;
$header-button-size: 90px;

$input-border-color: #c7c7c7;
$input-padding: 8px;
$input-font-size: 16px !important;

$red: #d64727;

#AdminFont {
  font-family: $font;
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  small,
  span {
    font-family: $font;
    font-weight: 500;
    color: #252525;
  }

  .muted {
    color: #878787;
  }
}

.admin-modal {
  font-family: $font;
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  .header-title,
  .back-button,
  .input-group,
  .input-group-addon {
    font-family: $font;
    font-weight: 500;
    text-decoration-line: none;
    margin: 0px;
  }

  .admin-modal_backdrop {
    background: rgba(0, 0, 0, 0.9) !important;
    z-index: $dialog-z;
  }

  .admin-modal_container {
    .admin-modal_dialog {
      border-radius: $dialog-radius;
      border: none;
      box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.13);
      overflow: hidden;
      width: 100% !important;
      max-width: 1000px !important;
      height: 100% !important;
      max-height: 80vh !important;

      .admin-modal_dialog_inner_container {
        position: relative;
        display: block;
        border: none;
        border-radius: none;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .admin-modal_header,
        .admin-modal_footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: white;
          padding: 0px 16px;
        }

        .admin-modal_header {
          height: $header-height;
          justify-content: center;
          border-bottom: 1px solid #e4e4e4;

          .back-button {
            cursor: pointer;
            color: #878787;
            justify-content: flex-start;
            text-align: left;
            width: $header-button-size;
            transition: all 0.5s $ease;
            background-color: transparent;
            &:hover {
              color: darken(#878787, 10%);
            }
          }

          .close {
            width: $header-button-size;
          }

          .modal-title {
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;
            text-align: center;
          }

          .header-title {
            flex: 1;
            font-weight: 500;
            color: black;
            font-size: 20px !important;
          }
        }

        .admin-modal_body {
          position: relative;
          width: 100%;
          height: calc(100% - #{$footer-height} - #{$header-height});
          overflow-y: auto;
          background: inherit;

          &.no-footer {
            height: calc(100% - #{$header-height});
          }

          &.autoOrderModal {
            padding: 0px;
          }
        }

        .admin-modal_footer {
          z-index: $dialog-z;
          background: white;
          height: $footer-height;
        }
      }
    }
  }

  &.overlayed {
    .admin-modal_container .admin-modal_dialog {
      background: rgba(0, 0, 0, 0.4);

      .admin-modal_dialog_inner_container {
        margin: 0px auto;
        max-width: 600px !important;
        max-height: 60vh !important;
        box-shadow:
          0 1px 1px rgba(0, 0, 0, 0.11),
          0 2px 2px rgba(0, 0, 0, 0.11),
          0 4px 4px rgba(0, 0, 0, 0.11),
          0 8px 8px rgba(0, 0, 0, 0.11),
          0 16px 16px rgba(0, 0, 0, 0.11),
          0 32px 32px rgba(0, 0, 0, 0.11);
        overflow: hidden;
      }
    }
  }

  .section_number,
  .section_title {
    font-family: $font;
    color: #000;
  }
  .section_number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #000;
    margin-right: 16px;
  }

  .section_title {
    font-size: 24px;
    font-weight: 300 !important;
  }

  .text-blue {
    color: $primary-color !important;
  }

  .muted {
    color: #878787 !important;
  }
}

#AdminForm {
  /*-- Font Style --*/
  label,
  input,
  input::placeholder,
  select {
    width: 100%;
    font-family: $font;
    font-weight: 500;
    text-align: left;
  }

  label {
    font-size: 15px;
    font-weight: 500;
    color: #6a7070;
    margin-bottom: 2px;
  }

  .form-group {
    margin-bottom: 1.5em;
  }

  &.inline-group {
    .form-group {
      display: flex;
      align-items: center;

      label {
        display: inline-flex;
        margin-right: 16px;
        margin-bottom: 0;
        width: 150px;
        white-space: nowrap;
      }

      .d-flex {
        width: 100%;
        align-items: center;
      }
      .form-control {
        flex: 1;
        width: 100%;
      }
    }
  }

  /*-- Input Style --*/
  input,
  textarea,
  select {
    display: block;
    border-radius: 4px;
    border: 1px solid $input-border-color !important;
    outline: none;
    padding: $input-padding;
    transition: all 0.5s $ease;
    font-size: $input-font-size !important;
    color: #252525;
    font-weight: 500 !important;
    caret-color: $primary-color;

    &:hover {
      border-color: darken($input-border-color, 15%) !important;
    }

    &:focus,
    &:active {
      border-color: $primary-color !important;
    }

    &::placeholder {
      color: #c0c0c0;
    }

    &:disabled {
      background-color: #f2f2f2 !important;
      cursor: not-allowed;
    }
  }

  /* -- Price Input --*/

  .input-group {
    .input-group-addon {
      text-align: center;
      background: #f1f3f6;
      padding: 5px 10px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid $input-border-color;
    }

    input {
      width: 100%;
      flex: 1;
      margin-left: -1px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.admin_input-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  margin: 0px;
  background: #f2f2f2;

  input,
  .input-search-button {
    height: 50px !important;
  }

  input {
    width: 100%;
    flex: 1;
    background-color: #f2f2f2 !important;
    border: none !important;
  }

  .form-group {
    width: 100%;
    margin: 0px;
  }

  .input-search-button {
    background: #f2f2f2;
    //background: $primary-color;
    padding: 0px 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    outline: none;
    border: none;
    color: $primary-color;
    transition: all 200ms $ease;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}

.admin_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  font-family: $font;
  font-size: 18px;
  background: $primary-color;
  color: white;
  border: none;
  outline: none;
  margin: 0px 4px;
  padding: 12px 24px;
  border-radius: 4px;
  transition: all 200ms $ease;

  &.input_button {
    padding: $input-padding;
    font-size: $input-font-size;
  }

  &:hover {
    background: darken($primary-color, 5%);
  }

  &.secondary {
    color: $primary-color;
    background: transparentize($primary-color, 0.9);

    &:hover {
      background: transparentize($primary-color, 0.85);
    }
  }

  &.green {
    background: $green;

    &:hover {
      background: darken($green, 10%);
    }
  }
}

.admin_alert-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparentize($red, 0.9);
  width: 100%;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: $font;
  font-size: 16px;
  color: $red;

  span {
    font-family: $font;
  }
}

.selectable-card {
  position: relative;
  background: white;
  width: 100%;
  margin: 0px auto;
  padding: 16px;
  border: 2px solid #e4e4e4;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s $ease;
  display: flex;
  align-items: flex-start;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);

  &:hover {
    border-color: darken(#e4e4e4, 15%);
  }

  &.active {
    border-color: $primary-color;
    border-width: 2px;
  }

  &.readOnly {
    //box-shadow: none;
    border: 1px solid #e4e4e4;
    overflow: hidden;
    padding: 0px;
    cursor: default;

    &:hover {
      border-color: #e4e4e4;
    }
  }

  .selectable-card-header {
    border-bottom: 1px solid #e4e4e4;
    background: #fafafa;
    padding: 16px;
  }

  .selectable-card-row {
    padding: 16px;
    border-bottom: 1px solid #e4e4e4;
    &:last-child {
      border: none;
    }
  }

  h4,
  h5,
  p,
  span,
  label,
  .selectable-card-title,
  .badge,
  .price-label {
    font-family: $font;
    margin: 0px;
  }

  .radio_icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #ccc;
    transition: all 200ms $ease;

    &.checked {
      border-color: $primary-color;
      border-width: 5px;
    }
  }

  .selectable-card-banner {
    position: absolute;
    left: 0px;
    top: 8px;
    align-items: center;
    background: $green;
    padding: 4px 12px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    span {
      font-size: 14px;
      color: white;
      font-weight: 600;
    }
  }

  .selectable-card-img {
    width: 90px;
    height: auto;
    margin-right: 24px;
    object-fit: contain;
    object-position: center;
  }

  .selectable-card-title {
    color: '#000';
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin-right: 16px;
  }

  .alert-warning {
    display: inline-block;
    margin-top: 8px;
    padding: 5px 24px;
    border-radius: 4px;
    background: #fcf8e3;
    border: 1px solid #9c722a;
    p {
      color: #9c722a;
      font-size: 0.9em;
    }
  }

  .badge {
    font-size: $font;
    font-weight: 600;
    background: #75c695;
    font-size: 14px;
    border-radius: 3px;
    color: white;
  }

  .price-label {
    font-size: 14px;
    font-weight: 500;
    color: #878787;
  }
}

.inline-link {
  align-items: center;
  text-decoration-line: none !important;
  border-radius: 4px;
  transition: all 0.5s $ease;
  color: $primary-color;
  margin: 0px;

  &:hover {
    text-decoration-line: none;
    background-color: transparentize($primary-color, 0.95) !important;
  }
}

.select-dropdown {
  .form-group {
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    label,
    select,
    .form-control {
      font-family: $font;
      font-weight: 500;
      font-size: 16px;
      color: $primary-color;
      margin: 0px !important;
    }

    label {
      color: #878787 !important;
    }

    .form-control,
    select {
      width: auto;
      color: $primary-color !important;
      padding-right: 24px;
      height: auto;
      border-radius: 4px;
      cursor: pointer !important;
      transition: all 0.5s $ease;

      &:hover {
        background-color: transparentize($primary-color, 0.95) !important;
      }
    }
  }
}

.admin_order_summary_table {
  border-radius: 3px;
  overflow: hidden;

  p {
    font-family: $font;
    color: #333;
  }

  tbody,
  thead,
  th,
  tr,
  td {
    border: none;
  }

  tr {
    th {
      padding: 12px 8px;
      font-family: $font;
      font-size: 16px;
      font-weight: 500;
      color: #878787;
      background: #f2f2f2;
      border-bottom: none !important;
      border-top: none;

      &:first-child {
        border-bottom-left-radius: 3px;
      }
      &:last-child {
        border-bottom-right-radius: 3px;
      }
    }

    td {
      padding: 18px 8px;
      border-bottom: 1px solid #e4e4e4;
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}
