$font: 'Myriad', sans-serif !important;
$primary-color: #0070c9;
$blue: #1a73e8;
$red: #d44f31;
$faded: #e4edf8;
$ease: cubic-bezier(0.23, 1, 0.32, 1);

.hover-anchor {
  &:hover {
    .hover-hide {
      display: none !important;
    }
    .hover-show {
      display: inherit !important;
    }
  }
  .hover-show {
    display: none !important;
  }
}

.hover-underline:hover {
  text-decoration: underline;
}

@keyframes fInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

#visit-dropdown-modal {
  position: relative;
  display: flex;
  justify-content: center;

  .visit-dropdown-title {
    pointer-events: auto;
    border-bottom: 1px solid #e4e4e4;
    padding: 16px;

    span,
    a {
      font-family: $font;
      font-weight: 500;
      font-size: 1em;
      color: #878787;
    }

    a {
      color: $blue;
      cursor: pointer;
    }
  }

  .options {
    width: 100%;
    pointer-events: auto;

    .options-item {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;
      transition: all 200ms ease;

      p {
        flex: 1;
        font-family: $font;
        font-weight: 600;
        font-size: 1em;
        color: #434343;
      }

      &:hover {
        background: $faded !important;
      }

      .radio-icon {
        transition: all 500ms $ease;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        border: 3px solid #ccc;
        margin-right: 16px;

        &.active {
          border: 7px solid $blue;
        }
      }
    }
  }

  .visit-dropdown-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms ease;
    text-decoration: none;
  }

  .visit-dropdown-menu {
    border: none;
    pointer-events: none;
    display: none;
    opacity: 0;
    position: absolute;
    background: white;
    border-radius: 8px;
    top: 100%;
    width: 250px;
    z-index: 9999;
    box-shadow:
      0 19px 38px rgba(0, 0, 0, 0.3),
      0 15px 12px rgba(0, 0, 0, 0.22);
    animation: fInUp 200ms ease forwards;
    &.open {
      display: block;
      opacity: 1;
    }
  }
}

.visit_card {
  margin: 0px auto;
  width: 100%;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-left: 6px solid $red;
  border-radius: 4px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);

  .status-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    background: #fff !important;
    border-radius: 50px;
    border: 1px solid #e4e4e4;

    transition: all 200ms $ease;
    font-family: $font;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 1.5em;
    color: #434343;

    i {
      color: #878787 !important;
    }

    &:hover {
      border-color: #ccc;
    }
  }
}

#VisitList {
  padding: 24px;
  background: #fafafa;

  .visit_alert {
    background: transparentize($red, 0.9);
    color: $red;
    font-family: $font;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 500;
    padding: 8px;
    margin-bottom: 12px;
  }

  .inline-button {
    pointer-events: all;
    transition: all 200ms ease;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 12px;
    color: #1a73e8;
    text-decoration: none;
    &:hover {
      background: #e4edf8;
    }
  }
}
