$font: 'Myriad', sans-serif;

.confirmModal {
  width: 100%;
  max-width: 600px !important;
  height: 100%;

  p,
  h1,
  h2,
  h5,
  h6,
  a,
  b,
  span,
  button,
  label {
    font-family: $font;
    margin: 0px;
    line-height: 1.35em;
    text-rendering: optimizeLegibility;
    color: #231e1a;
    text-decoration: none !important;
  }

  #dm-input {
    input {
      font-family: $font;
      border-radius: 4px;
      outline: none;
      border: 1px solid #e4e4e4 !important;
      transition: all 200ms ease;

      &:hover {
        border-color: darken(#e4e4e4, 5%) !important;
      }

      &:active,
      &:focus {
        border-width: 2px;
        border-color: #0070c9 !important;
      }
    }
  }

  button {
    display: inline-flex;
    align-items: center;
    padding: 12px 18px;
    border-radius: 6px;
    font-weight: 500;
    border: none;
    outline: none;
    transition: all 200ms ease;

    &.primary {
      background: #0070c9;
      color: white;

      &:hover {
        background: darken(#0070c9, 5%);
      }
    }

    &.secondary {
      background: none;
      color: #0070c9;

      &:hover {
        background: transparentize(#0070c9, 0.9);
        color: darken(#0070c9, 5%);
      }
    }
  }

  .error-blurb {
    border: 1px solid transparentize(#df3d36, 0.6);
    background: #fdf5f5;
    padding: 12px 16px;
    border-radius: 4px;

    p {
      color: #df3d36;
    }
  }

  .modal-content {
    position: relative;
    border: none !important;
    border-radius: 10px;
    padding: 0px;
    overflow: hidden;
    height: 100%;
    max-height: 90vh;

    .modal-body {
      height: 100%;
      padding: 0px;

      .dm-scroll-body {
        padding: 24px;
        margin: 0px auto;
        height: calc(100% - 70px);
        overflow-y: scroll;
        overflow-x: hidden;

        .dm-container {
          width: 100%;
          max-width: 500px;
          margin: 0px auto;
        }

        .dm-header {
          margin: 24px 0px;

          .dm-blurb {
            background: #f1f3f6;
            padding: 16px;
            border-radius: 5px;
            width: 100%;
          }

          .dm-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto;
            margin-bottom: 1em;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            border: 12px solid #f7e1d4;
            background: #e64c3f;
            font-family: $font;
            font-weight: 800;
            color: white;
          }

          h2 {
            font-size: 24px;
            font-weight: 700;
            color: #2d303a;
            text-align: center;
            margin-bottom: 0.3em;
            text-align: center;

            @media (max-width: 576px) {
              font-size: 22px;
            }
          }

          p {
            font-size: 1em;
            line-height: 1.4em;
            font-weight: 500;
            color: #666a72;
            text-align: center;

            @media (max-width: 576px) {
              font-size: 15px;
            }
          }
        }

        .dm-body {
          margin: 24px 0px;
          flex: 1;
          overflow-y: scroll;

          .card {
            border: 2px solid #e4e4e4;
            padding: 1em;
            text-decoration: none;
            transition: all 200ms ease;

            &:hover {
              border-color: #0070c9;
            }

            h5 {
              font-size: 18px;
              font-weight: 700;
              color: #2d303a;
            }

            p {
              color: #666a72;
            }
          }
        }
      }
      .dm-footer {
        height: 70px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #f1f3f6;
        padding: 0px 16px;
      }
    }

    .modal-footer {
      display: none;
    }
  }
}
