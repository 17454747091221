/*** Font Weight ***/
/*** Color Guide ***/
/*** Button Styles ***/
.dropdown .btn-secondary {
  font-family: "Myriad", sans-serif;
  display: inline-block;
  font-size: 16px;
  background: none !important;
  color: #222 !important;
  font-weight: 600 !important;
  padding: 1em 1em;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}

.blurred {
  filter: blur(0.5rem) !important;
}

.button-border {
  width: 100%;
  border-radius: 4px;
  padding: 1em 1em;
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  outline: none;
  border: 1px solid #dee1e5;
  cursor: pointer;
  transition: all 200ms ease;
  background: white;
  color: #1069c9;
}
.button-border:hover {
  border-color: #1069c9;
}

.button-blue {
  width: 100%;
  border-radius: 4px;
  padding: 1em 1em;
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  background: #1069c9;
  color: white;
}
.button-blue:hover {
  background: #0c509a;
}
.button-blue.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button-dark {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer !important;
  background: #479dc8;
  border: 1px solid #479dc8;
  border-radius: 0px;
  color: #fff;
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 15px;
  padding: 0.75em 1em;
  border-radius: 4px;
  transition: all 200ms ease;
}
.button-dark.bg {
  font-size: 20px;
  padding: 1em 2em;
}
.button-dark.w-100 {
  width: 100%;
}
.button-dark:hover {
  background: #3891bd;
  color: white;
}
.button-dark.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.button-dark p {
  color: #fff;
  font-family: "Myriad", sans-serif;
  font-weight: 600 !important;
  font-size: 15px;
}
.button-dark p.badge {
  background: rgba(0, 0, 0, 0.25);
}

.button-light {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  background: #f1f3f6;
  border: 1px solid #f1f3f6;
  border-radius: 0px;
  color: #141b41;
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  padding: 0.75em 1em;
  border-radius: 4px;
  transition: all 200ms ease;
}
.button-light.w-100 {
  width: 100% !important;
}
.button-light:hover {
  border-color: #c2cbd8;
  color: #020307;
}

#newForm .form-control:disabled,
#newForm .form-control[readonly] {
  background: #f2f2f2 !important;
  color: rgba(0, 0, 0, 0.4) !important;
  border: none !important;
}
#newForm .form-control:disabled:hover, #newForm .form-control:disabled:focus, #newForm .form-control:disabled:active, #newForm .form-control:disabled:visited,
#newForm .form-control[readonly]:hover,
#newForm .form-control[readonly]:focus,
#newForm .form-control[readonly]:active,
#newForm .form-control[readonly]:visited {
  color: rgba(0, 0, 0, 0.4) !important;
  border: none !important;
  box-shadow: none !important;
}
#newForm .form-group label,
#newForm .form-group .form-check-label {
  font-size: "Myriad", sans-serif !important;
  font-size: 16px !important;
}
#newForm .form-group label.d-block,
#newForm .form-group .form-check-label.d-block {
  font-weight: 600 !important;
}
#newForm .form-group .form-check-label {
  color: #6c757e !important;
}
#newForm .form-group .item {
  padding: 0px !important;
  font-family: "Myriad", sans-serif !important;
  font-size: 16px !important;
  border: none !important;
  color: #6c757e !important;
}
#newForm .form-group .Select.form-control {
  padding: 0px !important;
  border: none !important;
}
#newForm .form-group .Select.form-control input {
  width: 100% !important;
  border: none !important;
}
#newForm .form-group input,
#newForm .form-group textarea {
  border: 1px solid #cad1d7 !important;
  border-radius: 0.2em !important;
  -webkit-appearance: none;
  color: #475a71 !important;
  background: white;
  font-family: "Myriad", sans-serif !important;
  font-weight: 500;
}
#newForm .form-group input:hover, #newForm .form-group input:focus, #newForm .form-group input:active, #newForm .form-group input:visited,
#newForm .form-group textarea:hover,
#newForm .form-group textarea:focus,
#newForm .form-group textarea:active,
#newForm .form-group textarea:visited {
  color: black !important;
  border-color: #adb8c1 !important;
  box-shadow: inset 0 1px #e0e6e8 !important;
}
#newForm .form-group input::-webkit-input-placeholder,
#newForm .form-group textarea::-webkit-input-placeholder {
  color: #8898aa !important;
  font-family: "Myriad", sans-serif !important;
}

#new_user_profile {
  background: #fff;
  max-width: 100%;
  overflow-x: hidden;
}
#new_user_profile p,
#new_user_profile h1,
#new_user_profile h2,
#new_user_profile h3,
#new_user_profile h4,
#new_user_profile h5,
#new_user_profile h6,
#new_user_profile label,
#new_user_profile small,
#new_user_profile span,
#new_user_profile button,
#new_user_profile ul,
#new_user_profile li,
#new_user_profile .badge {
  font-family: "Myriad", sans-serif;
  font-weight: 500;
  margin: 0;
}
#new_user_profile .nav--notFixed {
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}
#new_user_profile .button-circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #4680ca;
  cursor: pointer;
  box-shadow: 0 9px 13px 4px rgba(70, 128, 202, 0.2);
  border: 1px solid #4680ca;
  outline: none;
  transition: all 200ms ease;
}
#new_user_profile .button-circle:hover {
  background: #3773c0;
  border-color: #3773c0;
}
#new_user_profile .button-circle.sm {
  width: 35px;
  height: 35px;
  box-shadow: 0 4px 8px 4px rgba(70, 128, 202, 0.2);
}
#new_user_profile .button-circle.secondary {
  background: white;
  box-shadow: none;
  border: 1px solid black;
  cursor: pointer;
}
#new_user_profile .button-circle.secondary:hover {
  background: white;
  border-color: #bec6da;
}
#new_user_profile .u-title-block h5 {
  color: black;
  font-size: 16px;
  font-weight: 500;
}
#new_user_profile .u-title-block h1 {
  font-size: 24px;
  font-weight: 500;
  color: #4680ca !important;
  line-height: 1.75em;
}
#new_user_profile .u-title-block .text-muted {
  color: #8898aa !important;
  font-size: 16px;
  font-weight: 500;
}
#new_user_profile .u-title-block .button-dark,
#new_user_profile .u-title-block .button-light {
  display: block;
}
#new_user_profile .u-left {
  background: white;
  border-right: 1px solid #e4e4e4;
  height: 100%;
  width: 100%;
  padding: 0em;
}
#new_user_profile .u-left .alert-notification {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  background: #fafafa;
  border: 1px solid #e4e4e4;
}
#new_user_profile .u-left .alert-notification p {
  font-family: "Myriad", sans-serif;
  font-size: 14px;
  color: #222;
  font-weight: 600;
  margin-left: 0.2em;
}
#new_user_profile .u-left .tr-list .tr-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
  position: relative;
}
#new_user_profile .u-left .tr-list .tr-item:before {
  content: " ";
  height: 100%;
  width: 2px;
  background: #eff6ff;
  display: block;
  left: 18px;
  position: absolute;
  z-index: 2;
}
#new_user_profile .u-left .tr-list .tr-item:first-child:before {
  content: " ";
  height: 50%;
  bottom: 0px;
}
#new_user_profile .u-left .tr-list .tr-item:last-child:before {
  content: " ";
  height: 50%;
  top: 0px;
}
#new_user_profile .u-left .tr-list .tr-item .tr-number {
  background: #eff6ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  color: #2643f7;
  font-family: "Myriad", sans-serif;
  font-size: 12px;
  font-weight: 800;
  z-index: 10;
}
#new_user_profile .u-left .tr-list .tr-item .tr-text {
  flex: 1;
}
#new_user_profile .u-left .tr-list .tr-item .tr-text p {
  font-weight: 600;
  color: #2e363e;
  line-height: 1.5em;
}
#new_user_profile .u-left .rwt__tabs .tab-header {
  background: #f6f9fc;
  padding: 0em 1em;
  padding-top: 0.3em;
}
#new_user_profile .u-left .rwt__tabs .tab-header h4 {
  font-weight: 600;
}
#new_user_profile .u-left .rwt__tabs .rwt__tabpanel {
  padding: 1em;
}
#new_user_profile .u-left .rwt__tabs .rwt__tablist .rwt__tab {
  padding-right: 1em;
  font-weight: 600 !important;
  font-size: 14px;
  color: #8898aa;
  font-family: "Myriad", sans-serif;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding-top: 1em;
  padding-bottom: 1em;
}
#new_user_profile .u-left .rwt__tabs .rwt__tablist .rwt__tab[aria-selected=false] {
  border-bottom: 2px solid transparent;
  color: #8898aa !important;
  transition: all 200ms ease;
}
#new_user_profile .u-left .rwt__tabs .rwt__tablist .rwt__tab[aria-selected=false]:hover {
  color: #607286 !important;
}
#new_user_profile .u-left .rwt__tabs .rwt__tablist .rwt__tab[aria-selected=true] {
  border-bottom: 2px solid #000;
  color: black;
}
#new_user_profile .u-center {
  border-radius: 4px;
  background: white;
  padding: 0em 2em;
  padding-bottom: 10vh;
}
@media (max-width: 767px) {
  #new_user_profile .u-center {
    padding-left: 1em;
    padding-right: 1em;
  }
}
#new_user_profile .u-center .disclaimer {
  font-size: 10px !important;
  color: #8898aa;
}
#new_user_profile .user-referral-card .button-dark .sm {
  font-size: 14px;
  padding: 0.3em 1em;
}
#new_user_profile .user-referral-card .referral-input {
  border: 2px dotted #e4e4e4;
  border-radius: 4px;
  width: 100%;
  flex: 1;
  padding: 8px;
  color: #4680ca;
  font-size: "Myriad", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}
#new_user_profile .waitingOnScheduleBlurb {
  padding: 32px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 1em;
  background: #1488cc;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2b32b2, #1488cc);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2b32b2, #1488cc);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
#new_user_profile .waitingOnScheduleBlurb .container-xs {
  max-width: 400px;
}
#new_user_profile .waitingOnScheduleBlurb .container-xs h2 {
  font-size: 18px !important;
  font-weight: 600;
  color: white;
  margin-bottom: 0.5em;
}
#new_user_profile .waitingOnScheduleBlurb .container-xs p {
  color: rgba(255, 255, 255, 0.75) !important;
}
#new_user_profile .waitingOnScheduleBlurb .btn-white {
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 0.75em 2em;
  color: #2b32b2;
  background: white;
  border-radius: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
}
#new_user_profile .waitingOnScheduleBlurb .btn-white:hover {
  background: rgba(255, 255, 255, 0.8);
}
#new_user_profile .card-section .padding-0 {
  padding: 0em !important;
}
#new_user_profile .card-section .card-box--shadow {
  padding: 1em;
  border: 1px solid #ececec;
  box-shadow: 0 0 9px 0 #f3f4f9;
  background: white;
  border-radius: 4px;
  margin-bottom: 0.5em;
}
#new_user_profile .card-section .card-box {
  background: white;
  margin-bottom: 1em;
}
#new_user_profile .card-section .card-box .card-part {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border: 1px solid #ececec;
  box-shadow: 0 0 9px 0 #f3f4f9;
  background: white;
  border-radius: 4px;
  margin-bottom: 0.5em;
}
#new_user_profile .card-section .card-box .card-part h4 {
  line-height: 1.5em;
  margin: 0px;
}
#new_user_profile .card-section .card-box .card-part badge {
  display: inline-block;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  background: #8898aa;
  color: white;
  font-family: "Myriad", sans-serif;
  margin-right: 1em;
}
#new_user_profile .card-section .card-box .card-box--header {
  padding: 1em 0em;
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #525f7f;
}
#new_user_profile .card-section .card-box .card-box--header h4 {
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #8898ac;
}
#new_user_profile .card-section .card-box .shipment-card btn {
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0em 1em;
  height: 40px;
  text-align: center;
  display: inline-flex;
  cursor: pointer;
  background: #333238;
  transition: all 200ms ease;
}
#new_user_profile .card-section .card-box .shipment-card btn:hover {
  background: #1a1a1d;
}
#new_user_profile .card-section .card-box .shipment-card btn.secondary {
  background: #eceff1;
  color: #2e3b48;
}
#new_user_profile .card-section .card-box .shipment-card btn.secondary:hover {
  background: #cfd6db;
  color: #2e3b48;
}
#new_user_profile .card-section .card-box .shipment-card .shipment-timeline {
  list-style: none !important;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}
#new_user_profile .card-section .card-box .shipment-card .shipment-timeline .shipment-timeline--item {
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
  position: relative;
}
#new_user_profile .card-section .card-box .shipment-card .shipment-timeline .shipment-timeline--item:first-child:before {
  content: " ";
  position: absolute;
  width: 2px;
  height: 100%;
  border-left: 2px dotted #55af7a;
  z-index: 1;
  left: 8px;
}
#new_user_profile .card-section .card-box .shipment-card .shipment-timeline .shipment-timeline--item .timeline--counter {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #55af7a;
  padding-top: 2px;
  font-family: "Myriad", sans-serif;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: #55af7a;
  background: white;
  z-index: 2;
}
#new_user_profile .card-section .card-box .shipment-card .shipment-timeline .shipment-timeline--item .timeline--text {
  margin-left: 16px;
}
#new_user_profile .card-section .card-box .card-box--content {
  padding: 1em 0em;
}
#new_user_profile .card-section .card-box .card-box--content.no-padding {
  padding: 0;
}
#new_user_profile .u-right {
  background: white;
  height: 100%;
  min-height: 100vh;
  border-left: 1px solid #e4e4e4;
}
@media (max-width: 767px) {
  #new_user_profile .u-right {
    border-top: 1px solid #e4e4e4;
    min-height: inherit;
  }
  #new_user_profile .u-right .payment-card {
    background: #f6f9fc !important;
  }
}
#new_user_profile .u-right .payment-card {
  padding: 1em;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
  transition: all 200ms ease;
}
#new_user_profile .u-right .payment-card:hover {
  background: #f6f9fc;
}
#new_user_profile .u-right .invoice-card {
  padding: 2em 1em;
}
#new_user_profile .u-right .invoice-card .copay-alert {
  font-family: "Myriad", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 1em;
  border-radius: 4px;
  border: 2px dotted #e4e4e4;
  line-height: 1.5em;
  margin-bottom: 1em;
}
#new_user_profile .u-right .invoice-card .copay-alert u {
  text-decoration-style: dotted;
  color: #4680ca;
}
#new_user_profile .u-right .invoice-card .invoice-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#new_user_profile .u-right .invoice-card .invoice-header badge {
  font-family: "Myriad", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  padding: 0.5em 1em;
  background: #eff6ff;
  color: #2b47f7;
}
#new_user_profile .u-right .signature-field {
  margin: 0.5em 0em;
}
#new_user_profile .u-right .signature-field .signature-box {
  margin-top: 0.2em;
  width: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 1.5em 1em;
}
#new_user_profile .u-right .signature-field .signature-box img {
  height: 50px;
}

#contractor-terms p,
#contractor-terms h1,
#contractor-terms h2,
#contractor-terms h3,
#contractor-terms h4,
#contractor-terms h5,
#contractor-terms h6,
#contractor-terms label,
#contractor-terms small,
#contractor-terms span,
#contractor-terms button,
#contractor-terms ul,
#contractor-terms li,
#contractor-terms .badge {
  font-family: "Myriad", sans-serif;
  font-weight: 500;
  margin: 0;
}
#contractor-terms h1 {
  margin-bottom: 0.3em !important;
}
@media (max-width: 500px) {
  #contractor-terms h1 {
    font-size: 24px !important;
  }
}
#contractor-terms h4 {
  font-size: 18px !important;
  margin-bottom: 0.5em;
}
#contractor-terms .container {
  max-width: 800px;
  margin: 0px auto;
}
#contractor-terms article {
  padding: 1em 0em;
}
#contractor-terms u {
  font-weight: 600;
}
#contractor-terms ul li {
  padding: 0.5em 0em;
}
#contractor-terms blurb {
  display: block;
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 1em;
  border-radius: 4px;
  margin: 0.5em 0em;
}
#contractor-terms blurb p {
  color: #8898aa;
  font-weight: 600;
}
#contractor-terms .header {
  padding: 2em 0em;
}
#contractor-terms .content-container {
  padding: 5vh 0vh;
  max-width: 800px;
  margin: 0px auto;
  margin: 1em;
}
#contractor-terms .contract-nav {
  background: #fff;
  box-shadow: inset 0 0 10px grey;
  padding: 2em 1em;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  padding-top: 120px;
  /* width */
  /* Track */
  /* Handle */
}
#contractor-terms .contract-nav h4 {
  font-weight: 600;
}
@media (max-width: 1000px) {
  #contractor-terms .contract-nav {
    display: none;
  }
}
#contractor-terms .contract-nav::-webkit-scrollbar {
  width: 3px;
}
#contractor-terms .contract-nav::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e4e4e4;
}
#contractor-terms .contract-nav::-webkit-scrollbar-thumb {
  background: #1e70bf;
  border-radius: 10px;
}
#contractor-terms .contract-nav ol {
  counter-reset: li;
  /* Initiate a counter */
  margin-left: 0;
  /* Remove the default left margin */
  padding-left: 0;
  /* Remove the default left padding */
}
#contractor-terms .contract-nav ol:hover li {
  opacity: 0.5;
  transition: all 200ms ease;
}
#contractor-terms .contract-nav ol > li {
  position: relative;
  /* Create a positioning context */
  margin: 0 0 6px 2em;
  /* Give each list item a left margin to make room for the numbers */
  padding: 4px 8px;
  /* Add some spacing around the content */
  list-style: none;
  /* Disable the normal item numbering */
  background: #fff;
  transition: all 200ms ease;
}
#contractor-terms .contract-nav ol > li:hover {
  opacity: 1;
}
#contractor-terms .contract-nav ol > li p {
  color: #222 !important;
  font-weight: 600;
  font-size: 14px;
}
#contractor-terms .contract-nav ol > li:before {
  content: counter(li);
  /* Use the counter as content */
  counter-increment: li;
  /* Increment the counter by 1 */
  /* Position and style the number */
  position: absolute;
  top: 3px;
  left: -2em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 25px;
  border-radius: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* Some space between the number and the content in browsers that support
       generated content but not positioning it (Camino 2 is one example) */
  margin-right: 8px;
  color: #8898aa;
  background: #f7f8f9;
  font-weight: 600;
  font-size: 12px;
  font-family: "Myriad", sans-serif;
  text-align: center;
}

.rodal_modal .user-card {
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  font-size: 14px;
  padding: 1em 0.5em;
  color: black;
  border-radius: 50px;
  text-align: center;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.rodal_modal .user-card .user-avatar {
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background: url("../../img/user-avatar-2.png");
  background-size: cover;
  background-position: center;
  margin-right: 0.5em;
}
.rodal_modal #rodal-body--action {
  width: 100%;
  height: 100%;
  max-width: none !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.rodal_modal #rodal-body--action .rodal-header {
  padding: 1em;
  background: #eaedf0;
}
.rodal_modal #rodal-body--action .rodal-header .rodal-header--title {
  font-family: "Myriad", sans-serif;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: #5b5d66;
}
.rodal_modal #rodal-body--action .rodal-content {
  padding: 3em 2em;
  padding-bottom: 5em;
}
.rodal_modal #rodal-body--action .rodal-footer {
  background: white;
  position: absolute;
  padding: 1em;
  border-top: 1px solid #e4e4e4;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.rodal_modal #rodal-body--action .rodal-footer .rodal-footer--btn {
  outline: none;
  background: white;
  border: 2px solid #e4e4e4;
  padding: 1em;
  border-radius: 4px;
  width: 100%;
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #8898aa;
  text-align: center;
}
.rodal_modal #rodal-body--action .rodal-footer .rodal-footer--btn.primary {
  background: #1069c9;
  border-color: #1069c9;
  color: white;
}
.rodal_modal.reschedule_modal.no-padding .rodal-dialog {
  padding: 0px !important;
}
.rodal_modal.reschedule_modal input,
.rodal_modal.reschedule_modal textarea {
  border: 1px solid #cad1d7 !important;
  border-radius: 0.2em !important;
  -webkit-appearance: none;
  color: #475a71 !important;
  background: white;
  font-family: "Myriad", sans-serif !important;
  font-weight: 500;
}
.rodal_modal.reschedule_modal input:hover, .rodal_modal.reschedule_modal input:focus, .rodal_modal.reschedule_modal input:active, .rodal_modal.reschedule_modal input:visited,
.rodal_modal.reschedule_modal textarea:hover,
.rodal_modal.reschedule_modal textarea:focus,
.rodal_modal.reschedule_modal textarea:active,
.rodal_modal.reschedule_modal textarea:visited {
  color: black !important;
  border-color: #adb8c1 !important;
  box-shadow: inset 0 1px #e0e6e8 !important;
}
.rodal_modal.reschedule_modal input::-webkit-input-placeholder,
.rodal_modal.reschedule_modal textarea::-webkit-input-placeholder {
  color: #8898aa !important;
  font-family: "Myriad", sans-serif !important;
}
.rodal_modal.reschedule_modal .rodal-dialog {
  max-width: 700px !important;
}
.rodal_modal.reschedule_modal .rodal-dialog .q-t {
  font-size: 18px !important;
  font-weight: 600 !important;
  display: none;
}
.rodal_modal.reschedule_modal .rodal-dialog h5 {
  font-size: 15px !important;
  display: none;
}
.rodal_modal.sm .rodal-dialog {
  max-width: 500px !important;
  height: 300px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em !important;
}
.rodal_modal.sm .rodal-dialog .rodal-body {
  max-width: 400px !important;
}
.rodal_modal.xs .rodal-dialog {
  max-width: 450px !important;
  height: 600px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2em !important;
}
.rodal_modal.xs .rodal-dialog .rodal-body {
  max-width: 350px !important;
  padding: 5vh 0vh !important;
}
.rodal_modal .rodal-mask {
  background: rgba(216, 221, 227, 0.5) !important;
}
.rodal_modal .ui.form textarea,
.rodal_modal .ui.form input {
  border: 1px solid #cad1d7;
  border-radius: 0.2em;
  -webkit-appearance: none;
  color: #475a71;
  background: white;
  font-family: "Myriad", sans-serif;
  font-weight: 500;
}
.rodal_modal .ui.form textarea:hover, .rodal_modal .ui.form textarea:focus, .rodal_modal .ui.form textarea:active, .rodal_modal .ui.form textarea:visited,
.rodal_modal .ui.form input:hover,
.rodal_modal .ui.form input:focus,
.rodal_modal .ui.form input:active,
.rodal_modal .ui.form input:visited {
  color: black;
  border-color: #adb8c1;
  box-shadow: inset 0 1px #e0e6e8 !important;
}
.rodal_modal .ui.form textarea::-webkit-input-placeholder,
.rodal_modal .ui.form input::-webkit-input-placeholder {
  color: #8898aa;
  font-family: "Myriad", sans-serif;
}
.rodal_modal .ticket-card {
  margin: 2em 0em;
  border: 1px solid #f4f7fe;
  border-radius: 5px;
  background: #fafafa;
  text-align: left;
}
.rodal_modal .ticket-card .ticket-item {
  padding: 1em;
  border-bottom: 1px solid #f4f7fe;
}
.rodal_modal .ticket-card .ticket-item label {
  font-weight: 600 !important;
  color: #8898aa !important;
}
.rodal_modal .ticket-card .ticket-item p {
  font-weight: 500;
  font-size: 16px;
}
.rodal_modal .rodal-head {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 1em;
  left: 1em;
}
.rodal_modal .rodal-body {
  margin-top: 2em;
}
.rodal_modal .rodal-dialog {
  max-width: 600px !important;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
  -moz-box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
  box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
  border-radius: 10px !important;
  padding: 0em;
  margin-top: 50px;
  /* Track */
  /* Handle */
}
@media screen and (min-width: 576px) {
  .rodal_modal .rodal-dialog {
    width: 90% !important;
    min-height: 80vh !important;
    margin-top: 100px;
  }
}
.rodal_modal .rodal-dialog::-webkit-scrollbar {
  width: 3px;
}
.rodal_modal .rodal-dialog::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e4e4e4;
}
.rodal_modal .rodal-dialog::-webkit-scrollbar-thumb {
  background: #1e70bf;
  border-radius: 10px;
}
.rodal_modal .rodal-dialog .rodal-close:before, .rodal_modal .rodal-dialog .rodal-close:after {
  border-radius: 0px;
}
.rodal_modal .modal-title {
  width: 100%;
  text-align: center;
}
.rodal_modal .rodal-title {
  font-family: "Myriad", sans-serif !important;
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 1.5em;
  color: #2e363e;
}
.rodal_modal .rodal-body {
  width: 100%;
  margin: 0px auto;
}
.rodal_modal .rodal-body.login h4 {
  font-size: 14px !important;
}
@media (max-width: 576px) {
  .rodal_modal .rodal-body.login {
    padding: 0em !important;
  }
}
.rodal_modal .rodal-body .rodal-body--action {
  width: 100%;
  max-width: none !important;
}
.rodal_modal .rodal-body .rodal-body--action .rodal-header {
  padding: 1em;
  background: #eaedf0;
}
.rodal_modal .rodal-body .rodal-body--action .rodal-header .rodal-header--title {
  font-family: "Myriad", sans-serif;
  font-size: 18px;
  text-align: center;
}
.rodal_modal .rodal-body .rodal-body--action .rodal-footer .rodal-footer--btn {
  outline: none;
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  text-align: center;
}
.rodal_modal .rodal-body .rodal-title {
  font-family: "Myriad", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5em;
  color: #2e363e;
}
.rodal_modal .rodal-body .rodal-title.login {
  font-size: 28px !important;
  font-weight: 500 !important;
  color: #2e363e !important;
}
@media (max-width: 576px) {
  .rodal_modal .rodal-body .rodal-title.login {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 0.5em !important;
    font-family: "Myriad", sans-serif !important;
  }
}
.rodal_modal .rodal-body .rodal-h4 {
  font-family: "Myriad", sans-serif;
  font-size: 18px !important;
  line-height: 1.5em;
}
.rodal_modal .rodal-body .rodal-p {
  font-family: "Myriad", sans-serif;
  font-size: 15px !important;
  line-height: 1.5em;
}
.rodal_modal .rodal-body .box-select-radios .radio {
  width: 100%;
  transition: all 200ms ease;
}
.rodal_modal .rodal-body .box-select-radios .radio .box-select {
  height: 60px;
  transition: all 200ms ease;
}
.rodal_modal .rodal-body .box-select-radios .radio .box-select badge {
  transition: all 200ms ease;
  opacity: 1;
  display: none;
}
.rodal_modal .rodal-body .box-select-radios .radio.checked .box-select {
  background: #f4f7fe !important;
}
.rodal_modal .rodal-body .box-select-radios .radio.checked .box-select p {
  color: #1069c9 !important;
}
.rodal_modal .rodal-body .box-select-radios .radio.checked .box-select badge {
  transition: all 200ms ease;
  opacity: 1;
  display: block;
}

.rodal.rodal-fade-enter.rodal_modal.reschedule_modal.rstep7.no-padding .rodal-dialog {
  height: 80vh !important;
}
