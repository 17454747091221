$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #67b23c;
$blue: #39678e;
$light-blue: #1069c9;
//$light-blue: #479DC8;
$dark-blue: #394b64;
$dark-gray: #646b73;

.text-gray {
  color: $dark-gray;
}
.text-blue {
  color: $light-blue;
}
.text-green {
  color: $green;
}
.text-teal {
  color: $teal;
}

#proPay {
  label {
    color: #888 !important;
    font-weight: 600;
  }

  .d-sign {
    font-family: 'Weissenhof', sans-serif;
    font-weight: 300 !important;
  }
  .header {
    padding: 2em 0em;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    .title {
      h1 {
        font-weight: 300;
        text-transform: uppercase;
      }

      p {
        font-size: 14px;
        color: $dark-gray;
      }
    }
    .d-lg-flex,
    .d-md-flex {
      width: 100%;
      justify-content: space-between;
    }
    .balance {
      h1 {
        color: $light-blue;
      }
    }
  }

  .account-statistics {
    padding: 2em 0em;
    h1 {
      font-weight: 400;
      font-size: 24px;
    }

    .border-right {
      @media (max-width: 976px) {
        border: none !important;
      }
    }
  }
  .account-table {
    background: #f2f2f2;
    padding: 3em 0em;

    .table {
      background: white !important;

      tr,
      td {
        border: none;
        background: none;
        color: $black !important;
        transition: all 200ms ease;

        &:hover {
          background: #fafafa !important;
        }
      }

      td {
        padding: 1em 0.5em !important;
        border-bottom: 2px solid #f2f2f2;
      }
      .t-head {
        td {
          color: darken($gray, 10%) !important;
        }
      }
    }
  }
}

#proJobs {
  margin-top: -1em;
  background: #fafafa;
  padding-bottom: 5em;
  padding-top: 3em;
  height: 100%;
  min-height: 100vh;

  .hidden-s-up {
    display: none;

    @media (max-width: 575px) {
      display: block;
    }
  }

  .hidden-s-down {
    display: block;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .header {
    padding: 3em 0em;

    .pro-title {
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }

    .pro-title-description {
      font-weight: 500;
      font-size: 18px;
      color: $dark-gray;
      text-align: center;
    }
  }

  .content {
    //background: #f2f2f2;
    .opp-card {
      width: 100%;
      max-width: 600px;
      margin: 0px auto;
      margin-bottom: 1em;
      display: block;
      background: white;
      box-shadow:
        0 7px 14px 0 rgba(50, 50, 93, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
      border: 1px solid #e6ebf1;
      border-radius: 5px;

      .opp-body {
        width: 100%;
        padding: 2em;
      }

      .opp-details {
        li {
          padding: 1em 2em;
          background: #fafafa;
          display: flex;
          justify-content: space-between;

          label {
            color: #888 !important;
            font-weight: 500;
          }

          p {
            color: black !important;
            font-weight: 600;
          }
        }
      }

      .opp-footer {
        border-top: 1px solid #ddd;
        padding: 1em 2em;

        .claim-button {
          background: $teal;
          color: white;
          border-radius: 3px;
          padding: 1em 2em;
          cursor: pointer;
          transition: all 200ms ease;

          &:hover {
            background: darken($teal, 10%);
          }
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .card-title {
    margin: 0;
  }
  .card-body {
    padding: 0;
  }
  .jcard {
    margin: 0px auto;
    max-width: 800px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    border: 1px solid #e4e4e4;
    margin-top: -1px;
    padding: 1em;
    transition: all 200ms ease;
    background: white;

    p {
      color: black !important;
    }

    &:hover {
      background: #fafafa;
    }

    .jtime {
      width: 20%;

      label {
        color: #939393 !important;
        font-size: 14px;
        margin: 0;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }

    .jtitle {
      width: 60%;

      b {
        font-weight: 600;
        //font-size: 16px;
        //color: #001837;
      }

      .text-muted {
        color: #939393 !important;
        font-weight: 500;
        font-size: 14px;

        @media (max-width: 767px) {
          display: none;
        }
      }

      @media (max-width: 767px) {
        width: auto;
      }
    }

    .jstatus {
      width: 20%;

      @media (max-width: 767px) {
        width: auto;
      }
    }
  }

  .badge {
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1em;
    font-size: 14px;
    margin: 0;
    color: #939393;
    border-radius: 50px;
    float: right;
    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 0.4em;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
      background-color: #69b6d5;
    }

    &.assigned,
    &.leftSite,
    &.onSite,
    &.enRoute {
      &::before {
        background: #0076ff;
      }
      color: black;
    }

    &.partsNeeded,
    &.pending,
    &.partsRequested {
      &::before {
        background: #f1c57c;
      }
    }

    &.completed,
    &.leftSite {
      &::before {
        background: #1fba14;
      }
    }

    &.cancelled,
    &.proNoShow,
    &.customerNoShow {
      &::before {
        background: #d0011b;
      }
    }
  }
}
