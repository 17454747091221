//$font: 'Source Sans Pro', sans-serif;
$font: 'Myriad', sans-serif;
$table-width: 100%;
$tbody-height-max: 50vh;

$border-color: #e4e4e4;
$green: #62b161;

#PartCrm,
.partCrmModal {
  position: relative;
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  small,
  input,
  button,
  textarea,
  span,
  tr,
  td,
  th,
  .badge {
    // font-family: $font !important;
    font-weight: 500;
    line-height: 1em;
    margin: 0px;
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }

  button,
  .button {
    font-weight: 500;
    border: none;
    font-size: 1.2em;
    transition: all 200ms ease;
    cursor: pointer;

    &[disabled] {
      cursor: default;
      opacity: 0.5;
    }

    &.primary,
    &.secondary,
    &.action {
      border-radius: 5px;
      padding: 10px 24px 8px 24px;
      border: 1px solid transparent;
      font-size: 16px;
    }

    &.primary {
      background: #0460aa;
      color: white;

      &:hover {
        background: darken(#0460aa, 5%);
      }
      &[disabled]:hover {
        background: #0460aa;
      }
    }

    &.secondary {
      background: linear-gradient(180deg, #ffffff 73.86%, #f4f4f4 100%);
      border: 1px solid #e4e4e4;
      color: #3e3e3e;

      &:hover {
        border-color: darken(#e4e4e4, 5%);
      }
      &[disabled]:hover {
        border-color: #e4e4e4;
      }
    }

    &.action {
      background: #bc523b;
      color: white;

      &:hover {
        background: darken(#bc523b, 5%);
      }
      &[disabled]:hover {
        background: #bc523b;
      }
    }

    &.sm {
      padding: 4px 12px;
      font-size: 1em;
    }

    &.edit_button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      background: none;

      i {
        color: #878787;
      }

      &:hover {
        i {
          color: black;
        }
        background-color: #f2f2f2;
      }
    }
  }

  .hover-press {
    transition: all 200ms ease;
    cursor: pointer !important;
    border-radius: 4px;
    pointer-events: all;

    &:hover {
      background-color: transparentize(#0460aa, 0.9);
    }
  }

  select {
    font-family: $font;
    color: #252525;

    &:disabled {
      color: #c0c0c0;
    }
  }

  .bg-white {
    background: white !important;
  }

  .badge {
    background: #3e3e3e;
    color: white;
    font-weight: 600;
    font-size: 0.9em;
    border-radius: 10px;
    padding: 8px 16px 4px 16px;

    &.red {
      background: #bc523b;
    }
  }

  .header {
    position: absolute;
    width: 100%;
    background: white;
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.05),
      0 2px 2px rgba(0, 0, 0, 0.05),
      0 4px 4px rgba(0, 0, 0, 0.05),
      0 6px 8px rgba(0, 0, 0, 0.05),
      0 8px 16px rgba(0, 0, 0, 0.05);
  }

  .tab-bar {
    .tab-item {
      cursor: pointer;
      padding: 16px 0px;
      margin-right: 32px;
      transition: all 200ms ease;

      &.active {
        border-bottom: 2px solid #3e3e3e;
      }

      &:hover {
        color: black;
        opacity: 0.5;
      }
    }
  }

  .parts-container {
    width: 100%;
    background: #f2f2f2;
    padding-top: 125px + 8px;

    .tab-card {
      background: red;
      height: 120px;
      margin-bottom: 8px;
    }
  }

  .actions {
    border-left: 1px solid #ccc;
    width: 200px;

    button,
    .button {
      padding: 8px;
      font-size: 14px;
      margin-bottom: 8px;
      width: 100%;

      &:last-child {
        margin: 0;
      }
    }

    .icon {
      border-right: 1px solid #ccc;
      margin-bottom: -2px;
    }
  }

  .part-num {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .part-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin: 0;
    }
  }

  .active-item {
    font-size: 20px;
  }

  .check-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    border: 2px solid #ccc;
    width: 24px;
    height: 24px;
    padding: 0px;
    border-radius: 32px;
    align-self: center;
    text-align: center;
    transition: all 200ms ease;

    &:hover {
      border-width: 5px;
    }

    &.active {
      border-width: 5px;
      background: #62b161;
      border-color: #62b161;
    }
  }

  .tracker {
    .tracker-item {
      opacity: 0.2;
      margin-right: 12px;
      transition: all 400ms ease;

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-right: 0px;
      }

      .tracker-line {
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: #ccc;
      }

      &.active {
        opacity: 1;
        .tracker-line {
          background: #62b161;
        }
      }
    }

    &.red {
      .tracker-item.active .tracker-line {
        background: #bc523b !important;
      }
    }
  }

  .desc {
    font-size: 14px;
    white-space: nowrap;

    td {
      line-height: 18px !important;
    }
  }
}

.partCrmModal {
  .text-dark {
    color: #3e3e3e !important;
  }

  .check-icon {
    margin-left: -14px;
  }

  .modal-title {
    margin: 0;
  }

  .back-button {
    margin-left: -20px;
  }

  .section {
    margin-bottom: 25px;
  }

  .section-subtitle {
    font-size: 18px;
  }

  .items {
    border-left: 1px solid #ccc;
    padding-left: 27px;
    margin-top: 10px;

    ul {
      margin: 0;
      padding-left: 17px;
    }
  }

  .subtitle {
    background: #fafafa;
    margin: 0 -30px 25px;
    padding: 9px 30px;

    .from,
    .to {
      &::before {
        content: '1';
        display: inline-block;
        border: 1px solid black;
        width: 26px;
        text-align: center;
        border-radius: 26px;
        margin-right: 10px;
      }
    }

    .to:before {
      content: '2';
    }
  }

  &.admin-modal .admin-modal_container .admin-modal_dialog {
    // designs are 1200 but .container maxes out at 1140
    max-width: 1140px !important;
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .sm-input {
    width: 64px;
  }

  label {
    color: #6a7070;
    margin-bottom: 8px !important;
    white-space: nowrap;
  }

  .stacked-form-input {
    margin-bottom: 16px !important;

    .d-block {
      flex-basis: 140px;
      margin-right: 10px;
    }

    .d-flex {
      flex: 1;
    }
  }

  .summary {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    margin-top: -75px;

    tr:nth-child(3n + 0) {
      td {
        border-bottom: 1px solid #ccc;
        padding-bottom: 8px;
      }
    }
    tr:last-child {
      td {
        border: 0;
      }
    }
    .estimate {
      font-size: 40px;
      text-align: center;
    }
  }

  .summary-items {
    background: #fafafa;
    border-top: 1px solid #ccc;

    p {
      line-height: 22px;
    }
  }

  .full-width {
    width: 100%;
  }

  .return-tracker {
    position: relative;
    width: 100%;
    transition: all 200ms ease;
  }

  .return-tracker-ui {
    height: 5px;
    border-radius: 5px;
    background: #f0f1f4;
  }

  .return-tracker-ui-line {
    left: 0;
    height: 5px;
    border-radius: 5px;
    width: 100%;
    background: $green;
    transition: all 200ms ease;
  }

  .return-tracker-label {
    margin-top: 8px;
    flex: 1;
    opacity: 0.5;
    transition: all 200ms ease;
    font-size: 15px;

    label {
      color: #3e3e3e !important;
    }

    &.active {
      opacity: 1;
    }
  }

  .step-1 {
    .part {
      margin: 0 -30px;
      padding: 16px 30px;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border: 0;
      }
    }
  }

  .step-3 {
    td {
      line-height: 22px;
    }

    .legend {
      border-bottom: 1px solid #ccc;
      font-weight: 600;

      span {
        font-weight: 600;
      }

      .text-muted {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .dimensions {
      .form-group {
        flex-basis: 25%;

        &:last-child {
          flex-basis: 70px;
        }
      }
    }

    .weight {
      .form-group:last-child {
        flex-basis: 70px;
      }
    }
  }

  .step-4 {
    background: #fafafa !important;

    .success {
      border-radius: 6px;
      border: 1px solid #62b161;
    }

    .success-icon {
      background: #62b161;
      border-radius: 20px;
      height: 20px;
      width: 20px;
    }

    .status {
      margin: 0 auto;
      width: 50%;

      p {
        line-height: 22px;
      }
      h1 {
        font-weight: 200;
        font-size: 32px;
      }
    }

    .confirmation-summary {
      background: #fff;

      h2 {
        font-weight: 200;
        font-size: 20px;
        border-bottom: 1px solid #ccc;
      }
      p,
      td {
        line-height: 22px;
      }
    }
  }
}
