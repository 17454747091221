/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #23d192;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
$dark-blue: #394b64;
$dark-gray: #646b73;
$pop: #6772e5;
$border-color: #e6ebf1;

$s-green: #24b47e;
$s-purple: #6772e5;
$s-blue: #3297d3;
$s-yellow: #e39f48;
$s-red: #d9453d;
/*** Button Styles ***/
$btn-primary: $light-blue;
$btn-secondary: $light-blue;

.dropdown {
  .btn-secondary {
    font-family: $font-primary;
    display: inline-block;
    font-size: 16px;
    background: none !important;
    color: #222 !important;
    font-weight: 600 !important;
    padding: 1em 1em;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
  }
}

.blurred {
  filter: blur(0.5rem) !important;
}

.button-border {
  width: 100%;
  border-radius: 4px;
  padding: 1em 1em;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  outline: none;
  border: 1px solid #dee1e5;
  cursor: pointer;
  transition: all 200ms ease;
  background: white;
  color: $light-blue;

  &:hover {
    border-color: $light-blue;
  }
}

.button-blue {
  width: 100%;
  border-radius: 4px;
  padding: 1em 1em;
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  background: $light-blue;
  color: white;

  &:hover {
    background: darken($light-blue, 10%);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button-dark {
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer !important;
  background: #479dc8;
  border: 1px solid #479dc8;
  border-radius: 0px;
  color: #fff;
  font-family: $font-primary;
  font-weight: 600;
  font-size: 15px;
  padding: 0.75em 1em;
  border-radius: 4px;
  transition: all 200ms ease;

  &.bg {
    font-size: 20px;
    padding: 1em 2em;
  }

  &.w-100 {
    width: 100%;
  }

  &:hover {
    background: darken(#479dc8, 5%);
    color: white;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  p {
    color: #fff;
    font-family: $font-primary;
    font-weight: 600 !important;
    font-size: 15px;

    &.badge {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}

.button-light {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  background: #f1f3f6;
  border: 1px solid #f1f3f6;
  border-radius: 0px;
  color: #141b41;
  font-family: $font-primary;
  font-weight: 600;
  padding: 0.75em 1em;
  border-radius: 4px;
  transition: all 200ms ease;

  &.w-100 {
    width: 100% !important;
  }

  &:hover {
    border-color: darken(#f1f3f6, 15%);
    color: darken(#141b41, 15%);
  }
}

#newForm {
  .form-control:disabled,
  .form-control[readonly] {
    background: #f2f2f2 !important;
    color: rgba(0, 0, 0, 0.4) !important;
    border: none !important;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: rgba(0, 0, 0, 0.4) !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .form-group {
    label,
    .form-check-label {
      font-size: $font-primary !important;
      font-size: 16px !important;
      &.d-block {
        font-weight: 600 !important;
      }
    }

    .form-check-label {
      color: #6c757e !important;
    }

    .item {
      padding: 0px !important;
      font-family: $font-primary !important;
      font-size: 16px !important;
      border: none !important;
      color: #6c757e !important;
    }

    .Select {
      &.form-control {
        padding: 0px !important;
        border: none !important;
        input {
          width: 100% !important;
          border: none !important;
        }
      }
    }

    input,
    textarea {
      border: 1px solid #cad1d7 !important;
      border-radius: 0.2em !important;
      -webkit-appearance: none;
      color: #475a71 !important;
      background: white;
      font-family: $font-primary !important;
      font-weight: 500;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: black !important;
        border-color: darken(#cad1d7, 10%) !important;
        box-shadow: inset 0 1px #e0e6e8 !important;
      }

      &::-webkit-input-placeholder {
        color: #8898aa !important;
        font-family: $font-primary !important;
      }
    }
  }
}

#new_user_profile {
  background: #fff;
  max-width: 100%;
  overflow-x: hidden;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  span,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;
  }

  .nav--notFixed {
    box-shadow:
      0 7px 14px 0 rgba(50, 50, 93, 0.05),
      0 3px 6px 0 rgba(0, 0, 0, 0.04);
  }

  .button-circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #4680ca;
    cursor: pointer;
    box-shadow: 0 9px 13px 4px transparentize(#4680ca, 0.8);
    border: 1px solid #4680ca;
    outline: none;
    transition: all 200ms ease;

    &:hover {
      background: darken(#4680ca, 5%);
      border-color: darken(#4680ca, 5%);
    }

    &.sm {
      width: 35px;
      height: 35px;
      box-shadow: 0 4px 8px 4px transparentize(#4680ca, 0.8);
    }

    &.secondary {
      background: white;
      box-shadow: none;
      border: 1px solid black;
      cursor: pointer;

      &:hover {
        background: white;
        border-color: darken(#eff1f6, 15%);
      }
    }
  }

  .u-title-block {
    h5 {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #4680ca !important;
      line-height: 1.75em;
    }

    .text-muted {
      color: #8898aa !important;
      font-size: 16px;
      font-weight: 500;
    }

    .button-dark,
    .button-light {
      display: block;
    }
  }

  .u-left {
    //background: #F6F9FC;
    background: white;
    border-right: 1px solid #e4e4e4;
    height: 100%;
    width: 100%;
    padding: 0em;

    .alert-notification {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
      border-radius: 4px;
      background: #fafafa;
      border: 1px solid #e4e4e4;

      p {
        font-family: $font-primary;
        font-size: 14px;
        color: #222;
        font-weight: 600;
        margin-left: 0.2em;
      }
    }

    .tr-list {
      .tr-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1em 0em;
        position: relative;
        &:before {
          content: ' ';
          height: 100%;
          width: 2px;
          background: #eff6ff;
          display: block;
          left: calc(20px - 2px);
          position: absolute;
          z-index: 2;
        }

        &:first-child {
          &:before {
            content: ' ';
            height: 50%;
            bottom: 0px;
          }
        }

        &:last-child {
          &:before {
            content: ' ';
            height: 50%;
            top: 0px;
          }
        }

        .tr-number {
          background: #eff6ff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 1em;
          color: #2643f7;
          font-family: $font-primary;
          font-size: 12px;
          font-weight: 800;
          z-index: 10;
        }

        .tr-text {
          flex: 1;

          p {
            font-weight: 600;
            color: #2e363e;
            line-height: 1.5em;
          }
        }
      }
    }

    .rwt__tabs {
      .tab-header {
        background: #f6f9fc;
        padding: 0em 1em;
        padding-top: 0.3em;

        h4 {
          font-weight: 600;
        }
      }

      .rwt__tabpanel {
        padding: 1em;
      }

      .rwt__tablist {
        .rwt__tab {
          padding-right: 1em;
          font-weight: 600 !important;
          font-size: 14px;
          color: #8898aa;
          font-family: $font-primary;
          border: none;
          outline: none;
          background: none;
          cursor: pointer;
          padding-top: 1em;
          padding-bottom: 1em;

          &[aria-selected='false'] {
            border-bottom: 2px solid transparent;
            color: #8898aa !important;
            transition: all 200ms ease;

            &:hover {
              color: darken(#8898aa, 15%) !important;
            }
          }

          &[aria-selected='true'] {
            border-bottom: 2px solid #000;
            color: black;
          }
        }
      }
    }
  }

  .u-center {
    border-radius: 4px;
    background: white;
    padding: 0em 2em;
    padding-bottom: 10vh;

    @media (max-width: 767px) {
      padding-left: 1em;
      padding-right: 1em;
    }

    .disclaimer {
      font-size: 10px !important;
      color: #8898aa;
    }
  }

  .user-referral-card {
    .button-dark .sm {
      font-size: 14px;
      padding: 0.3em 1em;
    }

    .referral-input {
      border: 2px dotted #e4e4e4;
      border-radius: 4px;
      width: 100%;
      flex: 1;
      padding: 8px;
      color: #4680ca;
      font-size: 'Myriad', sans-serif;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .waitingOnScheduleBlurb {
    padding: 32px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-bottom: 1em;
    background: #1488cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2b32b2,
      #1488cc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2b32b2,
      #1488cc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .container-xs {
      max-width: 400px;

      h2 {
        font-size: 18px !important;
        font-weight: 600;
        color: white;
        margin-bottom: 0.5em;
      }

      p {
        color: rgba(255, 255, 255, 0.75) !important;
      }
    }

    .btn-white {
      font-family: $font-primary;
      font-weight: 600;
      font-size: 14px;
      padding: 0.75em 2em;
      color: #2b32b2;
      background: white;
      border-radius: 25px;
      border: none;
      outline: none;
      cursor: pointer;
      transition: all 200ms ease;

      &:hover {
        background: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .card-section {
    .padding-0 {
      padding: 0em !important;
    }

    .card-box--shadow {
      padding: 1em;
      border: 1px solid #ececec;
      box-shadow: 0 0 9px 0 #f3f4f9;
      background: white;
      border-radius: 4px;
      margin-bottom: 0.5em;
    }

    .card-box {
      background: white;
      margin-bottom: 1em;
      //border-radius: 4px;
      //box-shadow: 0 7px 14px 0 rgba(50,50,93,.1),0 3px 6px 0 rgba(0,0,0,.07);
      //overflow: hidden;
      //border: 1px solid #e4e4e4;

      .card-part {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1em;
        border: 1px solid #ececec;
        box-shadow: 0 0 9px 0 #f3f4f9;
        background: white;
        border-radius: 4px;
        margin-bottom: 0.5em;

        h4 {
          line-height: 1.5em;
          margin: 0px;
        }

        badge {
          display: inline-block;
          padding: 0.2em 0.5em;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          background: #8898aa;
          color: white;
          font-family: $font-primary;
          margin-right: 1em;
        }
      }

      .card-box--header {
        padding: 1em 0em;
        font-family: $font-primary;
        font-weight: 600;
        font-size: 16px;
        color: #525f7f;

        h4 {
          font-family: $font-primary;
          font-weight: 600;
          font-size: 16px;
          color: #8898ac;
        }
      }

      .shipment-card {
        btn {
          font-family: 'Myriad', sans-serif;
          font-weight: 600;
          font-size: 12px;
          color: white;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          padding: 0em 1em;
          height: 40px;
          text-align: center;
          display: inline-flex;
          cursor: pointer;
          background: #333238;
          transition: all 200ms ease;

          &:hover {
            background: darken(#333238, 10%);
          }

          &.secondary {
            background: #eceff1;
            color: #2e3b48;

            &:hover {
              background: darken(#eceff1, 10%);
              color: #2e3b48;
            }
          }
        }

        .shipment-timeline {
          list-style: none !important;
          -webkit-margin-before: 0em;
          -webkit-margin-after: 0em;
          -webkit-margin-start: 0px;
          -webkit-margin-end: 0px;
          -webkit-padding-start: 0px;

          .shipment-timeline--item {
            display: flex;
            align-items: flex-start;
            padding: 8px 0px;
            position: relative;

            &:first-child {
              &:before {
                content: ' ';
                position: absolute;
                width: 2px;
                height: 100%;
                border-left: 2px dotted #55af7a;
                z-index: 1;
                left: 8px;
              }
            }
            .timeline--counter {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border: 2px solid #55af7a;
              padding-top: 2px;
              font-family: 'Myriad', sans-serif;
              font-weight: 700;
              font-size: 10px;
              text-align: center;
              color: #55af7a;
              background: white;
              z-index: 2;
            }

            .timeline--text {
              margin-left: 16px;
            }
          }
        }
      }

      .card-box--content {
        padding: 1em 0em;

        &.no-padding {
          padding: 0;
        }
      }
    }
  }

  .u-right {
    background: white;
    height: 100%;
    min-height: 100vh;
    border-left: 1px solid #e4e4e4;

    @media (max-width: 767px) {
      border-top: 1px solid #e4e4e4;
      min-height: inherit;

      .payment-card {
        background: #f6f9fc !important;
      }
    }

    .payment-card {
      padding: 1em;
      border-bottom: 1px solid #e4e4e4;
      cursor: pointer;
      transition: all 200ms ease;

      &:hover {
        background: #f6f9fc;
      }
    }

    .invoice-card {
      padding: 2em 1em;

      .copay-alert {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        padding: 1em;
        border-radius: 4px;
        border: 2px dotted #e4e4e4;
        line-height: 1.5em;
        margin-bottom: 1em;

        u {
          text-decoration-style: dotted;
          color: #4680ca;
        }
      }

      .invoice-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        badge {
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          padding: 0.5em 1em;
          background: #eff6ff;
          color: #2b47f7;
        }
      }
    }

    .signature-field {
      margin: 0.5em 0em;

      .signature-box {
        margin-top: 0.2em;
        width: 100%;
        text-align: center;
        background: #fafafa;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        padding: 1.5em 1em;

        img {
          height: 50px;
        }
      }
    }
  }
}

#contractor-terms {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  span,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;
  }
  h1 {
    margin-bottom: 0.3em !important;
    @media (max-width: 500px) {
      font-size: 24px !important;
    }
  }
  h4 {
    font-size: 18px !important;
    margin-bottom: 0.5em;
  }

  .container {
    max-width: 800px;
    margin: 0px auto;
  }

  article {
    padding: 1em 0em;
  }

  u {
    font-weight: 600;
  }

  ul {
    li {
      padding: 0.5em 0em;
    }
  }

  blurb {
    display: block;
    background: #fafafa;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    padding: 1em;
    border-radius: 4px;
    margin: 0.5em 0em;

    p {
      color: #8898aa;
      font-weight: 600;
    }
  }

  .header {
    padding: 2em 0em;
  }

  .content-container {
    padding: 5vh 0vh;
    max-width: 800px;
    margin: 0px auto;
    margin: 1em;
  }
  .contract-nav {
    background: #fff;
    box-shadow: inset 0 0 10px grey;
    padding: 2em 1em;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    padding-top: 120px;
    h4 {
      font-weight: 600;
    }

    @media (max-width: 1000px) {
      display: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #e4e4e4;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e70bf;
      border-radius: 10px;
    }

    ol {
      counter-reset: li; /* Initiate a counter */
      margin-left: 0; /* Remove the default left margin */
      padding-left: 0; /* Remove the default left padding */

      &:hover {
        li {
          opacity: 0.5;
          transition: all 200ms ease;
        }
      }
    }

    ol > li {
      position: relative; /* Create a positioning context */
      margin: 0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
      padding: 4px 8px; /* Add some spacing around the content */
      list-style: none; /* Disable the normal item numbering */
      background: #fff;
      transition: all 200ms ease;

      &:hover {
        opacity: 1;
      }

      p {
        color: #222 !important;
        font-weight: 600;
        font-size: 14px;
      }
    }
    ol > li:before {
      content: counter(li); /* Use the counter as content */
      counter-increment: li; /* Increment the counter by 1 */
      /* Position and style the number */
      position: absolute;
      top: 3px;
      left: -2em;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      width: 25px;
      border-radius: 25px;
      height: 25px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      /* Some space between the number and the content in browsers that support
           generated content but not positioning it (Camino 2 is one example) */
      margin-right: 8px;
      color: #8898aa;
      background: #f7f8f9;
      font-weight: 600;
      font-size: 12px;
      font-family: $font-primary;
      text-align: center;
    }
  }
}

.rodal_modal {
  .user-card {
    font-family: $font-primary;
    font-weight: 600;
    font-size: 14px;
    padding: 1em 0.5em;
    color: black;
    border-radius: 50px;
    text-align: center;
    border: 1px solid #e4e4e4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .user-avatar {
      width: 25px;
      height: 25px;
      border-radius: 100px;
      background: url('../../img/user-avatar-2.png');
      background-size: cover;
      background-position: center;
      margin-right: 0.5em;
    }
  }

  #rodal-body--action {
    width: 100%;
    height: 100%;
    max-width: none !important;
    overflow-x: hidden;
    overflow-y: auto;

    .rodal-header {
      padding: 1em;
      background: #eaedf0;

      .rodal-header--title {
        font-family: $font-primary;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        color: #5b5d66;
      }
    }

    .rodal-content {
      padding: 3em 2em;
      padding-bottom: 5em;
    }

    .rodal-footer {
      background: white;
      position: absolute;
      padding: 1em;
      border-top: 1px solid #e4e4e4;
      left: 0;
      bottom: 0;
      width: 100%;
      box-shadow:
        0 7px 14px 0 rgba(50, 50, 93, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);

      .rodal-footer--btn {
        outline: none;
        background: white;
        border: 2px solid #e4e4e4;
        padding: 1em;
        border-radius: 4px;
        width: 100%;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 600;
        color: #8898aa;
        text-align: center;

        &.primary {
          background: $light-blue;
          border-color: $light-blue;
          color: white;
        }
      }
    }
  }

  &.reschedule_modal {
    &.no-padding {
      .rodal-dialog {
        padding: 0px !important;
      }
    }

    input,
    textarea {
      border: 1px solid #cad1d7 !important;
      border-radius: 0.2em !important;
      -webkit-appearance: none;
      color: #475a71 !important;
      background: white;
      font-family: $font-primary !important;
      font-weight: 500;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: black !important;
        border-color: darken(#cad1d7, 10%) !important;
        box-shadow: inset 0 1px #e0e6e8 !important;
      }

      &::-webkit-input-placeholder {
        color: #8898aa !important;
        font-family: $font-primary !important;
      }
    }

    .rodal-dialog {
      max-width: 700px !important;

      .q-t {
        font-size: 18px !important;
        font-weight: 600 !important;
        display: none;
      }

      h5 {
        font-size: 15px !important;
        display: none;
      }
    }
  }

  &.sm {
    .rodal-dialog {
      max-width: 500px !important;
      height: 300px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2em !important;

      .rodal-body {
        max-width: 400px !important;
      }
    }
  }

  &.xs {
    .rodal-dialog {
      max-width: 450px !important;
      height: 600px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2em !important;

      .rodal-body {
        max-width: 350px !important;
        padding: 5vh 0vh !important;
      }
    }
  }

  .rodal-mask {
    background: rgba(216, 221, 227, 0.5) !important;
  }

  .ui.form textarea,
  .ui.form input {
    border: 1px solid #cad1d7;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background: white;
    font-family: $font-primary;
    font-weight: 500;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: black;
      border-color: darken(#cad1d7, 10%);
      box-shadow: inset 0 1px #e0e6e8 !important;
    }

    &::-webkit-input-placeholder {
      color: #8898aa;
      font-family: $font-primary;
    }
  }

  .ticket-card {
    margin: 2em 0em;
    border: 1px solid #f4f7fe;
    border-radius: 5px;
    background: #fafafa;
    text-align: left;

    .ticket-item {
      padding: 1em;
      border-bottom: 1px solid #f4f7fe;

      label {
        font-weight: 600 !important;
        color: #8898aa !important;
      }
      p {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .rodal-head {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 1em;
    left: 1em;
  }

  .rodal-body {
    margin-top: 2em;
  }

  .rodal-dialog {
    max-width: 600px !important;
    min-height: 100vh;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
    -moz-box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
    box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);
    border-radius: 10px !important;
    padding: 0em;
    margin-top: 50px;

    @media screen and (min-width: 576px) {
      width: 90% !important;
      min-height: 80vh !important;
      margin-top: 100px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #e4e4e4;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e70bf;
      border-radius: 10px;
    }

    .rodal-close {
      &:before,
      &:after {
        border-radius: 0px;
      }
    }
  }

  .modal-title {
    width: 100%;
    text-align: center;
  }

  .rodal-title {
    font-family: $font-primary !important;
    font-size: 20px;
    font-weight: 600 !important;
    line-height: 1.5em;
    color: #2e363e;
  }

  .rodal-body {
    width: 100%;
    margin: 0px auto;

    &.login {
      h4 {
        font-size: 14px !important;
      }

      @media (max-width: 576px) {
        padding: 0em !important;
      }
    }

    .rodal-body--action {
      width: 100%;
      max-width: none !important;

      .rodal-header {
        padding: 1em;
        background: #eaedf0;
        .rodal-header--title {
          font-family: $font-primary;
          font-size: 18px;
          text-align: center;
        }
      }

      .rodal-footer {
        .rodal-footer--btn {
          outline: none;
          font-family: $font-primary;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .rodal-title {
      font-family: $font-primary;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5em;
      color: #2e363e;

      &.login {
        font-size: 28px !important;
        font-weight: 500 !important;
        color: #2e363e !important;

        @media (max-width: 576px) {
          font-size: 20px !important;
          font-weight: 600 !important;
          margin-bottom: 0.5em !important;
          font-family: 'Myriad', sans-serif !important;
        }
      }
    }

    .rodal-h4 {
      font-family: $font-primary;
      font-size: 18px !important;
      line-height: 1.5em;
    }

    .rodal-p {
      font-family: $font-primary;
      font-size: 15px !important;
      line-height: 1.5em;
    }

    .box-select-radios {
      .radio {
        width: 100%;
        transition: all 200ms ease;

        .box-select {
          height: 60px;
          transition: all 200ms ease;
          badge {
            transition: all 200ms ease;
            opacity: 1;
            display: none;
          }
        }

        &.checked {
          .box-select {
            background: #f4f7fe !important;

            p {
              color: $light-blue !important;
            }
            badge {
              transition: all 200ms ease;
              opacity: 1;
              display: block;
            }
          }
        }
      }
    }
  }
}

.rodal.rodal-fade-enter.rodal_modal.reschedule_modal.rstep7.no-padding {
  .rodal-dialog {
    height: 80vh !important;
  }
}
