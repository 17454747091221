@mixin responsive-aspect-ratio($ratio) {
  &:before {
    content: '';
    float: left;
    padding-top: calc(100% / $ratio);
  }
}

@mixin apply-page-animation($property, $add-will-change: true) {
  transition: #{$property} 0.6s ease-out;

  @if ($add-will-change) {
    will-change: $property;
  }
}

@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin respond($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

$new-grey: #f4f6fd;

.apply {
  &-container {
    margin: auto;
    max-width: 1200px;
    width: 100%;
  }

  &-bg-grey {
    background-color: $new-grey;
  }

  &-hero {
    &__title {
      font-size: 36px !important;
      text-transform: capitalize;
    }

    &__image {
      @include responsive-aspect-ratio(calc(428 / 222));
      background-image: url('../../../img/tech-repairing-oven-new.png');
      min-height: 222px;
    }

    &__form {
      grid-template-columns: 1fr 1px 1fr;
    }

    &__sticky {
      box-shadow:
        0 -1px 3px rgba(0, 0, 0, 0.02),
        0 -2px 2px rgba(0, 0, 0, 0.05),
        0 -4px 4px rgba(0, 0, 0, 0.05),
        0 -8px 8px rgba(0, 0, 0, 0.05),
        0 0 0 rgba(0, 0, 0, 0.05);
    }
  }

  &-benefits {
    &__title {
      max-width: 500px;
    }

    &__card {
      border: solid 1px transparent;

      &__icon {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        .apply-benefits__card {
          &__background {
            opacity: 1;
          }
        }
      }
    }
  }

  &-calculator {
    background-image: linear-gradient(180deg, #ffffff 0%, $new-grey 50%);

    &__container {
      background-image: url('../../../img/mobile-bg-dots.png');
      line-height: 50px;
    }

    &__chevron {
      color: white !important;
      font-size: 35px !important;
      margin-left: 0 !important;
      margin-top: -15px;
      transform: rotateX(180deg);
    }
  }

  &-testimonials {
    &__texts {
      position: relative;

      &__text {
        @include apply-page-animation(opacity);
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &--selected {
          opacity: 1;
        }
      }
    }

    &__bubbles {
      max-width: 428px;
      min-height: 450px;
      position: relative;
    }

    &__bubble {
      @include apply-page-animation(transform);
      @include square(120px);
      background-color: transparent;
      border-radius: 50%;
      border: solid 1px transparent;
      cursor: pointer;
      display: flex;
      position: absolute;

      &__inner {
        @include square(100px);
        background-color: transparent;
        border-radius: 50%;
        border: solid 1px transparent;
        margin: auto;
        position: relative;
      }

      &__image {
        @include square(80px);
        border-radius: 50%;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &--selected {
        transform: scale(1.25);
        background-color: rgba(71, 157, 200, 0.1);
        border: solid 1px rgba(0, 112, 201, 0.15);

        .apply-testimonials__bubble {
          &__inner {
            background-color: rgba(71, 157, 200, 0.1);
            border: solid 1px rgba(0, 112, 201, 0.15);
          }
        }
      }
    }
  }

  &-hiw {
    &__slider {
      display: flex;
      margin: 0 auto;
      max-width: 380px;
      position: relative;

      &__texts {
        position: absolute;
        top: 44px;
        left: 0;
        width: 100%;
      }

      &__container {
        display: flex;
        margin-top: 180px;
        position: relative;
        width: 100%;
      }

      &__controls {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &__slides {
        margin: auto;
        max-width: 364px;
        width: 68%;
      }
    }

    &-slider-texts {
      @include apply-page-animation(opacity);
      opacity: 0;

      &--selected {
        opacity: 1;
      }
    }

    &-slider-controls {
      display: grid;
      gap: 24px;
      grid-auto-flow: row;
      grid-gap: 24px;

      &__control {
        @include apply-page-animation(background-color, false);
        @include square(12px);
        background-color: #e4e4e4;
        border-radius: 50%;
        outline: none !important;

        &--selected {
          background-color: #479dc8;
        }
      }
    }

    &-slider-slides {
      position: relative;

      &__slides {
        bottom: 3%;
        left: 6%;
        overflow: hidden;
        position: absolute;
        right: 6%;
        top: 3%;

        &__slide {
          @include apply-page-animation(transform);
          @include square(100%);
          background-position: center;
          background-size: cover;

          &__content {
            @include square(100%);
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 30px 20px;
          }
        }
      }
    }
  }

  &-mission {
    &__container {
      @include responsive-aspect-ratio(calc(396 / 300));
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%),
        url('../../../img/nana-video.png');
      border-radius: 10px;
      max-height: 630px;
      max-width: 1200px;

      &:hover {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.5) 100%),
          url('../../../img/nana-video.png');
      }
    }

    &__video {
      @include responsive-aspect-ratio(calc(560 / 315));
      max-height: 706px;
    }
  }

  &-faq-question {
    grid-template-areas: 'button question' 'button answer';
    grid-template-columns: max-content auto;

    &__button {
      grid-area: button;

      &__button {
        @include apply-page-animation(transform);

        &:focus,
        &:active {
          outline: none;
        }
      }
    }

    &__question {
      grid-area: question;
    }

    &__answer {
      @include apply-page-animation(max-height, false);
      grid-area: answer;
      overflow: hidden;
    }
  }

  &-modal {
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  &-application-modal {
    &__image {
      background-image: url('../../../img/application-modal-background-new.png');
      height: 614px;
      width: 376px;
    }
  }

  &-stepper {
    position: relative;

    &__steps {
      align-items: center;
      display: grid;
      gap: 16px;
      grid-auto-rows: minmax(42px, auto);
      grid-gap: 16px;
      grid-template-columns: 42px auto;
    }

    &__indicator {
      @include square(42px);
      background-color: transparent;
      border-radius: 50%;
      border: solid 1px transparent;
      display: flex;
      position: relative;

      &__content {
        @include square(8px);
        background-color: #878787;
        border-radius: 50%;
        border: 1px solid #878787;
        box-shadow: 0px 4px 4px rgba(117, 137, 149, 0.25);
        margin: auto;
      }

      &__glow {
        @include square(24px);
        background-color: transparent;
        border-radius: 50%;
        border: solid 1px transparent;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &--selected {
        background-color: rgba(71, 157, 200, 0.1);
        border: solid 1px rgba(0, 112, 201, 0.15);

        .apply-stepper__indicator {
          &__content {
            background-color: #479dc8;
            border-color: #479dc8;
          }

          &__glow {
            background-color: rgba(71, 157, 200, 0.1);
            border: solid 1px rgba(0, 112, 201, 0.15);
          }
        }
      }
    }

    &__line {
      background-image: linear-gradient(
        rgba(20, 27, 65, 0),
        rgba(20, 27, 65, 0.26),
        rgba(3, 3, 1, 0)
      );
      bottom: 0;
      left: 21px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 4px;
    }

    &--large {
      .apply-stepper__steps {
        row-gap: 50px;
      }
    }
  }
}

@include respond(768px) {
  .apply {
    &-hero {
      background-color: $new-grey;

      &__image {
        @include responsive-aspect-ratio(calc(307 / 553));
        background-image: url('../../../img/tech-repairing-oven-2-new.png');
      }
    }

    &-benefits__card {
      &__background {
        opacity: 0;
      }

      &:hover {
        border-color: #f5f5f5;
        box-shadow: 0 4px 12px rgba(71, 157, 200, 0.25);

        .apply-benefits__card__background {
          opacity: 1;
        }
      }
    }

    &-calculator__container {
      background-image: url('../../../img/desktop-bg-dots.png');
    }
  }
}

@include respond(1024px) {
  .apply {
    &-hero {
      &__container {
        padding-bottom: 10vh !important;
        padding-top: 15vh !important;
      }

      &__title {
        font-size: 58px !important;
      }

      &__image {
        @include responsive-aspect-ratio(calc(590 / 766));
        border-radius: 10px;
        margin-left: 50px;
      }

      &__image-large {
        background-image: url('../../../img/tech-repairing-oven-2-new.png');
        height: 476px;
      }
    }

    &-benefits {
      padding-bottom: 98px !important;
      padding-top: 98px !important;
    }

    &-vendors__text {
      max-width: 270px;
    }

    &-testimonials {
      &__container {
        height: 700px;
      }

      .apply-container {
        grid-auto-rows: 450px;
        grid-template-areas: 'bubbles texts';
        grid-template-columns: 428px minmax(auto, 600px);

        .apply-testimonials {
          &__texts {
            grid-area: texts;

            &__text {
              top: 50%;
              transform: translateY(-50%);
            }
          }

          &__bubbles {
            grid-area: bubbles;
          }
        }
      }
    }

    &-hiw__slider__container {
      column-gap: 118px;
      grid-template-columns: 364px 500px;
    }
  }
}
