// cleaned
#ApplicationTracker {
  background: #ffffff !important;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 8em;

  h1 {
    font-weight: 400;
  }

  .applicationStep {
    margin-bottom: 1.5em;
    z-index: 2;
    position: relative;

    &.active {
      .tracker-icon {
        border: 1px solid #1dabd0;
        color: #1dabd0;
      }

      .sub-heading-3 {
        color: black !important;
      }
    }
    .tracker-icon {
      background: white;
      border-radius: 5px;
      color: #94a2b9;
      line-height: 40px;
      padding: 0;
      text-align: center;
      width: 40px;
      height: 40px;
      border: 1px solid #e0e0e0;
      display: inline-block;
      margin: 0px auto;
      margin-right: 1em;
    }

    .tracker-text {
      line-height: 1.5em;

      .sub-heading-3 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1rem;
        color: #94a2b9;
      }
    }
  }

  .sub-text-2 {
    font-weight: 500;
    //color: black;
  }
  .applicationLine {
    width: 1px;
    z-index: 1;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    top: 0;
    left: 35px;
  }

  .infocards {
    max-width: 90%;
    margin: 0px auto;
    font-weight: 500;
    font-size: 0.9rem;

    .card {
      padding: 1em !important;
    }
    .link {
      color: #1dabd0;
    }
  }
}
