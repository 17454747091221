.nana-calendar {
  &.react-calendar {
    border: 0;
  }

  .react-calendar {
    &__navigation__label {
      background-color: transparent !important;

      span {
        color: black;
        font-family: 'Myriad';
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 24px;
      }
    }

    &__month-view__weekdays__weekday {
      abbr {
        cursor: default;
        font-family: 'Myriad';
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 15px;
        text-decoration: none;
        text-transform: capitalize;
      }
    }

    &__tile {
      padding: 0;
      border-left: solid 1px #e4e4e4;
      border-bottom: solid 1px #e4e4e4;

      &:nth-child(7n) {
        border-right: solid 1px #e4e4e4;
      }

      &:nth-child(-n + 7) {
        border-top: solid 1px #e4e4e4;
      }

      abbr {
        display: none;
      }

      .tile-inner {
        padding: 0.75em 0.5em;

        &--selected {
          background-color: #479dc8;

          p {
            color: white !important;
            font-weight: bold;
          }
        }
      }

      &.react-calendar__tile:disabled {
        background-color: #f0f0f0;
      }

      &.react-calendar__tile--active,
      &.react-calendar__tile--active:enabled:hover,
      &.react-calendar__tile--active:enabled:focus,
      &.react-calendar__tile--now:enabled:focus,
      &.react-calendar__tile:enabled:focus {
        background-color: transparent;
      }

      &.react-calendar__tile:enabled:hover,
      &.react-calendar__tile--now:enabled:hover {
        background-color: #e4e4e4;
      }

      &.react-calendar__tile--now {
        background-color: transparent;
      }

      &.react-calendar__month-view__days__day--neighboringMonth {
        p {
          color: #e4e4e4 !important;
        }
      }
    }
  }
}
