// Myriad
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/Myriad-300.ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/Myriad-500.otf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/Myriad-600.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/Myriad-800.otf');
  font-style: normal;
  font-weight: 800;
}

// Myriad Pro
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-Light.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-LightIt.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-Regular.otf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-It.otf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-Semibold.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-SemiboldIt.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-Bold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-family: 'Myriad';
  src: url('../fonts/Myriad/MyriadPro-BoldIt.otf');
  font-style: italic;
  font-weight: 700;
}
