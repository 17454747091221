html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.flex {
  justify-content: inherit !important;
}

.border {
  border-color: inherit !important;
}

/* hide arrows in focused number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #878787;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
