/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #67b23c;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
//$light-blue: #479DC8;
$dark-blue: #394b64;
$dark-gray: #646b73;
/*** Button Styles ***/
$btn-primary: $light-blue;
$btn-secondary: $light-blue;

.jumbotron {
  border-radius: 0 !important;
}

#contact2 {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  em,
  b {
    font-family: 'Myriad', sans-serif !important;
    margin: 0;
    font-weight: 500;
  }

  .jumbo-contact {
    padding: 4em 0em;
    padding-top: 20vh;
  }

  .icon-contact {
    margin-bottom: 1em;
  }

  h1 {
    font-size: 32px !important;
    @media (max-width: 576px) {
      font-size: 28px !important;
    }
  }

  h2 {
    color: #888 !important;

    @media (max-width: 576px) {
      font-size: 24px !important;
    }
  }

  p {
    color: #878787 !important;
    line-height: 1.5em;
    font-size: 1em !important;
  }

  .card-contact {
    padding: 3em 1em;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 0.5em;

    img {
      margin: 0px auto;
      height: 50px;
    }
  }

  .btn {
    font-family: 'Myriad', sans-serif !important;
    font-size: 16px !important;
    font-weight: 600;

    &.primary {
      background: #1a6bc6;
      border-radius: 4px;
      padding: 1em 1.5em;
      transition: all 200ms ease;
      color: white;
      text-transform: none !important;

      &:hover {
        background: darken(#1a6bc6, 5%);
      }
    }
  }
}
