/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;

/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #23d192;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
$dark-blue: #394b64;
$dark-gray: #646b73;
$border-color: #e6ebf1;
$title-color: #001837;
$muted: #7a838e;
$blue: #003bde;

#layout-up {
  min-height: 100vh;

  .laup-col-left {
    background: $black;
    min-height: 100vh;
  }
  .laup-col-right {
    min-height: 100vh;
    background: white;
  }

  .cp-client {
    label,
    p,
    h5 {
      font-family: $font-primary !important;
      color: white;
    }

    label {
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    p {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
    .sty-f1 {
      &.header {
        padding: 2em 1em;
      }
    }
  }
}
