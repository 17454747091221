$font: 'Myriad', sans-serif;
$font-2: 'Myriad', sans-serif;
$bg: #f7fafc;
$text: #878787;
$bold: #2e3b48;
$muted: #9499a3;
$less-muted: #798192;
$border: #e3e8ee;
$blue: #1a6bc6;
$r-blue: #1558a3;
$c-black: #2e3c49;
$c-green: #40d78f;
$c-gray: #dfe2e8;
$c-red: #e90f09;
$c-yellow: #fcd240;
$c-pink: #e84f89;
$c-orange: #fd9926;
$c-purple: #8e69ec;
$c-teal: #34cbdb;
$c-blue: #2453d5;
$c-darkBlue: #021635;
$c-royalBlue: #0f183e;
$c-lightBlue: #5195ff;

$scale-xl: 1;
$scale-lg: 0.9;
$scale-md: 0.8;

#Admin {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a {
    font-family: $font;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0px;
    text-rendering: optimizeLegibility;
  }
  .text-dark {
    color: #000d40 !important;
  }

  .text-green {
    color: #5ac894 !important;
  }

  .text-red {
    color: $c-red !important;
  }

  .text-purple {
    color: $c-purple !important;
  }

  .text-orange {
    color: $c-orange !important;
  }

  .rounded-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #f7f8fa;
  }

  h1 {
    font-size: calc(#{$scale-lg} * 3.052em);
    font-weight: 500;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 3.052em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 3.052em);
    }
    @media (max-width: 540px) {
      font-size: 34.84px;
    }
  }

  h2 {
    font-size: calc(#{$scale-lg} * 2.441em);
    font-weight: 500 !important;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 2.441em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 2.441em);
    }

    @media (max-width: 540px) {
      font-size: 29.03px;
    }
  }

  h3 {
    font-size: calc(#{$scale-lg} * 1.953em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.953em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.953em);
    }

    @media (max-width: 540px) {
      font-size: 24.19px;
    }
  }

  h4 {
    font-size: calc(#{$scale-lg} * 1.763em);
    font-weight: 500;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.763em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.763em);
    }

    @media (max-width: 540px) {
      font-size: 20.16px;
    }
  }

  h45 {
    font-family: $font;
    font-size: calc(#{$scale-lg} * 1.45em);
    font-weight: 500;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.45em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.45em);
    }

    @media (max-width: 540px) {
      font-size: 20.16px;
    }
  }

  h5 {
    font-size: calc(#{$scale-lg} * 1.25em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.25em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.25em);
    }

    @media (max-width: 540px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 16px;
    color: $text;
    font-weight: 700;
  }

  ulabel {
    font-family: $font;
    font-size: calc(#{$scale-lg} * 14px);
    color: #2e3b48;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08ch;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 14px);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 14px);
    }

    &.sm {
      font-size: calc(#{$scale-lg} * 10px);
      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 10px);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 10px);
      }
    }
  }

  small {
    font-size: calc(#{$scale-lg} * 0.64em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 0.64em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 0.64em);
    }
  }

  a {
    color: $blue;
    font-weight: 600;
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      color: darken($blue, 15%);
    }
  }

  .rounded-btn {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #eceff1;
    transition: all 200ms ease;
    outline: none;
    border: none;
    cursor: pointer;

    &.sm {
      width: 25px;
      height: 25px;
    }

    &:hover {
      background: darken(#eceff1, 10%);
    }

    &.green {
      background: $c-green;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-green, 5%);
      }
    }

    &.red {
      background: $c-red;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-red, 5%);
      }
    }

    &.orange {
      background: $c-orange;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-orange, 5%);
      }
    }

    &.blue {
      background: $r-blue;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($r-blue, 5%);
      }
    }
  }

  .text-blue {
    color: $r-blue !important;
  }

  .text-bold {
    color: $bold !important;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  btn,
  .adminBtn {
    padding: 10px 18px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: $font;
    font-size: calc(#{$scale-lg} * 1em);
    font-weight: 700;
    transition: all 200ms ease;
    cursor: pointer;
    position: relative;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1em);
      padding-top: calc(#{$scale-xl} * 10px);
      padding-bottom: calc(#{$scale-xl} * 10px);
      padding-left: calc(#{$scale-xl} * 18px);
      padding-right: calc(#{$scale-xl} * 18px);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1em);
      padding-left: calc(#{$scale-md} * 18px);
      padding-right: calc(#{$scale-md} * 18px);
      padding-top: calc(#{$scale-md} * 10px);
      padding-left: calc(#{$scale-md} * 10px);
    }

    &.sm {
      font-size: calc(#{$scale-lg} * 0.9em);
      padding: 5px 10px;

      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 0.9em);
        padding-top: calc(#{$scale-xl} * 5px);
        padding-bottom: calc(#{$scale-xl} * 5px);
        padding-left: calc(#{$scale-xl} * 10px);
        padding-right: calc(#{$scale-xl} * 10px);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 0.9em);
        padding-left: calc(#{$scale-md} * 10px);
        padding-right: calc(#{$scale-md} * 10px);
        padding-top: calc(#{$scale-md} * 5px);
        padding-bottom: calc(#{$scale-md} * 5px);
      }
    }

    &.primary {
      background: $r-blue;
      color: white;

      i,
      p {
        color: white !important;
      }

      &:hover {
        background: darken($r-blue, 10%);
        color: white;
      }
    }

    &.secondary {
      background: #eceff1;
      color: $bold;

      &:hover {
        background: darken(#eceff1, 10%);
        color: $bold;
      }
    }

    &.tertiary {
      background-image: linear-gradient(
        to bottom,
        transparent,
        transparent 50%,
        rgba(0, 0, 0, 0.04)
      );
      border: 1px solid $border;

      i,
      p {
        color: $bold !important;
      }

      &:hover {
        border-color: darken($border, 10%);
      }
    }

    &.link {
      background: none;
      color: $r-blue;

      &:hover {
        background: none;
        color: darken($r-blue, 10%);
      }
    }
  }
}

.admin-communication-body {
  background-image: url('https://i.imgur.com/9ftjpbX.jpg');
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f2f4f6;
  background-size: contain;
  background-position: center;
  overflow: hidden !important;

  .ac-nav--filler {
    height: 70px;
    display: block;
  }

  .ac-form {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    //width: 100%;

    &.input {
      background: #f3f5f7;
      border-radius: 4px;
      padding: 0px 16px;

      i {
        left: 16px;
      }
    }

    i {
      position: absolute;
      left: 0;
      color: $muted;
      width: 16px;
      font-size: 12px;
      //top: 0;
    }

    &.dropdown {
      i {
        right: 0px;
        margin-left: auto;
        width: 16px;
      }
    }
  }

  .form-group {
    margin: 0px !important;
    width: auto !important;

    select.form-control {
      border: 0;
      background: none;
      -webkit-appearance: none;
      font-size: calc(#{$scale-lg} * 1em);
      color: $text;
      font-weight: 700;
      padding: 0px;
      height: auto !important;
      margin: 0px !important;
      font-family: $font !important;

      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 1em);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 1em);
      }
    }
  }

  .ac-nav {
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    //z-index: 999;
    background: white;
    border-bottom: 1px solid $border;
    padding: 0px 16px;
    box-shadow: 0 2px 4px 0 rgba(161, 166, 174, 0.24);
    height: 70px;

    .ac-nav--title {
      font-family: $font;
      font-weight: 700;
      font-size: calc(#{$scale-lg} * 18px);
      color: $bold;

      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 18px);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 18px);
      }
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 16px);
    }
  }

  .ac-main-body,
  .ac-side-bar {
    height: calc(100vh - 70px);
    background: white;
  }

  .status-badge {
    font-size: 12px !important;
    font-weight: 800 !important;
    padding: 5px 12px;
    border-radius: 3px;
    background: $bg;
    display: inline-flex;
    color: $muted;
    font-family: $font;

    &.red {
      background: $c-red;
      color: white;
    }

    &.green {
      background: $c-green;
      color: white;
    }
  }

  .ac-side-bar {
    height: 100%;
    //background:  #162942;
    background: white;
    padding: 0.5em;
    border-right: 1px solid $border;

    &.right {
      background: white;
    }

    .ac-sidebar--item {
      padding: 16px;
      border-radius: 4px;
      transition: all 200ms ease;
      cursor: pointer;

      p {
        font-weight: 800 !important;
        color: #868fa0 !important;
      }

      label {
        color: #b5b8be !important;
      }

      &:hover {
        background: rbga(0, 0, 0, 0.1);
      }

      &.active {
        background: $bg;

        p {
          font-weight: 800 !important;
          color: $c-blue !important;
        }

        label {
          color: #868fa0 !important;
        }
      }
    }
  }

  .ac-inbox {
    //background: #F7F7F9;
    //background: #FAFBFD;
    //background: white;
    height: calc(100vh - 70px);
    overflow-y: auto;
    position: relative;
    //margin-top: 70px;
    border-right: 1px solid $border;

    .ac-loader {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 26);

      .ac-loader--container {
        height: 100%;
        width: 100%;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

    .ac-inbox--header {
      height: 54px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .ac-inbox--filler {
      height: 54px;
      display: block;
    }

    .ac-inbox--messages {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      background: white;

      .ac-inbox--card {
        background: #f7f7f9;
        //padding: 16px 8px;
        border-bottom: 1px solid $border;
        transition: all 200ms ease;
        border-left: 3px solid transparent;
        cursor: pointer;

        &.unread {
          background: #fff;

          .ac-card--title {
            .ac-card--title-text {
              color: $r-blue !important;

              &.active {
                font-weight: 800 !important;
              }
            }
          }
        }

        &.active {
          //background: white;
          background: #f4fcfb !important;
          border-left-color: rgb(13, 177, 237);
        }

        .ac-inbox--container {
          width: calc(100% - 35px);
          padding-left: 12px;

          //display: flex;
          //flex-direction: row;
        }

        .ac-card--title {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;

          .ac-card--title-text {
            color: $bold !important;

            &.active {
              font-weight: 800 !important;
            }
          }
          .ac-card--badge {
            font-family: $font;
            font-size: 10px;
            font-weight: 800;
            padding: 4px 12px;
            border-radius: 40px;
            background: #f0f8ff;
            color: #6982a6;
            margin-left: 16px;

            &.active {
              background: #f0f8ff;
              color: $r-blue;
            }
          }
        }

        .ac-card--body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;

          .btn-fav {
            width: 25px;
            //height: 25px;
            text-align: center;
            display: inline-flex;
          }
          .ac-card--text {
            width: calc(100% - 31px);
            text-overflow: ellipsis;
            padding-right: 16px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .ac-action-bar {
    padding: 8px 16px;

    .form-group {
      margin: 0px !important;
      background: none;

      label {
        display: none !important;
      }

      .form-control {
        border: none;
        background: none;
        box-shadow: none;
      }
    }
  }

  .messenger {
    position: relative;
    height: calc(100vh - 70px) !important;
    overflow-y: auto;

    .messenger-header {
      background: white;
    }

    .ReactVirtualized__Grid.ReactVirtualized__List {
      height: calc(100vh - 75px - 70px) !important;
      overflow-y: hidden;
      max-height: none !important;

      .ReactVirtualized__Grid__innerScrollContainer {
        height: calc(100vh - 75px - 70px) !important;
        overflow-y: auto !important;
        padding-bottom: 10vh;
        background: #f2f2f2;
        max-height: none !important;

        .messenger-card {
          position: relative !important;
          background: white;
          height: auto !important;
          width: 100%;
          margin: 0px !important;
          top: 0px !important;

          &.status-update {
            background: #111 !important;
            h5,
            b,
            p {
              color: white !important;
            }
          }
        }
      }
    }

    .messenger-body {
      //height: calc(100% - 75px);
      overflow-y: auto;
    }
  }
}

.parent-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f2f4f6;
  background-image: url('https://i.imgur.com/9ftjpbX.jpg');
  background-size: contain;
  background-position: center;
  overflow: hidden !important;

  nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .levelOne {
      width: 100%;
      padding: 8px 16px;
      background: $bold;

      @media (min-width: 2000px) {
        padding-top: calc(#{$scale-xl} * 8px) !important;
        padding-bottom: calc(#{$scale-xl} * 8px) !important;
        padding-left: calc(#{$scale-xl} * 1em) !important;
        padding-right: calc(#{$scale-xl} * 1em) !important;
      }

      &.escalation {
        background: #a32d2d;
      }

      &.flagsActive {
        background: #e0891c;
      }
    }

    .levelTwo {
      display: flex;
      align-items: center;
      background: white;
      border-bottom: 1px solid $border;
      padding: 16px;
      box-shadow: 0 2px 4px 0 rgba(161, 166, 174, 0.24);

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 16px);
      }
      .bc-status {
        padding: 0em 15px;
        border-left: 1px solid $border;

        small {
          font-size: calc(#{$scale-lg} * 12px);
          font-weight: 800 !important;
          color: $muted;
          letter-spacing: 0.025ch;
          text-transform: uppercase;

          @media (min-width: 2000px) {
            font-size: calc(#{$scale-xl} * 12px);
          }

          @media (max-width: 1280px) {
            font-size: calc(#{$scale-md} * 12px);
          }
        }

        p {
          font-weight: 700 !important;
          color: $bold !important;
          font-size: calc(#{$scale-lg} * 16px);

          @media (min-width: 2000px) {
            font-size: calc(#{$scale-xl} * 16px);
          }

          @media (max-width: 1280px) {
            font-size: calc(#{$scale-md} * 16px);
          }
        }
      }
    }

    .breadcrumb-list {
      list-style: none !important;
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;
      display: inline-flex;
      align-items: center;

      li {
        &.bc-item {
          display: inline-block;
          padding: 0em 0.3em;
          font-family: $font;
          font-weight: 700;
          font-size: calc(#{$scale-lg} * 18px);
          color: $muted;

          @media (min-width: 2000px) {
            font-size: calc(#{$scale-xl} * 18px);
          }

          @media (max-width: 1280px) {
            font-size: calc(#{$scale-md} * 18px);
          }

          &.active {
            color: $bold;
          }

          &.arrow {
            i {
              padding-top: 7px;
            }
          }
        }
      }
    }
  }

  #admin-messenger {
    .admin-chat-box {
      min-height: 50vh;
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      &.dark {
        background: #272e38 !important;
        padding: 12px 8px;

        .admin-chat-bubble {
          background: rgba(255, 255, 255, 0.15);
          border-radius: 5px;
          margin-bottom: 8px;
          border: none;
          margin-left: 0px;
          margin-right: 16px;
          border-radius: 5px;

          &.blue-bubble {
            background: $r-blue !important;
            margin-left: 32px;
            margin-right: -8px;
          }

          .admin-chat-text {
            .chat-title {
              color: white !important;
            }
            .chat-text {
              color: rgba(255, 255, 255, 0.75) !important;
            }
            label {
              color: white !important;
            }
          }
        }
      }

      .admin-chat-bubble {
        border-bottom: 1px solid $border;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px 8px;

        .admin-chat-icon {
          width: 25px;
          height: 25px;
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          background: $c-pink;
          color: white;
          position: relative;

          &.new {
            &:after {
              content: '';
              position: absolute;
              top: 0px;
              right: -4px;
              width: 12px;
              height: 12px;
              border-radius: 16px;
              background: $c-green;
              border: 2px solid white;
            }
          }

          &.Email {
            background: $c-teal;
            color: #fff;
          }

          &.Call {
            background: $c-pink;
            color: white;
          }

          &.Text {
            background: $c-orange;
            color: white;
          }
        }

        .admin-chat-text {
          padding-left: 8px;
          flex: 1;

          .chat-title {
            font-weight: 800 !important;
            color: $bold !important;
          }

          .chat-text {
            word-break: break-word;
          }

          label {
            font-weight: 800 !important;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .body-container {
    padding: 1em;
    margin-top: 129px;
    width: 100%;
    height: calc(100vh - 129px);
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: 2000px) {
      padding: calc(#{$scale-xl} * 1.2em) !important;
    }

    .admin-sidebar {
      background: white;
      border: 1px solid $border;
      border-radius: 4px;
      //max-width: 300px;

      .dropdown {
        &:hover {
          .btn-secondary {
            transition: all 200ms ease;
            border-radius: 4px;
            background: rgba(18, 96, 108, 0.15) !important;
          }
        }

        .btn-secondary {
          padding: 0px !important;
          color: inherit !important;
          text-transform: none !important;
          text-align: inherit;
          font-size: inherit;
          color: $text;
        }

        .dropdown-menu {
          margin-top: -3px;
          -webkit-animation: slideInToTop 200ms ease forwards;
          animation: slideInToTop 200ms ease forwards;
          padding: 0em;

          @keyframes slideInToTop {
            0% {
              opacity: 0;
              margin-top: 25px;
            }
            100% {
              opacity: 1;
              margin-top: -3px;
            }
          }

          .dropdown-item {
            font-weight: 700 !important;
            padding: 0.75em 1em;
            background: white;
            transition: all 200ms ease;
            cursor: pointer;

            i {
              color: $r-blue;
            }
            p {
              color: $bold !important;
              font-weight: 700 !important;
            }

            &:hover {
              background: $bg !important;

              p {
                color: $r-blue !important;
              }
            }
          }
        }
      }

      .sidebar-item {
        padding: 16px;

        @media (max-width: 1280px) {
          padding: calc(#{$scale-md} * 16px);
        }

        &.header {
          padding: 24px 16px;

          @media (min-width: 2000px) {
            padding-left: calc(#{$scale-xl} * 16px) !important;
            padding-right: calc(#{$scale-xl} * 16px) !important;
            padding-top: calc(#{$scale-xl} * 24px) !important;
            padding-bottom: calc(#{$scale-xl} * 24px) !important;
          }
        }

        .sidebar-item-title {
          .sidebar-item-title-text {
            font-weight: 800 !important;
          }
        }

        @media (min-width: 2000px) {
          padding: calc(#{$scale-xl} * 16px) !important;
        }

        &.tags {
          .Select-control {
            background: #f5f7f8 !important;
            border: none !important;
            outline: none !important;
            font-family: $font !important;
            font-weight: 600 !important;
            font-size: calc(#{$scale-lg} * 1em) !important;
            color: $text !important;

            @media (min-width: 2000px) {
              font-size: calc(#{$scale-xl} * 1em) !important;
            }
          }

          .Select-menu-outer {
            font-family: $font !important;
            font-weight: 600 !important;
            font-size: calc(#{$scale-lg} * 1em) !important;
            color: $text !important;
            border: none !important;
            box-shadow:
              0 2px 3px 0 rgba(50, 50, 93, 0.1),
              0 0px 1px 0 rgba(0, 0, 0, 0.07);

            @media (min-width: 2000px) {
              font-size: calc(#{$scale-xl} * 1em) !important;
            }
          }
        }

        .visit-card-list {
          margin-top: 1em;

          .visit-card-item {
            padding: 10px;

            @media (min-width: 2000px) {
              padding: calc(#{$scale-xl} * 10px) !important;
            }
          }
        }
      }

      h4 {
        font-size: calc(#{$scale-lg} * 1.3em) !important;
        font-weight: 700 !important;
        margin-bottom: 0.2em;

        @media (min-width: 2000px) {
          font-size: calc(#{$scale-xl} * 1.5em) !important;
        }

        @media (max-width: 1280px) {
          font-size: calc(#{$scale-md} * 1.5em);
        }
      }

      p {
        color: $muted;
        font-weight: 700;
      }
    }

    .admin-inner-nav {
      background: white;
      border-radius: 4px;
      border: 1px solid $border;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .admin-nav-item {
        .admin-nav-link {
          height: 50px;
          display: inline-flex;
          align-items: center;
          padding: 16px;
          font-family: $font;
          border-bottom: 2px solid transparent !important;
          font-size: calc(#{$scale-lg} * 1em);
          color: $text !important;
          font-weight: 700;

          @media (min-width: 2000px) {
            font-size: calc(#{$scale-xl} * 1em);
          }

          @media (max-width: 1280px) {
            font-size: calc(#{$scale-md} * 1em);
          }

          &.active {
            border-bottom: 2px solid $r-blue !important;
            color: $r-blue !important;
          }
        }
      }
    }

    .admin-main-body {
      .vc-container {
        .vc-header {
          padding: 15px;
          background: white;
          border-bottom: 1px solid $border;
        }

        .vc-list {
          .vc-item {
            background: white;
            border-bottom: 1px solid $border;
            transition: all 200ms ease;

            &.clickable {
              &:hover {
                background: #fafafa !important;
              }
            }
            .vc-form-item {
              position: relative;

              &.pick,
              &.radio,
              &.select,
              &.check {
                .form-arrow {
                  display: block !important;
                }
              }

              .form-arrow {
                position: absolute;
                right: 12px;
                z-index: 99;
                display: none;
                bottom: 2px;
              }
            }

            .form-group {
              position: relative;

              label {
                color: $bold !important;
                font-family: $font !important;
                font-size: calc(#{$scale-lg} * 1em) !important;
                color: $text;
                font-weight: 700 !important;

                @media (min-width: 2000px) {
                  font-size: calc(#{$scale-xl} * 1em) !important;
                }

                @media (max-width: 1280px) {
                  font-size: calc(#{$scale-md} * 1em) !important;
                }
              }

              .form-control,
              input {
                z-index: 1;
                -webkit-appearance: none;
                background-color: white;
                color: #1e3a5e;
                font-weight: 700 !important;
                border: 1px solid #dde2e9 !important;
                border-radius: 3px;
                font-family: $font;
                font-size: 16px !important;
                height: 45px;
                display: flex;
                align-items: center;
                padding: 8px;
                transition: all 200ms ease;
                position: relative;

                &:hover,
                &:focus,
                &:active,
                &:visited {
                  box-shadow:
                    0 2px 3px 0 rgba(50, 50, 93, 0.1),
                    0 0px 1px 0 rgba(0, 0, 0, 0.07);
                }
              }
            }

            .vc-item--left {
              text-align: center;
              padding: 16px 24px;
              min-width: 80px;

              label {
                display: block;
                font-weight: 700 !important;
              }
            }

            .vc-item--right {
              border-left: 1px solid #e3e8ee;
              .vc-item--blurb {
                margin-top: 1em;
              }
            }
          }
        }

        .photo-grid {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          background: white;
          padding: 0.1em;

          .photo-grid-item {
            background: $bg;
            margin: 0.2em;

            img {
              width: 80px;
              height: 80px;
              object-fit: cover;
              object-position: center;
              cursor: pointer;
              transition: all 200ms ease;
              &:hover {
                opacity: 0.8;
              }
            }
          }
        }

        .form-group {
          position: relative;

          label {
            color: $bold !important;
            font-family: $font !important;
            font-size: calc(#{$scale-lg} * 1em) !important;
            color: $text;
            font-weight: 700 !important;

            @media (min-width: 2000px) {
              font-size: calc(#{$scale-xl} * 1em) !important;
            }

            @media (max-width: 1280px) {
              font-size: calc(#{$scale-md} * 1em) !important;
            }
          }

          .form-control,
          input {
            z-index: 1;
            -webkit-appearance: none;
            background-color: white;
            color: #1e3a5e;
            font-weight: 700 !important;
            border: 1px solid #dde2e9 !important;
            border-radius: 3px;
            font-family: $font;
            font-size: 16px !important;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 8px;
            transition: all 200ms ease;
            position: relative;

            &:hover,
            &:focus,
            &:active,
            &:visited {
              box-shadow:
                0 2px 3px 0 rgba(50, 50, 93, 0.1),
                0 0px 1px 0 rgba(0, 0, 0, 0.07);
            }
          }
        }
        .vc-input {
          &.file {
            align-items: center;
            display: flex;
            background: #f5f7f8;
            padding: 0.5em;
            border-radius: 4px;
            .form-group {
              margin: 0px;

              .form-control {
                font-family: $font;
                background: none;
                font-weight: 700;
                color: $muted;
              }
            }
          }
          .form-control {
            font-family: $font;
            font-weight: 700;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
            background-color: #fafafa;
            border: none;
            font-size: calc(#{$scale-lg} * 0.9em) !important;

            @media (min-width: 2000px) {
              font-size: calc(#{$scale-xl} * 0.9em) !important;
            }

            @media (max-width: 1280px) {
              font-size: calc(#{$scale-md} * 0.91em) !important;
            }

            &::placeholder {
              font-family: $font;
              font-weight: 700;
              color: $muted !important;
            }
          }
        }

        .vc-table {
          .vc-badge {
            border-radius: 3px;
            background: #e3e8ee;
            text-align: center;
            height: 25px;
            width: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          .vc-status {
            font-weight: 800 !important;
            padding: 4px 8px;
            border-radius: 3px;
            background: $bg;
            display: inline-flex;
            color: white !important;
          }

          &.less-padding {
            th,
            td {
              padding: 4px 8px !important;
            }
          }

          &.no-wrap {
            p {
              white-space: nowrap !important;
            }
          }

          thead {
            th {
              background: #f3f5f6;

              p {
                font-weight: 700 !important;
                color: $muted !important;
              }
            }
          }

          tbody {
            &::-webkit-scrollbar {
              width: 2px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background: #e4e4e4;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #1e70bf;
              border-radius: 10px;
            }
          }

          tr {
            background: white;
            transition: all 200ms ease;

            &:nth-child(even) {
              background: white;
            }
            &:hover {
              background: #f9fbfe;
            }

            &.activeRow {
              background: #f3fbfe;
            }
          }

          th,
          td {
            padding: 16px 12px;
            border-bottom: 1px solid $border;

            p {
              color: $bold;
              font-weight: 700;
            }

            /*
            &:nth-child(2){
              width: 28%;
            }

            &:last-child{
              width: 40px;
            }
            */
          }
        }

        .vc-footer {
          background: #f5f7f8;
          padding: 16px 24px;
        }

        .vc-action-bar {
          border-radius: 4px;
          //box-shadow: 0 50px 60px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), 0 -18px 60px -10px rgba(0, 0, 0, 0.4);
          overflow: hidden;

          .vc-action-bar--item {
            background: white;
            padding: 10px 16px;
            border-right: 1px solid #e4e4e4;
            flex: 1;
            text-align: center;
            transition: all 200ms ease;
            cursor: pointer;

            &:hover {
              background: $bg;
            }

            &.status {
              i {
                color: $r-blue !important;
              }
            }
            &.delete {
              i {
                color: $c-red !important;
              }
            }
            &.email {
              i {
                color: $c-orange !important;
              }
            }
            &.add {
              i {
                color: $c-teal !important;
              }
            }

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
}

#vc-table {
  .vc-badge {
    border-radius: 3px;
    background: #e3e8ee;
    text-align: center;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .vc-status {
    font-size: 10px !important;
    font-weight: 800 !important;
    padding: 4px 8px;
    border-radius: 3px;
    background: $bg;
    display: inline-flex;
    color: white !important;
  }

  &.less-padding {
    th,
    td {
      padding: 4px 8px !important;
    }
  }

  &.td-border-all {
    td,
    th {
      border: 1px solid $border;
    }
  }

  &.no-wrap {
    p {
      white-space: nowrap !important;
    }
  }

  .ledgerRow {
    td {
      white-space: nowrap;
    }
  }

  thead {
    th {
      background: #f3f5f6;

      p {
        font-weight: 700 !important;
        color: $muted !important;
      }
    }
  }

  tbody {
    /* display: block;
        max-height: 50vh;
        overflow-y: auto;
      */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: #e4e4e4;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1e70bf;
      border-radius: 10px;
    }
  }

  tr {
    background: white;
    transition: all 200ms ease;

    &:nth-child(even) {
      background: white;
    }

    &:last-child {
      //td{border: none !important;}
    }

    &:hover {
      background: #f9fbfe;
    }

    &.activeRow {
      background: #f3fbfe;
    }
  }

  th,
  td {
    padding: 16px 12px;
    border-bottom: 1px solid $border;

    p {
      color: $bold;
      font-weight: 700;
    }

    /*
      &:nth-child(2){
        width: 28%;
      }

      &:last-child{
        width: 40px;
      }
      */
  }
}

.height-100 {
  height: 100% !important;
  max-height: none !important;
}

.authUI {
  th,
  td {
    //padding: 8px 16px;
    align-items: center;

    h4 {
      font-family: $font !important;
      font-weight: 700 !important;
    }

    p {
      font-family: $font !important;
      color: $bold !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      margin: 0px !important;
      padding-right: 0.2em;
    }
  }

  input,
  .form-control {
    font-family: $font !important;
    color: $bold !important;
    font-weight: 700 !important;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder {
      font-family: $font !important;
      color: $muted !important;
    }
  }

  input:disabled,
  .form-control:disabled,
  .form-control[readonly] {
    background: #f5f7f8 !important;
    border: none !important;
    box-shadow: none !important;
    color: $muted !important;

    & ::-webkit-input-placeholder {
      color: $muted !important;
      font-family: $font !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: #f5f7f8 !important;
      box-shadow: none !important;
    }
  }

  .Select-placeholder {
    font-family: $font !important;
    color: $muted !important;
  }
}

.react-dropdown-no-style {
  position: relative;
  button,
  .dropdown,
  .btn-secondary {
    padding: 0px !important;
    margin: 0px !important;
    line-break: inherit;
    position: relative;
    &:after {
      display: none !important;
    }
  }

  &.no-caret {
    .dropdown-menu {
      &:before {
        display: none !important;
      }
    }
  }

  &.inline-dropdown {
    .btn-secondary {
      transition: all 200ms ease;
      background: rgba(18, 96, 108, 0);
      border-radius: 4px;
      transition: all 200ms ease;
    }

    &:hover {
      .btn-secondary {
        transition: all 200ms ease;
        background: rgba(18, 96, 108, 0.15) !important;
      }
    }
    .dropdown {
      display: inline-block !important;
    }

    .dropdown-menu {
      top: -10px !important;

      p {
        word-break: break-word;
        //white-space: pre-line;
      }
    }
  }
  .dropdown-menu {
    padding: 16px 8px !important;
    border-radius: 4px;
    //box-shadow: 0 50px 100px -20px rgba(50, 50, 93, .25), 0 30px 60px -30px rgba(0, 0, 0, .3), 0 -18px 60px -10px rgba(0, 0, 0, .025);
    box-shadow:
      0 0 0 1px rgba(50, 50, 93, 0.05),
      0 2px 5px 0 rgba(50, 50, 93, 0.1),
      0 1px 1px 0 rgba(0, 0, 0, 0.07);
    position: absolute;
    will-change: transform;
    left: -50% !important;
    transform: translateX(50%);
    top: -8px;
    right: 0;
    transform: translate3d(0px, 41px, 0px);
    overflow: visible;

    &:before {
      content: '';
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
      //box-shadow: 0 50px 100px -20px rgba(50, 50, 93, .25), 0 30px 60px -30px rgba(0, 0, 0, .3), 0 -18px 60px -10px rgba(0, 0, 0, .025);
      left: 50%;
      top: -8px;
      transform: translateX(-50%);
      border-radius: 2px;
      z-index: 9999;
      filter: drop-shadow(0px -1px 1px rgba(50, 50, 93, 0.1));
    }

    .dropdown-item {
      padding: 8px 16px !important;
      //border-bottom: 1px solid $border !important;
      transition: all 200ms ease;
      border-radius: 4px;
      border: none !important;

      p {
        font-weight: 700 !important;
        color: $bold !important;

        &.text-red {
          color: $c-red !important;
        }
      }

      &:hover {
        background: $bg;

        p {
          color: $r-blue !important;
          &.text-red {
            color: darken($c-red, 10%) !important;
          }
        }
      }
    }
  }
}

.collapseCom {
  border-radius: 0px !important;
  box-shadow: none !important;
  position: relative;
  overflow: visible !important;

  .collapseActions {
    position: absolute;
    right: 16px;
  }
}

#cx-profile {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a {
    font-family: $font !important;
    font-weight: 500;
    line-height: 1.35em !important;
    margin: 0px !important;
    padding: 0px !important;
    text-rendering: optimizeLegibility;
    text-decoration: none !important;
  }

  input {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    font-family: $font !important;
    font-weight: 600;
    font-size: 16px;
    padding: 0em 0.4em;
    color: $bold;
    outline: none !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid $border !important;
    box-sizing: none;
    box-shadow: none;
    background: #f6f6f6;
    transition: all 200ms ease;

    &::placeholder {
      font-family: $font !important;
      font-weight: 600;
      color: $muted;
    }
    &:hover {
      border-bottom-width: 2px !important;
    }

    &:focus,
    &:active {
      background: #eef3fe;
      border: none !important;
      border-bottom: 2px solid #3c76fc !important;
    }
  }

  .cx-profile-body {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;

    .cx-profile-left {
      background: white;
      width: 500px !important;
      border-right: 1px solid $border;
      height: 100%;
      overflow-y: auto;
      p,
      label {
        font-weight: 500;
        color: #000d40;
      }
      i {
        color: #c5cdd8;
      }

      .cx-profile-left--header {
        background: #f9f9fa;
        padding: 32px 16px;

        &.hasAddress {
          background: white;
          margin-top: -40px;
          padding: 16px;

          .sb-avatar__text {
            border: 4px solid white;
          }
        }

        button {
          width: 100%;
          padding: 8px 12px !important;
          border-radius: 30px;
          background: $blue;
          color: white;
          outline: none;
          border: none;
          transition: all 200ms ease;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          &:hover {
            background: darken($blue, 5%);
          }

          &.white {
            background: white;
            border: 1px solid #dee1e5;
            color: $blue;
            i {
              color: $blue !important;
            }
          }
        }
      }

      .social-block {
        i {
          min-width: 16px;
        }
        .d-flex {
          flex-wrap: wrap;
          white-space: pre-line;
          word-break: break-all;
        }
      }
    }

    .cx-profile-right {
      position: relative;
      width: 100%;
      max-height: 100vh;
      overflow: hidden;
      border-right: 2px solid $border;

      .cx-tabs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        background: #fafaf9;
        border-bottom: 2px solid $border;
        display: flex;
        justify-content: center;
        .cx-tabs-item {
          .nav-link {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 60px;
            padding: 0px 16px !important;
            border-bottom: 2px solid transparent;
            p {
              color: #2f405c !important;
            }
            &.active {
              border-bottom: 2px solid $blue;

              p {
                color: $blue !important;
              }
            }
          }
        }
      }

      .cx-tabs--body {
        background-image: url('https://i.imgur.com/9ftjpbX.jpg');
        background-position: center;
        background-size: cover;
        height: calc(100vh - 60px);
        margin-top: 60px;
        padding: 16px;
        overflow-y: auto;
        overflow-x: hidden;
        .cx-profile--container {
          border: none;
        }

        .user_notes {
          position: relative;

          #new-tabContent {
            max-width: none;

            #task_notes {
              padding: 0px !important;

              #AdminTaskDetails {
                background: none !important;
                position: absolute;
                top: 0;
                right: 0px;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                i {
                  font-size: 25px !important;
                }
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }

  .visits {
    padding: 25px;
    background: #fafaf9;
  }

  .visit {
    background: #fff;
  }

  .job-title {
    font-size: 15px;
  }

  .job-create-date {
    font-size: 10px;
    margin-left: 5px !important;
    display: inline-block;
  }

  .cx-profile--container {
    border: 1px solid $border;
    background: white;

    .cx-header {
      padding: 16px;
      background: white;
      border-bottom: 1px solid $border;
    }
    .cx-list {
      .cx-list---item {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid $border;
        transition: all 200ms ease;
        cursor: pointer;
        align-items: center;

        &:hover {
          background: $bg;
        }

        .cx-list--item_left {
          text-align: center;
          min-width: 80px;
          padding: 18px 12px;

          label {
            display: block;
            font-weight: 700 !important;
          }
        }

        .cx-list--item_center {
          flex: 1;
          padding: 12px;
          border-left: 1px solid #e3e8ee;
        }

        .cx-list--item_right {
          padding: 12px;

          @media (max-width: 1000px) {
            display: block;
          }
        }
      }
    }
  }
}

#crm-activity {
  position: relative;
  background: white;
  border: 1px solid $border;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;

  .crm-activity--header {
    padding: 16px;
    background: white;
    border-bottom: 1px solid $border;
  }

  .activity-item {
    border-bottom: 1px solid $border;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    width: 100%;

    .timestamp-badge {
      //background: #F0F2F5;
      //padding: 8px 10px !important;
      //border-radius: 4px;
      color: #7e95a5 !important;
      font-size: 14px;
    }
  }
}

#DMenu {
  position: relative;

  .dropdown {
    &:hover {
      .btn-secondary {
        transition: all 200ms ease;
        border-radius: 4px;
        background: rgba(18, 96, 108, 0.15) !important;
      }
    }

    .btn-secondary {
      padding: 0px !important;
      color: inherit !important;
      text-transform: none !important;
      text-align: inherit;
      font-size: inherit;
      color: $text;
    }

    .dropdown-menu {
      margin-top: -3px;
      -webkit-animation: slideInToTop 200ms ease forwards;
      animation: slideInToTop 200ms ease forwards;
      padding: 0em;
      z-index: 99999;
      border: none;
      box-shadow: 0 12px 60px hsla(0, 0%, 4%, 0.3);
      width: 100%;
      border-radius: 4px !important;
      overflow: hidden;

      @keyframes slideInToTop {
        0% {
          opacity: 0;
          margin-top: 25px;
        }
        100% {
          opacity: 1;
          margin-top: -3px;
        }
      }

      .dropdown-item {
        font-weight: 700 !important;
        padding: 0.75em 1em !important;
        background: white;
        transition: all 200ms ease;
        cursor: pointer;

        i {
          color: $r-blue;
        }
        p {
          color: $bold !important;
          font-weight: 700 !important;
        }

        &:hover {
          background: $bg !important;

          p {
            color: $r-blue !important;
          }
        }
      }
    }
  }
}

.ReactModalPortal {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a {
    font-family: $font !important;
    font-weight: 500;
    line-height: 1.35em !important;
    margin: 0px !important;
    padding: 0px !important;
    text-rendering: optimizeLegibility;
    text-decoration: none !important;
  }

  .ReactModal__Overlay {
    z-index: 999999999;
    transition: all 500ms ease !important;
    background: rgba(0, 0, 0, 0) !important;
    opacity: 0;

    &.ReactModal__Overlay--after-open {
      background: rgba(0, 0, 0, 0.8) !important;
      opacity: 1;
    }
  }
  .ReactModal__Content {
    z-index: 999999999;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    max-height: 700px;
    overflow: hidden;
    border: none !important;
    box-shadow: 0 8px 14px hsla(0, 0%, 4%, 0.2);
    -webkit-animation: slideInToTop 200ms ease forwards;
    animation: slideInToTop 200ms ease forwards;
    padding: 0px !important;
    position: relative;

    @keyframes slideInToTop {
      0% {
        opacity: 0;
        margin-top: 25px;
      }
      100% {
        opacity: 1;
        margin-top: -3px;
      }
    }

    .rmc_header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;
      background: white;
      padding: 8px;
      height: 60px;
      text-align: center;
      border-bottom: 1px solid $border;

      .rmc_title {
        flex: 1;
      }
      .rmc_header-btn {
        cursor: pointer;
        transition: all 200ms ease;
        width: auto;
        &:hover {
          opacity: 0.7;
        }

        &.right {
          text-align: right !important;
          justify-content: flex-end;
          transition: all 200ms ease;

          .rmc-btn {
            background: $blue;
            padding: 8px 16px;
            border-radius: 4px;
            h6 {
              color: white;
            }

            &.disabled {
              background: #e4e4e4;
              h6 {
                color: $muted;
              }
            }
          }
        }
      }
    }

    .rmc_body {
      padding: 16px;
      height: calc(100% - 60px);
      margin-top: 60px;
      width: 100%;
      overflow-y: auto;

      .rmc_body--container {
        max-width: 600px;
        margin: 0px auto;
      }
      label {
        font-weight: 700;
      }
      .form-control,
      input {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 40px;
        font-family: $font;
        font-weight: 600;
        font-size: 16px;
        padding: 0em 0.4em;
        color: $bold;
        outline: none !important;
        border: none;
        border-bottom: 1px solid $border;
        box-sizing: none;
        box-shadow: none;
        background: #f6f6f6;
        transition: all 200ms ease;

        &::placeholder {
          color: $muted;
          font-family: $font;
          font-weight: 600;
          font-size: 16px;
        }

        &:focus,
        &:hover,
        &:active {
          background: #eef3fe;
          border-bottom-width: 2px;
          border-color: #3c76fc;
        }
      }

      .select-container {
        position: relative;

        select {
          -webkit-appearance: button;
          -moz-appearance: button;
          -webkit-user-select: none;
          -moz-user-select: none;
          -webkit-padding-end: 20px;
          -moz-padding-end: 20px;
          -webkit-padding-start: 2px;
          -moz-padding-start: 2px;
          font-size: inherit;
          margin: 0;
          overflow: hidden;
          padding: 2px 8px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .Select {
        .Select-control {
          border: none;
          box-shadow: none;
          border-radius: 0px;
          background: #fafafa;
          font-family: $font !important;
          input {
            background: none !important;
            border: none !important;
            font-family: $font !important;
          }
          .Select-multi-value-wrapper {
            .Select-value {
              background: #ededfb;
              border: none;
              border-radius: 30px;
              padding: 2px 4px;
              color: #4c49b1;
              font-weight: 700;
              .Select-value-icon {
                border: none !important;
                border-radius: 50%;
                padding: 0px 4px !important;
                transition: all 200ms ease;
                cursor: pointer;
                &:hover {
                  background: #4c49b1;
                  color: white;
                }
              }
              .Select-value-label {
                padding-right: 4px !important;
              }
            }
          }
        }

        .Select-menu-outer {
          font-family: $font !important;
          font-weight: 600 !important;
          font-size: calc(#{$scale-lg} * 1em) !important;
          color: $text !important;
          border: none !important;
          box-shadow:
            0 2px 3px 0 rgba(50, 50, 93, 0.1),
            0 0px 1px 0 rgba(0, 0, 0, 0.07);
        }
      }
    }

    .close-button {
      border: none;
      background: none;
      outline: none;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9999;
      width: 50px;
      height: 50px;
      padding: 0px;
      border-radius: 0px;
      background: none !important;
      i {
        color: lighten(#8898aa, 30%) !important;
        transition: all 200ms ease;
      }
      &:hover {
        i {
          color: #8898aa !important;
        }
      }
    }
  }
}
