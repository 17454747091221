// cleaned
/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #67b23c;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
//$light-blue: #479DC8;
$dark-blue: #394b64;
$dark-gray: #646b73;

$nav-height: 80px;

div.ReactModal__Overlay.ReactModal__Overlay--after-open.slide-pane__overlay.toggle-overlay {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

div.ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right.toggle-menu-pane {
  width: 80%;
  max-width: 500px;
  .animatedSlide {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: white;
    -webkit-animation: animatedSlide 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
    animation: animatedSlide 500ms cubic-bezier(0.445, 0.05, 0.55, 0.95) forwards;
    animation-delay: 0ms;
    opacity: 1;
    display: none;

    @keyframes animatedSlide {
      0% {
        opacity: 0;
        height: 100%;
        margin-top: -100vh;
        overflow: hidden;
      }
      25% {
        opacity: 1;
        height: 50%;
        margin-top: 0vh;
        overflow: hidden;
      }
      100% {
        opacity: 1;
        height: 100vh;
        margin-top: 100vh;
        overflow: visible;
      }
    }
  }
  .slide-pane__content {
    background: $black !important;

    .toggle-menu-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      float: right;
      position: relative;

      .toggle-switch {
        background: $white;
        border: none;
        color: $black;
        padding: 0em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0px;
        height: 0px;
        line-height: 50px;
        border-radius: 50%;
        cursor: pointer;
        -webkit-animation: closeBtn 500ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
        animation: closeBtn 500ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
        animation-delay: 200ms;
        opacity: 0;
        transform: scale(0.8);

        @keyframes closeBtn {
          0% {
            opacity: 0;
            width: 0;
            height: 0;
            overflow: hidden;
          }
          100% {
            opacity: 1;
            width: 50px;
            height: 50px;
            overflow: visible;
          }
        }

        svg {
          -webkit-animation: closeIcon 500ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
          animation: closeIcon 500ms cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
          animation-delay: 450ms;
          opacity: 0;
          margin-top: -2em;
          transform: rotate(45deg);

          @keyframes closeIcon {
            0% {
              opacity: 0;
              margin-top: -2em;
              transform: rotate(-45deg);
            }
            100% {
              opacity: 1;
              margin-top: 0em;
              transform: rotate(0deg);
            }
          }
        }
      }
    }

    .menu-nav-links {
      padding-top: 20%;

      ul li {
        color: $white !important;
        list-style-type: none;
        list-style: none;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        padding: 1em 0em;
        cursor: pointer;
        .text-1,
        .text-2,
        .text-3,
        .text-4,
        .text-5,
        .text-6,
        .text-7,
        .text-8,
        .text-9,
        .text-10 {
          -webkit-animation: navLink 1s ease forwards;
          animation: navLink 1s ease forwards;
          opacity: 0;
          display: inline-block;
          transform: translateY(20px) skewX(-5px);
          color: $white !important;

          &:hover {
            color: rgba(255, 255, 255, 0.7) !important;
          }
        }

        .text-1 {
          animation-delay: 200ms;
        }
        .text-2 {
          animation-delay: 250ms;
        }
        .text-3 {
          animation-delay: 300ms;
        }
        .text-4 {
          animation-delay: 350ms;
        }
        .text-5 {
          animation-delay: 400ms;
        }
        .text-6 {
          animation-delay: 450ms;
        }

        @keyframes navLink {
          0% {
            opacity: 0;
            transform: translateY(-20px) skewX(-5deg);
          }
          100% {
            opacity: 1;
            transform: translateY(0px) skewX(0deg);
            transform: matrix(1, 0, 0, 1, 0, 0);
            transform-style: preserve-3d;
          }
        }
      }
    }
  }
}
