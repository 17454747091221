// cleaned
/********************* Colors **********************/
$primaryblue: #1690bf;
$primaryblue: #1690bf;

$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;

/********************* Text Styles **********************/

body {
  font-size: 16px;
  font-family: $font-primary !important;

  ::-moz-selection {
    background: #36c4cc;
    color: #fff;
  }
  ::selection {
    text-shadow: none;
    background: #cce7f5;
  }
}

.bold {
  color: inherit;
  font-size: inherit;
}

.h4 {
  font-size: 1.4em !important;
  font-weight: 400 !important;
  line-height: 1.5em !important;
}

.sub-title {
  font-size: 1.8rem;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: normal;
  @media (max-width: 700px) {
    font-size: 1.8rem;
  }
}

.blue-text {
  color: $primaryblue !important;
}

.bullet-list {
  ul {
    list-style: initial !important;
  }
}

/************ Flex Box *************/
.flex-row {
  display: flex;

  .flex-1 {
    flex: 1;
  }
}

/************ Animation Delay *************/
.delay-1 {
  -webkit-animation-delay: 0.4s !important;
  animation-delay: 0.4s !important;
}

.delay-2 {
  -webkit-animation-delay: 0.6s !important;
  animation-delay: 0.6s !important;
}

.delay-3 {
  -webkit-animation-delay: 0.9s !important;
  animation-delay: 0.9s !important;
}

.delay-4 {
  -webkit-animation-delay: 1.2s !important;
  animation-delay: 1.2s !important;
}

.delay-5 {
  -webkit-animation-delay: 1.4s !important;
  animation-delay: 1.4s !important;
}

.delay-6 {
  -webkit-animation-delay: 1.7s !important;
  animation-delay: 1.7s !important;
}

.delay-7 {
  -webkit-animation-delay: 1.9s !important;
  animation-delay: 1.9s !important;
}

.delay-8 {
  -webkit-animation-delay: 2.1s !important;
  animation-delay: 2.1s !important;
}

/************************* Footer  ************************/
footer {
  bottom: 0 !important;
  width: 100%;
  padding: 50px 0 80px;

  &.black {
    background: #000 !important;
    border: none !important;
    border-top: 1px solid #000 !important;
    font-weight: 500 !important;
    a,
    .ftLinks,
    label {
      color: #fff;
      font-weight: 500 !important;
    }

    a:not(.btn) {
      color: rgba(255, 255, 255, 0.5) !important;

      &:hover {
        color: rgba(255, 255, 255, 1) !important;
      }
    }

    .hollow-btn,
    .hollow-btn:hover {
      border: 2px solid #fff;
      color: #fff;
      background: none;
    }

    .solid-btn,
    .solid-btn:hover {
      border: 2px solid #fff;
      color: black !important;
      background: #fff;
    }
  }
  &.light {
    background: #fafafa !important;
    border: none !important;
    border-top: 1px solid #ddd !important;
    font-weight: 500 !important;
    a,
    .ftLinks,
    label {
      color: #767676;
      font-weight: 500 !important;
    }

    a:not(.btn) {
      color: rgba(0, 0, 0, 0.5) !important;

      &:hover {
        color: rgba(0, 0, 0, 1) !important;
      }
    }

    .hollow-btn,
    .hollow-btn:hover {
      border: 2px solid #000;
      color: black;
      background: none;
    }

    .solid-btn,
    .solid-btn:hover {
      border: 2px solid #000;
      color: #fff;
      background: #000;
    }
  }

  .solid-btn,
  .solid-btn:hover {
    border: 2px solid #000;
    color: #fff;
    background: #000;
  }

  .hollow-btn,
  .hollow-btn:hover {
    border: 2px solid #000;
    color: #000;
    background: transparent;
  }

  a:not(.btn) {
    color: rgba(0, 0, 0, 0.5) !important;

    &:hover {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  label {
    letter-spacing: 0.02em;
    text-transform: none;
    font-family: $font-primary;
    font-weight: normal;
    color: white;
    font-size: 0.825em;
  }

  a {
    font-size: 0.875rem;
    color: #78828d;
  }
}

/********************** ToolTip **************************/

.popover-content {
  padding: 0px;
  width: 200px;
}

.bs-tether-element {
  font-family: $font-primary !important;
  font-weight: 500;
  border: none;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
  padding: 0px;

  .app-actions {
    a {
      padding: 0.5em 1em;
      line-height: 2.5em;
      text-align: left;
      border-bottom: 1px solid #f0f0f0;
      -webkit-transition: 0.233s ease;
      transition: 0.233s ease;

      &:hover {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.popover-wrapper {
  display: inline-block;

  .s-popover {
    background: #fff;
    border: 1px solid #ddd;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.28);
    padding: 20px;
    position: absolute;
    visibility: hidden;
    width: 400px !important;
    top: 20%;
  }

  &:hover {
    .popover {
      visibility: visible;
      opacity: 1;
    }
  }
}

/************************* Tab Nav  ************************/

.settings {
  &:hover {
    .menu {
      visibility: visible;
    }
  }
  .menu {
    width: 250px;
    padding: 20px;
    background: white;
    -webkit-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px -3px rgba(0, 0, 0, 0.75);
    position: absolute;
    right: 30px;
    margin-top: 50px;
    border-radius: 5px;
    visibility: hidden;

    i {
      float: left;
      font-size: 1rem;
      line-height: 2em;
      margin-right: 1em;
      color: #cccccc;
    }

    p {
      font-weight: 500;
      line-height: 2em;
    }
    a:hover {
      color: #636c72 !important;
    }
  }
}
