.socialProofPopup {
  z-index: 10000;
  position: fixed;
  bottom: -50px;
  left: 20px;
  border: 1px solid black;
  -webkit-transition: 1s;
  -moz-transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  -ms-transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  background: #fff;
  border: 0;
  overflow: hidden;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  min-width: 300px;
  opacity: 0;
  font-family: 'Karla', sans-serif !important;

  .catalog-img {
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    width: 100px;
    height: 100px;
  }
  .catalog-info {
    height: 100px;
    padding: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin: 0;
      font-size: 12px;
      color: #888 !important;

      .strong {
        font-family: inherit;
        font-size: inherit;
        font-weight: 600;
        color: darken(#888, 20%) !important;
      }
    }

    p {
      font-family: 'Karla', sans-serif !important;
      font-weight: 18px !important;
      font-weight: 600;
    }
    small {
      font-size: 12px;
      color: #888;
      font-weight: 500;
    }
  }

  &.opening {
    bottom: 20px;
    opacity: 1;
  }
}
