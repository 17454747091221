#Bootstrapped {
  .container {
    width: 100%;
    max-width: 1200px !important;
  }

  //text align
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }

  //margins
  .mt-0 {
    margin-top: 0px !important;
  }
  .mt-1 {
    margin-top: 8px !important;
  }
  .mt-2 {
    margin-top: 16px !important;
  }
  .mt-3 {
    margin-top: 24px !important;
  }
  .mt-4 {
    margin-top: 32px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }
  .mb-1 {
    margin-bottom: 8px !important;
  }
  .mb-2 {
    margin-bottom: 16px !important;
  }
  .mb-3 {
    margin-bottom: 24px !important;
  }
  .mb-4 {
    margin-bottom: 32px !important;
  }

  .ml-0 {
    margin-left: 0px !important;
  }
  .ml-1 {
    margin-left: 8px !important;
  }
  .ml-2 {
    margin-left: 16px !important;
  }
  .ml-3 {
    margin-left: 24px !important;
  }
  .ml-4 {
    margin-left: 32px !important;
  }

  .mr-0 {
    margin-right: 0px !important;
  }
  .mr-1 {
    margin-right: 8px !important;
  }
  .mr-2 {
    margin-right: 16px !important;
  }
  .mr-3 {
    margin-right: 24px !important;
  }
  .mr-4 {
    margin-right: 32px !important;
  }

  //padding
  .py-1 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .py-2 {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .py-3 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
  .py-4 {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }
  .py-5 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  //SM devices
  @media (min-width: 576px) {
    .text-sm-center {
      text-align: center !important;
    }
    .text-sm-left {
      text-align: left !important;
    }
  }

  //MD devices
  @media (min-width: 768px) {
    .text-md-center {
      text-align: center !important;
    }
    .text-md-left {
      text-align: left !important;
    }

    //margin top
    .mt-md-0 {
      margin-top: 0px !important;
    }
    .mt-md-1 {
      margin-top: 8px !important;
    }
    .mt-md-2 {
      margin-top: 16px !important;
    }
    .mt-md-3 {
      margin-top: 24px !important;
    }

    //margin left
    .ml-md-0 {
      margin-left: 0px !important;
    }
    .ml-md-1 {
      margin-left: 8px !important;
    }
    .ml-md-2 {
      margin-left: 16px !important;
    }
    .ml-md-3 {
      margin-left: 24px !important;
    }

    //margin right
    .mr-md-0 {
      margin-right: 0px !important;
    }
    .mr-md-1 {
      margin-right: 8px !important;
    }
    .mr-md-2 {
      margin-right: 16px !important;
    }
    .mr-md-3 {
      margin-right: 24px !important;
    }

    //margin bottom
    .mb-md-0 {
      margin-bottom: 0px !important;
    }
    .mb-md-1 {
      margin-bottom: 8px !important;
    }
    .mb-md-2 {
      margin-bottom: 16px !important;
    }
    .mb-md-3 {
      margin-bottom: 24px !important;
    }
  }

  //LG devices
  @media (min-width: 992px) {
    .text-lg-center {
      text-align: center !important;
    }
    .text-lg-left {
      text-align: left !important;
    }

    //margin top
    .mt-lg-0 {
      margin-top: 0px !important;
    }
    .mt-lg-1 {
      margin-top: 8px !important;
    }
    .mt-lg-2 {
      margin-top: 16px !important;
    }
    .mt-lg-3 {
      margin-top: 24px !important;
    }

    //margin left
    .ml-lg-0 {
      margin-left: 0px !important;
    }
    .ml-lg-1 {
      margin-left: 8px !important;
    }
    .ml-lg-2 {
      margin-left: 16px !important;
    }
    .ml-lg-3 {
      margin-left: 24px !important;
    }

    //margin right
    .mr-lg-0 {
      margin-right: 0px !important;
    }
    .mr-lg-1 {
      margin-right: 8px !important;
    }
    .mr-lg-2 {
      margin-right: 16px !important;
    }
    .mr-lg-3 {
      margin-right: 24px !important;
    }

    //margin bottom
    .mb-lg-0 {
      margin-bottom: 0px !important;
    }
    .mb-lg-1 {
      margin-bottom: 8px !important;
    }
    .mb-lg-2 {
      margin-bottom: 16px !important;
    }
    .mb-lg-3 {
      margin-bottom: 24px !important;
    }
  }
}
