/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #23d192;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
$dark-blue: #394b64;
$dark-gray: #646b73;
$pop: #6772e5;
$border-color: #e6ebf1;

$s-green: #24b47e;
$s-purple: #6772e5;
$s-blue: #3297d3;
$s-yellow: #e39f48;
$s-red: #d9453d;
/*** Button Styles ***/
$btn-primary: $light-blue;
$btn-secondary: $light-blue;

.card-shadow {
  box-shadow:
    0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.persona {
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
  }
  .clip-circle {
    clip-path: circle(25px at center);
  }

  &.sm {
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      object-position: center;
    }
    .clip-circle {
      clip-path: circle(15px at center);
    }
  }
}

.circle-menu-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #fff;
  border: 1px solid black;
  transition: all 200ms ease;

  .feather.feather-arrow-left {
    color: black !important;
  }

  &.sm {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    color: black;

    &:hover {
      color: white !important;
    }
  }

  &:hover {
    background: #222;
    i {
      color: white !important;
    }

    .feather.feather-arrow-left {
      color: white !important;
    }
  }
}

.material-button {
  background: #1a6bc6;
  border-radius: 50px;
  padding: 16px 100px;
  font-size: 18px;
  font-weight: 600;
}

.link-blue {
  color: #007bff !important;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.table-dark > td {
  background-color: #86888b;
}

body {
  font-weight: 500;
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border: transparent;
  box-shadow: none !important;
}

.float-right {
  float: right !important;
}
.show .dropdown-menu {
  display: block;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #eee;
}

.pointer {
  cursor: pointer;
}
@media (max-width: 575px) {
  #livechat-compact-container {
    display: none;
  }
}

.main {
  padding-top: 54px;
  min-height: 100vh;
  @media (min-width: 576px) {
    padding-top: 64.16px;
  }
}

.card {
  margin-bottom: 1rem;
}

.btn-circle {
  background: #d51b40;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  text-align: center;
  color: white;

  &.hvr-ripple-out {
    &:before {
      content: '';
      position: absolute;
      border: #d51b40 solid 6px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      border-radius: 50% !important;
    }
  }

  &.white {
    display: inline-block;
    background: #161718;
    color: white;

    &.dropdown-toggle::after {
      display: none;
    }

    &.hvr-ripple-out {
      &:before {
        content: '';
        position: absolute;
        border: #161718 solid 5px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        border-radius: 50% !important;
      }

      &::after {
        content: '';
        position: absolute;
        border: rgba(255, 255, 255, 0) solid 1px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        border-radius: 50% !important;
      }
    }
  }
}

.ReactModalPortal
  .ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right
  .slide-pane__content {
  padding: 0;
  background: #fff !important;
  height: 100vh;
  overflow: none;
}
