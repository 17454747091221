$font: 'Myriad', 'Myriad', sans-serif;
$bg: #f7fafc;
$bold: #2e3b48;
$muted: #9499a3;
$border: #e3e8ee;
$c-black: #2e3c49;
$c-green: #40d78f;
$c-gray: #dfe2e8;
$c-red: #e90f09;
$c-yellow: #fcd240;
$c-pink: #e84f89;
$c-orange: #fd9926;
$c-purple: #8e69ec;
$c-teal: #34cbdb;
$c-blue: #2453d5;
$c-darkBlue: #021635;
$c-royalBlue: #0f183e;
$c-lightBlue: #5195ff;

.newTable-rating-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  background: white;
  display: flex;
  flex-direction: row;
  box-shadow:
    0 0 0 1px rgba(50, 50, 93, 0.05),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1px 0 rgba(0, 0, 0, 0.07);
  border-top: 1px solid $border;

  h1,
  label {
    font-family: $font !important;
    margin: 0px !important;
  }

  h1 {
    font-weight: 500;
  }

  label {
    color: $muted !important;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05ch;
    font-size: 12px;
  }

  .nt-graph {
    .nt-graph-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0em 1em;

      .text-black {
        color: $bold !important;
      }
      .nt-graph-line-container {
        background: $border;
        border-radius: 10px;
        width: 500px;
        margin-left: 16px;
        margin-right: 16px;

        .nt-graph-line {
          height: 6px;
          border-radius: 4px;
          background: $c-lightBlue;
        }
      }
    }
  }
  .nt-rating-item {
    padding: 16px;
    padding-right: 64px;
    border-right: 1px solid $border;
    text-align: left;
  }
}
