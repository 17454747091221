.task {
  background: #fff;
  padding: 1em 0em;

  &.head {
    .date {
      margin-right: 2em;
      text-align: center;
      line-height: 2em;

      .month {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 500;
      }

      .day {
        font-size: 1.3em;
        font-weight: 100;
      }
    }

    .details {
      margin-top: 0.6em;
      vertical-align: middle;

      p {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}

.e-jobs {
  text-align: center;
  background: white;

  .wrapper {
    background: #fff;
    color: black;
    border: 1px solid #f0f0f0;
    padding: 3em;
    height: 300px;
    border-radius: 0.3em;
  }

  h4 {
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.05em;
    font-size: 1.4em;
  }

  p {
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    font-weight: 300;
    letter-spacing: 0.02em;
    max-width: 400px;
    margin: 0px auto;
  }

  img {
    height: 150px;
  }
  .btn-book {
    background: white;
    color: black;
    font-family: 'Lato', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-top: 2em;
    border: none;
    padding: 1em 5em;
  }
}

.tab-payment {
  ul {
    list-style: none;
    li {
      line-height: 4em;
      border-bottom: 1px solid #f0f0f0;
      font-family: 'Lato', sans-serif;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0.05em;
      margin-top: -1px;
    }
  }

  .cardbrand {
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 2em;
  }
}

/************ EMPTY STATE FOR JOBS ***************/
.tutorial {
  text-align: center;
  img {
    display: block;
    margin: 0px auto;
    margin-bottom: 2em;
  }
  p {
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.02rem;
    font-weight: 300;
  }
}
.add-btn-round {
  cursor: pointer;
  &.right {
    float: right !important;
    position: absolute;
    right: 0;
    top: 0;
    margin: 20px;
  }

  &.center {
    margin: 0px auto;
  }
  .add-icon {
    width: 50px;
    height: 50px;
    line-height: 55px;
    background: linear-gradient(45deg, #1c1c1c, #383838);
    color: #fff;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    display: block;
    margin: 0px auto;
    margin-bottom: 1em;
  }
}

.add-btn-sq {
  cursor: pointer;
  background: linear-gradient(45deg, #1c1c1c, #383838);
  color: #fff;
  padding: 1em;
  border: none;
  margin: 0px auto;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.2rem;
  font-size: 0.9rem;
  font-weight: 500;
  max-width: 500px;
}

/*********** Payment Style ***********/

.placeholder-card {
  background: #f0f0f0;
  padding: 2em;
  font-weight: 500;
}
