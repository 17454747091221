.lastLogin:hover .green-icon {
  display: none;
}
.lastLogin:hover .time {
  display: block;
}
.lastLogin .time {
  display: none;
}

.map-popover {
  font-size: 16px;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
  z-index: 99 !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 {
  width: 100% !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 p,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 h1,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 h2,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 h3,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 h4,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 h5,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 label,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 em,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 b,
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 strong {
  font-family: "Myriad", sans-serif !important;
  margin: 0;
  font-weight: 500;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .modal-body {
  background: white;
  padding: 0;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-container {
  width: 100%;
  max-width: 95%;
  margin: 0px auto;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 15px;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head .pp-container {
  justify-content: space-between;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head .pp-head--title {
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #333;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head .pp-head--btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #fafafa;
  transition: all 200ms ease;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head .pp-head--btn:hover {
  background: #222;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-head .pp-head--btn:hover .fa-map-marker {
  color: white !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body {
  padding: 1em 15px;
  border-bottom: none;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list li {
  margin-bottom: 0.2em;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .pp-job-item {
  width: 100%;
  display: block;
  border-radius: 4px;
  padding: 1em 2em;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .hover-info {
  position: relative;
  overflow: visible;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .hover-info:hover::after {
  content: "" !important;
  background: none !important;
  display: none !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-primary {
  background: #0882ff !important;
  border: none !important;
  color: white !important;
  transition: all 200ms ease;
  font-weight: 600;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-primary:hover {
  background: #0076ee !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-danger {
  background: #d23544 !important;
  transition: all 200ms ease;
  border: none !important;
  font-weight: 600;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-danger:hover {
  background: #b30000 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-success {
  background: #1dc09b !important;
  transition: all 200ms ease;
  border: none !important;
  font-weight: 600;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-success:hover {
  background: #1aaa89 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-white {
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #ddd !important;
  font-weight: 500;
  transition: all 200ms ease;
  color: #3c577b !important;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-body .pp-job-list .bg-white:hover {
  color: black;
  background: #f1f3f8 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-btn {
  width: 100%;
  border-radius: 4px;
  padding: 1em 1em;
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  outline: none;
  border: none;
  cursor: pointer;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-btn.primary {
  background: #3b9cff;
  color: white;
}
.slide-pane__overlay.ReactModal__Overlay--after-open .pp-modal-2 .pp-btn:hover {
  background: #0882ff;
}

.schedule .schedule-slot {
  font-size: 18px;
}
.schedule .schedule-slot.visitCancelled {
  background-color: grey !important;
}
.schedule .bg-purple {
  background-color: #8e44ad !important;
  border: 1px solid #8e44ad !important;
}
.schedule .dark-nav {
  background: #222;
  padding: 1em;
}
.schedule .version2 {
  position: relative;
  height: 100vh;
  display: flex;
  flex-flow: column;
}
.schedule .version2 .Select-menu-outer {
  z-index: 999999999;
}
.schedule .version2 .map-title h2 {
  font-family: "Myriad", sans-serif !important;
  font-weight: 400 !important;
  color: white;
  margin: 0;
}
.schedule .version2 .map-title h2 strong {
  font-weight: 500 !important;
}
.schedule .version2 .map-title h4 {
  font-size: 18px !important;
  font-family: "Myriad", sans-serif !important;
  font-weight: 400 !important;
  color: #eee;
  margin: 0;
}
.schedule .version2 .badge-default {
  background: #d23544;
  font-weight: 600;
  font-size: 16px;
  font-family: "Myriad", sans-serif;
  padding: 0.2em 0.5em;
  padding-top: 0.4em;
  border-radius: 4px;
  color: white;
}
.schedule .version2 .map-popover b,
.schedule .version2 .map-popover p,
.schedule .version2 .map-popover label {
  line-height: 1.5em;
  font-family: "Myriad", sans-serif;
  display: block;
  margin: 0;
}
.schedule .version2 .map-popover b {
  font-weight: 600;
}
.schedule .version2 .map-popover label {
  color: #3c577b !important;
  font-weight: 500;
}
.schedule .version2 .map-legend {
  position: absolute;
  top: 0px;
  margin-top: 66px;
  left: 1rem;
  padding: 1em;
  padding-left: 0;
  border-radius: 5px;
  border: 1px solid #dbdde1;
  width: 200px;
  background: white;
  font-family: "Myriad", sans-serif !important;
  font-weight: 600;
  color: #3c577b;
}
.schedule .version2 .map-legend .map-legend-icon {
  width: 40px;
}
.schedule .version2 .header-left {
  overflow-x: auto;
  white-space: nowrap;
}
.schedule .version2 #map {
  height: 100%;
}
.schedule .version2 .rct-header {
  height: 100px !important;
}
.schedule .version2 .rct-header .top-header {
  height: 50px !important;
  background: #0275d8;
}
.schedule .version2 .rct-header .top-header .rct-label-group {
  border-right: 1px solid rgba(255, 255, 255, 0.25) !important;
}
.schedule .version2 .rct-header .top-header .rct-label-group:nth-child(even) {
  background: rgba(0, 0, 0, 0.2) !important;
}
.schedule .version2 .rct-header .bottom-header {
  height: 50px !important;
  background: #f2f2f2;
}
.schedule .version2 .bg-primary {
  background: #3b9cff !important;
  border: none !important;
  color: white !important;
  transition: all 200ms ease;
  font-weight: 600;
}
.schedule .version2 .bg-primary:hover {
  background: #228fff !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.schedule .version2 .bg-primary.hover-info:hover::after {
  background: linear-gradient(to right, rgba(59, 156, 255, 0) 0%, #3b9cff 50%, #3b9cff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.schedule .version2 .bg-danger {
  background: #d23544 !important;
  transition: all 200ms ease;
  border: none !important;
  font-weight: 600;
}
.schedule .version2 .bg-danger:hover {
  background: #b30000 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.schedule .version2 .bg-danger.hover-info:hover::after {
  background: linear-gradient(to right, rgba(210, 53, 68, 0) 0%, #d23544 50%, #d23544 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.schedule .version2 .bg-success {
  background: #1dc09b !important;
  transition: all 200ms ease;
  border: none !important;
  font-weight: 600;
}
.schedule .version2 .bg-success:hover {
  background: #1aaa89 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.schedule .version2 .bg-success.hover-info:hover::after {
  background: linear-gradient(to right, rgba(29, 192, 155, 0) 0%, #1dc09b 50%, #1dc09b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.schedule .version2 .bg-white {
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #ddd !important;
  font-weight: 500;
  transition: all 200ms ease;
  color: #3c577b !important;
}
.schedule .version2 .bg-white:hover {
  color: black;
  background: #f1f3f8 !important;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.schedule .version2 .bg-white.hover-info:hover::after {
  color: black;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.schedule .version2 .custom-group {
  position: relative;
  cursor: pointer;
}
.schedule .version2 .custom-group:hover .icon-wrapper, .schedule .version2 .custom-group.active .icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.schedule .version2 .custom-group.active .icon-wrapper {
  opacity: 1;
}
.schedule .version2 .custom-group .icon-wrapper {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: none;
  font-size: 20px;
  opacity: 0.5;
}
.schedule .version2 .top-header {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  padding: 0em;
  border-bottom: 1px solid #dbdde1;
}
.schedule .version2 .top-header h2 {
  font-family: "Myriad", sans-serif !important;
  font-weight: 400 !important;
  color: black;
  margin: 0;
}
.schedule .version2 .top-header h2 strong {
  font-weight: 500 !important;
}
.schedule .version2 .top-header h4 {
  font-size: 18px !important;
  font-family: "Myriad", sans-serif !important;
  font-weight: 400 !important;
  color: #3c577b !important;
  margin: 0;
}
.schedule .version2 .top-header .container {
  max-width: 1440px;
}
.schedule .version2 .top-header .header-1 {
  padding: 1em 0em;
  padding-top: 2em;
}
.schedule .version2 .top-header .header-2 {
  font-family: "Myriad", sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.schedule .version2 .top-header .header-2 .header-col {
  text-align: left;
  padding: 1em 0.5em;
}
.schedule .version2 .top-header .header-2 .header-col.black {
  border-bottom: 2px solid black;
}
.schedule .version2 .top-header .header-2 .header-col.purple {
  border-bottom: 2px solid #8e44ad;
}
.schedule .version2 .top-header .header-2 .header-col.blue {
  border-bottom: 2px solid #3b9cff;
}
.schedule .version2 .top-header .header-2 .header-col.red {
  border-bottom: 2px solid #d23544;
}
.schedule .version2 .top-header .header-2 .header-col.green {
  border-bottom: 2px solid #1dc09b;
}
.schedule .version2 .top-header .header-2 .header-col.cancelled {
  border-bottom: 2px solid #dbdde1;
}
.schedule .version2 .top-header .header-2 .header-col.orange {
  border-bottom: 2px solid #f4982c;
}
.schedule .version2 .top-header .header-2 .header-col.info {
  border-bottom: 2px solid #17a2b8;
}
.schedule .version2 .top-header .header-2 .header-col p {
  font-family: "Myriad", sans-serif !important;
  font-weight: 600 !important;
  color: #3c577b;
  display: inline-block;
  margin-left: 0.5em;
}
.schedule .version2 .top-header .header-2 .header-col .hcircle-icon {
  width: 15px !important;
  height: 15px !important;
  display: inline-block;
  border-radius: 50%;
}
.schedule .version2 .header {
  background: white;
  min-height: 57px;
  overflow-y: hidden;
  display: flex;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
.schedule .version2 .header .header-left {
  width: 150px;
}
.schedule .version2 .header .header-right {
  overflow-x: auto;
  display: flex;
}
.schedule .version2 .react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-color: #dbdde1;
}
.schedule .version2 .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  border-left-color: #7fa0c0 !important;
  border-left-width: 1px !important;
}
.schedule .version2 .rct-horizontal-lines {
  border: none !important;
}
.schedule .version2 .rct-hl-even {
  background: #f1f3f6;
  transition: all 200ms ease;
}
.schedule .version2 .rct-hl-even:hover {
  background: #e1e6ec !important;
  transition: all 200ms ease;
}
.schedule .version2 .rct-hl-odd {
  background: #ffffff;
  transition: all 200ms ease;
}
.schedule .version2 .rct-hl-odd:hover {
  background: #fafafa !important;
  transition: all 200ms ease;
}
.schedule .version2 .react-calendar-timeline .rct-sidebar-header {
  background: white;
}
.schedule .version2 .react-calendar-timeline .rct-header .rct-label-group {
  line-height: 54px !important;
  border: none;
  height: 50px !important;
  background: none;
}
.schedule .version2 .react-calendar-timeline .rct-header .rct-label-group span {
  font-family: "Myriad", sans-serif;
  font-weight: 600 !important;
  font-size: 16px;
}
.schedule .version2 .rct-label {
  line-height: 50px !important;
  font-family: "Myriad", sans-serif;
  font-weight: 600 !important;
  color: #3c577b;
  height: 50px !important;
  background: none !important;
  border-color: #dbdde1;
}
.schedule .version2 .rct-item {
  overflow: hidden;
  border-radius: 4px;
  padding-left: 8px;
}
.schedule .version2 .rct-item:hover .title {
  display: none;
}
.schedule .version2 .rct-item:hover .status {
  display: inline;
}
.schedule .version2 .rct-item .status {
  display: none;
  text-transform: uppercase;
}
.schedule .version2 .rct-item .rct-item-content {
  padding: 0 !important;
}
.schedule .version2 .rct-item .rct-item-content .slotTitle {
  color: inherit;
  white-space: nowrap;
  font-family: "Myriad", sans-serif;
  font-weight: 600 !important;
  text-transform: inherit !important;
  font-size: 16px;
  text-transform: uppercase;
}
.schedule .version2 .rct-outer .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid #dbdde1 !important;
}
.schedule .version2 .rct-outer .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  background: #f1f3f6;
}
.schedule .version2 .rct-outer .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: #ffffff;
}
.schedule .version2 .rct-outer .rct-sidebar .rct-sidebar-row .title,
.schedule .version2 .rct-outer .rct-sidebar .rct-sidebar-row .title-text {
  font-family: "Myriad", sans-serif !important;
  font-weight: 600 !important;
  color: #3c577b;
}
.schedule .version2 .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.schedule .version2 .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #dbdde1 !important;
}

.hover-info {
  position: relative;
  overflow: visible;
}
.hover-info:hover::after {
  font-family: "Myriad", sans-serif;
  font-weight: 600;
  color: white;
  padding: 0em 0.5em;
  content: "(Double click to open)";
  text-align: right;
  transition: all 200ms ease;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.85) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.85) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.85) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#a6000000",GradientType=1 );
  /* IE6-9 */
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: visible;
  z-index: 9999;
}

.modal-dialog.edit_route_modal {
  font-size: 16px;
  max-width: 1000px !important;
}

.schedule {
  font-size: 16px;
}
.schedule .btn-secondary.active {
  background-color: #eee !important;
  color: black !important;
}
.schedule .timeline table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
.schedule .timeline td,
.schedule .timeline th {
  white-space: nowrap;
  font-weight: 600;
  font-family: "Myriad", sans-serif !important;
  font-size: "18px";
}
.schedule .timeline td + td,
.schedule .timeline th + th {
  width: auto;
}
.schedule a.pro {
  color: #212529 !important;
}
.schedule th {
  text-align: center;
}
.schedule .dropdown:hover > .dropdown-body {
  display: block;
}
.schedule .dropdown .dropdown-body {
  position: absolute;
  top: 0;
  display: none;
  background: white;
  z-index: 999999999;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  transition: all 200ms ease;
}
.schedule .dropdown .dropdown-body > * {
  color: #212529;
  display: block;
  padding: 8px;
}
.schedule .dropdown .dropdown-body > *:hover {
  background: #fafafa;
}
.schedule .cell {
  white-space: nowrap;
  overflow-x: hidden;
  display: block;
  width: 100%;
}
.schedule .cell a:hover {
  -webkit-box-shadow: 0px 7px 19px -3px #49494f;
  -moz-box-shadow: 0px 7px 19px -3px #49494f;
  box-shadow: 0px 7px 19px -3px #49494f;
}
.schedule .cell p {
  font-family: "Myriad", sans-serif !important;
  font-size: 18px;
}
.schedule .cell a {
  color: white;
  font-weight: 600;
  display: block;
  margin-bottom: 4px;
  border-radius: 4px;
  font-family: "Myriad", sans-serif !important;
}
.schedule .cell a .title,
.schedule .cell a .title-text {
  font-family: "Myriad", sans-serif !important;
}
.schedule .cell a .map-button {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0px 5px;
  margin-right: 5px;
  text-decoration: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  transition: all 200ms ease;
}
.schedule .cell a .map-button:hover {
  opacity: 0.5;
}
.schedule .cell a .status {
  display: none;
  font-family: "Myriad", sans-serif !important;
}
.schedule .cell a:hover .status {
  display: inline;
}
.schedule .cell a:hover .title {
  display: none;
}
.schedule .cell a.cancelled {
  color: #212529;
  border-color: #e4e4e4;
}
.schedule .cell a .fa-star {
  color: white;
}
.schedule .cell .abandoned {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  background: white;
}
.schedule .cell .abandoned:hover {
  background: #fafafa;
  color: #3b9cff;
}
.schedule .cell .cancelled {
  background: white;
}
.schedule .cell .cancelled:hover {
  background: #fafafa;
  color: #3b9cff;
}
.schedule .cell .cancelled .fa-star {
  color: #6e849a !important;
}
.schedule .cell .cancelled .map-button {
  border: 1px solid #999999;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  background-color: #ffffff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#cfcfcf));
  background-image: -webkit-linear-gradient(top, #ffffff, #cfcfcf);
  background-image: -moz-linear-gradient(top, #ffffff, #cfcfcf);
  background-image: -ms-linear-gradient(top, #ffffff, #cfcfcf);
  background-image: -o-linear-gradient(top, #ffffff, #cfcfcf);
  background-image: linear-gradient(to bottom, #ffffff, #cfcfcf);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#FFFFFF, endColorstr=#CFCFCF);
}
.schedule .cell .cancelled .map-button i {
  color: #000;
}
.schedule .cell .claimed {
  background-color: #3b9cff;
  border-color: #228fff;
}
.schedule .cell .claimed:hover {
  background: #0069d4;
}
.schedule .cell .claimed .fa-map-marker {
  color: #3b9cff;
}
.schedule .cell .visitCancelled .fa-map-marker {
  color: grey !important;
}
.schedule .cell .completed {
  background: #1dc09b;
  border-color: #1aaa89;
}
.schedule .cell .completed:hover {
  background: #106753;
}
.schedule .cell .completed .fa-map-marker {
  color: #1dc09b;
}
.schedule .cell .visitCompleted {
  background: #27ae60;
  border-color: #229955;
}
.schedule .cell .visitCompleted:hover {
  background: #145b32;
}
.schedule .cell .visitCompleted .fa-map-marker {
  color: #27ae60;
}
.schedule .cell .inProgress {
  background: #8e44ad;
  border-color: #7f3d9b;
}
.schedule .cell .inProgress:hover {
  background: #522764;
}
.schedule .cell .inProgress .fa-map-marker {
  color: #8e44ad;
}
.schedule .cell .pending,
.schedule .cell .suggested {
  background: #e67e22;
  border-color: #d67118;
}
.schedule .cell .pending:hover,
.schedule .cell .suggested:hover {
  background: #924d10;
}
.schedule .cell .pending .fa-map-marker,
.schedule .cell .suggested .fa-map-marker {
  color: #e67e22;
}
.schedule .cell .pending.preferred,
.schedule .cell .suggested.preferred {
  background: #d23544;
  border-color: #c22b3a;
}
.schedule .cell .pending.preferred .fa-map-marker,
.schedule .cell .suggested.preferred .fa-map-marker {
  color: #d23544;
}
.schedule .cell .pending.preferred:hover,
.schedule .cell .suggested.preferred:hover {
  background: #841d27;
}
.schedule .cell .waitingOnSchedule {
  color: white;
  background: #ee6a94;
  border-color: #eb5384;
}
.schedule .cell .waitingOnSchedule:hover {
  background: #d91956;
}
.schedule .cell .waitingOnSchedule .fa-map-marker {
  color: #ee6a94;
}
.schedule .cell .recall {
  background: #ffc107;
  border-color: #edb100;
}
.schedule .cell .recall:hover {
  background: #a07800;
}
.schedule .cell .recall .fa-map-marker {
  color: #ffc107;
}

.tool-popper {
  opacity: 1 !important;
  margin-top: -5px;
}
.tool-popper .tool-tip {
  background: white !important;
  opacity: 1 !important;
  color: black;
  -webkit-box-shadow: 0px 7px 19px -3px #49494f;
  -moz-box-shadow: 0px 7px 19px -3px #49494f;
  box-shadow: 0px 7px 19px -3px #49494f;
  padding: 16px;
}
.tool-popper .tool-tip h2 {
  font-family: "Myriad", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  margin: 0px !important;
}
.tool-popper .tool-tip p,
.tool-popper .tool-tip .text-blue {
  font-family: "Myriad", sans-serif !important;
  font-weight: 600;
  font-size: 16px;
}
.tool-popper .tool-tip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  left: 50%;
  top: -10px;
  transform: translateX(-50%);
}
