/* FOR THE APPLIANCE TECH PAGE*/
#JobPost {
  width: 100vw;
  overflow-x: hidden;
}
#JobPost .container {
  width: 100%;
  max-width: 1200px;
}
#JobPost .bold-color {
  color: #051033 !important;
}
#JobPost .MuiFormControl-root-30 {
  background: white !important;
}
#JobPost .form-group,
#JobPost .MuiFormControl-marginNormal-31 {
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}
#JobPost.academy2019.fixed {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}
#JobPost.academy2019 .text-blue {
  color: #1558a3 !important;
}
#JobPost.academy2019 .text-blue.link {
  transition: all 200ms ease;
}
#JobPost.academy2019 .text-blue.link:hover {
  color: #124c8c !important;
}
#JobPost.academy2019 p,
#JobPost.academy2019 h1,
#JobPost.academy2019 h2,
#JobPost.academy2019 h3,
#JobPost.academy2019 h4,
#JobPost.academy2019 h5,
#JobPost.academy2019 h6,
#JobPost.academy2019 label,
#JobPost.academy2019 span,
#JobPost.academy2019 small,
#JobPost.academy2019 button,
#JobPost.academy2019 a,
#JobPost.academy2019 b {
  font-family: "Myriad", sans-serif !important;
  text-align: inherit;
}
#JobPost.academy2019 btn {
  font-family: "Myriad", sans-serif !important;
  font-weight: 700;
  background: #1558a3;
}
#JobPost.academy2019 btn:hover {
  background: #124c8c;
}
#JobPost.academy2019 btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#JobPost.academy2019 btn.outline {
  border-color: #e4e4e4;
  padding: 0.5em;
  color: #1558a3 !important;
}
#JobPost.academy2019 btn.outline:hover {
  background: white;
  border-color: #cbcbcb;
  color: #124c8c !important;
}
#JobPost.academy2019 .academy-jumbo {
  background: url("../../../img/teaching-2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}
#JobPost.academy2019 .academy-quote {
  background: url("../../../img/bg-academy-9.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
}
@media (max-width: 544px) {
  #JobPost.academy2019 .academy-quote {
    background: url("../../../img/bg-academy-10.jpg");
    background-size: cover;
    background-position: center right;
    height: auto !important;
    padding-bottom: 0px;
  }
}
#JobPost.academy2019 .academy-conclusion .container {
  background: url("../../../img/academy-footer.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 20vh 16px;
}
#JobPost.academy2019 .academy-video-body {
  height: 250px;
  width: 444.4444444444px;
  border-radius: 5px;
  background: url("../../../img/academy-video.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
#JobPost .highlight {
  color: #2a3f58;
  background: #b7e3e9;
  box-shadow: 5px 0 0 #b7e3e9, -5px 0 0 #b7e3e9;
  padding: 5px 0;
}
#JobPost p,
#JobPost h1,
#JobPost h2,
#JobPost h3,
#JobPost h4,
#JobPost h5,
#JobPost h6,
#JobPost label,
#JobPost span,
#JobPost small,
#JobPost button,
#JobPost a,
#JobPost b {
  font-family: "Myriad", sans-serif;
  color: #2a3f58;
  margin: 0px;
  line-height: 1.4em;
  text-rendering: optimizeLegibility;
}
#JobPost h1 {
  font-size: 3.052em;
  font-weight: 500;
}
@media (max-width: 540px) {
  #JobPost h1 {
    font-size: 34.84px;
  }
}
#JobPost h2 {
  font-size: 2.441em;
  font-weight: 400 !important;
}
@media (max-width: 540px) {
  #JobPost h2 {
    font-size: 1.7em;
    font-weight: 500 !important;
  }
}
@media (max-width: 540px) {
  #JobPost h2 {
    font-size: 1.7em;
    font-weight: 500 !important;
  }
}
@media (max-width: 375px) {
  #JobPost h2 {
    font-size: 1.5em;
    font-weight: 500 !important;
  }
}
#JobPost h3 {
  font-size: 1.953em;
}
@media (max-width: 540px) {
  #JobPost h3 {
    font-size: 24.19px;
  }
}
#JobPost h4 {
  font-size: 1.763em;
  font-weight: 500 !important;
}
@media (max-width: 540px) {
  #JobPost h4 {
    font-size: 20.16px;
  }
}
#JobPost h5 {
  font-size: 1.25em;
}
@media (max-width: 540px) {
  #JobPost h5 {
    font-size: 18px;
  }
}
#JobPost p {
  font-size: 18px;
  color: #7f8c9d;
}
#JobPost small {
  font-size: 0.64em;
}
#JobPost a {
  color: #176de9;
  font-weight: 600;
  transition: all 200ms ease;
  cursor: pointer;
}
#JobPost a:hover {
  color: #104ca4;
}
#JobPost btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  background: #176de9;
  padding: 0.8em 1.5em;
  border-radius: 4px;
  color: white;
  transition: all 200ms ease;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
  font-family: "Myriad", sans-serif;
  font-weight: 500;
}
@media (max-width: 767px) {
  #JobPost btn {
    width: 100%;
  }
}
@media (max-width: 540px) {
  #JobPost btn {
    font-size: 16px;
    font-weight: 600;
  }
}
#JobPost btn:hover {
  background: #1462d3;
}
#JobPost btn.outline {
  background: none;
  color: #176de9;
  border: 1.5px solid #176de9;
  min-width: auto;
}
#JobPost btn.outline:hover {
  background: white;
  color: #1257bb;
  border-color: #1257bb;
}
#JobPost btn.white {
  background: white;
  color: #176de9;
  min-width: auto;
}
#JobPost btn.white:hover {
  color: #1257bb;
}
#JobPost .form-container {
  background-color: #fafafa;
  padding: 1em;
  width: 100%;
}
#JobPost .form-container .form-group {
  width: 100%;
  background: white;
}
#JobPost .form-container .form-group label {
  font-family: "Myriad", sans-serif !important;
  font-weight: 700;
  font-size: 16px;
  color: #99a8bb !important;
  padding: 0.5em;
  top: 0em !important;
}
#JobPost .form-container .form-group label[data-shrink=false] {
  margin-bottom: 1em !important;
  top: -1.1em !important;
}
#JobPost .form-container .MuiFormControl-marginNormal-2 {
  margin: 0px;
  border: 1px solid #e4e4e4;
  border-bottom-width: 0px;
}
#JobPost .form-container .MuiInput-underline-27:before {
  border-bottom-color: #e4e4e4 !important;
}
#JobPost .form-container .MuiInput-underline-27:after {
  border-color: #1558a3 !important;
}
#JobPost .form-container input {
  font-family: "Myriad", sans-serif !important;
  font-weight: 700;
  color: #30435c !important;
  width: 100% !important;
  display: block;
  padding: 0.5em 0.5em;
}

@media (max-width: 500px) {
  .press-row img {
    height: 35px;
  }
}

.pro-app-container {
  background-color: #013a6b;
  background-image: -webkit-linear-gradient(101deg, #fff 35%, #176de9 35%);
  min-height: 200px;
}

.iphone-container {
  height: 600px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iphone-container .iphone {
  height: 100%;
  position: absolute;
  z-index: 10;
}
.iphone-container .iphone img {
  height: 100%;
  object-fit: contain;
}
.iphone-container .screen-container {
  padding: 0.5em;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.iphone-container .screen-container .screen {
  margin-top: 8px;
  margin-left: 0px;
  height: 98%;
  width: 100%;
  background: url("../../../img/lightning/route.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.join-home .join-jumbotron {
  padding: 10em 1em;
  width: 100%;
  border-radius: 5px;
  background: url("../../../img/happy-family.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.join-home .job_card_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px !important;
  overflow: hidden;
  border: 1px solid #e3e8ee;
  box-shadow: 0px 8px 28px rgba(8, 24, 76, 0.04), 0px 0px 8px rgba(0, 13, 52, 0.02);
}
.join-home .job_card_container .job_card_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: center;
  cursor: pointer;
  transition: all 200ms ease;
  border-right: 1px solid #e3e8ee;
}
@media (max-width: 768px) {
  .join-home .job_card_container .job_card_item {
    border-right: none;
    border-bottom: 1px solid #e3e8ee;
    justify-content: flex-start;
  }
}
.join-home .job_card_container .job_card_item:hover {
  transition: all 200ms ease;
}
.join-home .job_card_container .job_card_item:hover .job_card_text {
  color: #176de9 !important;
}
.join-home .job_card_container .job_card_item .job_card_image {
  margin-right: 16px;
}
.join-home .job_card_container .job_card_item .job_card_image img {
  height: 25px;
}
.join-home .job_card_container .job_card_item .job_card_text {
  font-family: "Myriad", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #051033;
  transition: all 200ms ease;
}
