#Announcement .accordian-group .accordian-card {
  border-radius: 4px;
  background: #fafafa;
  margin-bottom: 16px;
  transition: all 0.3s ease-in-out;
}
#Announcement .accordian-group .accordian-card.opened, #Announcement .accordian-group .accordian-card:hover {
  background: white;
  box-shadow: 0 16px 86px rgba(0, 0, 0, 0.2);
}
#Announcement .accordian-group .accordian-card .accordian-header {
  padding: 24px 16px;
  cursor: pointer;
}
#Announcement .accordian-group .accordian-card .accordian-header i {
  color: #1558a3;
}
#Announcement .accordian-group .accordian-card .accordian-body {
  border-top: 1px solid #e4e4e4;
  padding: 16px;
  transform-origin: top;
  -webkit-animation: grow 0.8s ease;
  animation: grow 0.8s ease;
}
#Announcement .accordian-group .accordian-card .accordian-body p {
  font-size: 16px;
  font-weight: 600;
}
@keyframes grow {
  0% {
    opacity: 0;
    height: 0%;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
#Announcement .full-width {
  padding-top: 180px;
}
#Announcement .full-width p,
#Announcement .full-width h1,
#Announcement .full-width h2,
#Announcement .full-width h3,
#Announcement .full-width h4,
#Announcement .full-width h5,
#Announcement .full-width h6,
#Announcement .full-width label,
#Announcement .full-width small,
#Announcement .full-width span,
#Announcement .full-width button,
#Announcement .full-width ul,
#Announcement .full-width li,
#Announcement .full-width .badge {
  font-family: "Myriad", sans-serif;
  font-weight: 500;
  margin: 0;
  color: #000;
}
#Announcement .full-width p {
  font-size: 18px;
  line-height: 1.5em;
}
#Announcement .full-width h1,
#Announcement .full-width h2 {
  font-family: "Myriad", serif;
  font-weight: 600;
  letter-spacing: 0.02ch;
  color: "#0f1726";
}
@media (max-width: 767px) {
  #Announcement .full-width h1 {
    font-size: 32px;
  }
  #Announcement .full-width p {
    font-size: 16px;
  }
}
#Announcement .full-width .text-muted {
  color: #41535a !important;
}
#Announcement .full-width .container {
  background: white;
  max-width: 800px;
}
