// cleaned
$primary: #000000;
$primary-900: lighten($primary, 10%);
$primary-800: lighten($primary, 20%);
$primary-700: lighten($primary, 30%);
$primary-200: lighten($primary, 80%);
$blue: #1469e6;
$blue-sky: #00b8f4;
$green: #3ecf8e;
$red: #fc4236;
$purple: #6772e5;
$purple-darker: darken($purple, 20%);
$text: #777e89;
$text-darker: darken($text, 30%);
$font-primary: 'Myriad', sans-serif;
$dark-blue: #2f3441;
$darker-blue: #212531;
$pop: #{$red};
$pop-light: lighten($red, 10%);
$pop-darker: darken($red, 10%);
$pop-dark: darken($red, 20%);
$red-darker: #de3c32;

* {
  text-rendering: optimizeLegibility;
}

.text-pop {
  color: $pop !important;
}

.Collapsible {
  background-color: white;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border: none;
  }
}

.collapse_block {
  margin-left: 40px;
}

.Collapsible__contentInner {
  padding: 10px;
  border-top: 0;
  padding-bottom: 1.5em;

  strong {
    font-weight: 500 !important;
    color: black !important;
  }
  p {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5em;
    font-family: $font-primary !important;
    color: darken(#888888, 20%) !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Collapsible__trigger {
  font-family: 'Myriad' !important;
  display: block;
  font-weight: 500;
  text-decoration: none;
  color: #000;
  transition: color 0.1s ease;
  position: relative;
  border: none;
  padding: 1em 10px;
  background: #ffffff;
  cursor: pointer;
  font-size: 16px;
  padding-left: 50px;

  &:hover {
    color: black !important;
  }
  &:after {
    position: absolute;
    left: 10px;
    top: 1em;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
    background: #fafafa;
    color: #6f737c;
    display: block;
    transition: transform 300ms;
  }
}

//* Collapse pop color */
.Collapsible__trigger.is-open {
  color: #000;
  transition: color 0.1s ease;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
  color: white;
  background: #000;
}
