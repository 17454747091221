#TaskReview .rounded-btn {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: #eceff1;
  transition: all 200ms ease;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0px;
}
#TaskReview .rounded-btn.sm {
  width: 25px;
  height: 25px;
}
#TaskReview .rounded-btn:hover {
  background: #cfd6db;
}
#TaskReview .rounded-btn.green {
  background: #40d78f;
}
#TaskReview .rounded-btn.green i {
  color: white !important;
}
#TaskReview .rounded-btn.green:hover {
  background: #2cd283;
}
#TaskReview .rounded-btn.red {
  background: #e90f09;
}
#TaskReview .rounded-btn.red i {
  color: white !important;
}
#TaskReview .rounded-btn.red:hover {
  background: #d00d08;
}
#TaskReview .rounded-btn.orange {
  background: #fd9926;
}
#TaskReview .rounded-btn.orange i {
  color: white !important;
}
#TaskReview .rounded-btn.orange:hover {
  background: #fd8d0d;
}
#TaskReview .rounded-btn.blue {
  background: #1558a3;
}
#TaskReview .rounded-btn.blue i {
  color: white !important;
}
#TaskReview .rounded-btn.blue:hover {
  background: #124c8c;
}
#TaskReview .text-blue {
  font-weight: 500;
  color: #2453d5 !important;
}
@-webkit-keyframes fadeInA {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#TaskReview .fadeInAnim {
  animation: fadeInA ease 400ms;
  -webkit-animation: fadeInA ease 400ms;
  -moz-animation: fadeInA ease 400ms;
  -o-animation: fadeInA ease 400ms;
  -ms-animation: fadeInA ease 400ms;
}
#TaskReview p,
#TaskReview h1,
#TaskReview h2,
#TaskReview h3,
#TaskReview h4,
#TaskReview h5,
#TaskReview h6,
#TaskReview label,
#TaskReview span,
#TaskReview small,
#TaskReview button,
#TaskReview a {
  font-family: "Myriad", sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0px;
  text-rendering: optimizeLegibility;
}
#TaskReview h1 {
  font-size: calc(0.9 * 3.052em);
  font-weight: 500;
  margin-bottom: 18px;
  color: #2e3b48;
}
@media (min-width: 2000px) {
  #TaskReview h1 {
    font-size: calc(1.4 * 3.052em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h1 {
    font-size: calc(0.8 * 3.052em);
  }
}
@media (max-width: 540px) {
  #TaskReview h1 {
    font-size: 34.84px;
  }
}
#TaskReview h2 {
  font-size: calc(0.9 * 2.441em);
  font-weight: 500;
  margin-bottom: 18px;
  color: #2e3b48;
}
@media (min-width: 2000px) {
  #TaskReview h2 {
    font-size: calc(1.4 * 2.441em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h2 {
    font-size: calc(0.8 * 2.441em);
  }
}
@media (max-width: 540px) {
  #TaskReview h2 {
    font-size: 29.03px;
  }
}
#TaskReview h3 {
  font-size: calc(0.9 * 1.953em);
}
@media (min-width: 2000px) {
  #TaskReview h3 {
    font-size: calc(1.4 * 1.953em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h3 {
    font-size: calc(0.8 * 1.953em);
  }
}
@media (max-width: 540px) {
  #TaskReview h3 {
    font-size: 24.19px;
  }
}
#TaskReview h4 {
  font-size: calc(0.9 * 1.763em);
  font-weight: 500;
  color: #2e3b48;
}
@media (min-width: 2000px) {
  #TaskReview h4 {
    font-size: calc(1.4 * 1.763em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h4 {
    font-size: calc(0.8 * 1.763em);
  }
}
@media (max-width: 540px) {
  #TaskReview h4 {
    font-size: 20.16px;
  }
}
#TaskReview h45 {
  font-family: "Myriad", sans-serif;
  font-size: calc(0.9 * 1.45em);
  font-weight: 500;
}
@media (min-width: 2000px) {
  #TaskReview h45 {
    font-size: calc(1.4 * 1.45em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h45 {
    font-size: calc(0.8 * 1.45em);
  }
}
@media (max-width: 540px) {
  #TaskReview h45 {
    font-size: 20.16px;
  }
}
#TaskReview h5 {
  font-size: calc(0.9 * 1.25em);
}
@media (min-width: 2000px) {
  #TaskReview h5 {
    font-size: calc(1.4 * 1.25em);
  }
}
@media (max-width: 1280px) {
  #TaskReview h5 {
    font-size: calc(0.8 * 1.25em);
  }
}
@media (max-width: 540px) {
  #TaskReview h5 {
    font-size: 18px;
  }
}
#TaskReview p {
  font-size: calc(0.9 * 1em);
  color: #878787;
  font-weight: 500;
}
@media (min-width: 2000px) {
  #TaskReview p {
    font-size: calc(1.4 * 1em);
  }
}
@media (max-width: 1280px) {
  #TaskReview p {
    font-size: calc(0.8 * 1em);
  }
}
#TaskReview label {
  font-size: calc(0.9 * 0.8em);
}
@media (min-width: 2000px) {
  #TaskReview label {
    font-size: calc(1.4 * 0.8em);
  }
}
@media (max-width: 1280px) {
  #TaskReview label {
    font-size: calc(0.8 * 0.8em);
  }
}
#TaskReview ulabel {
  font-family: "Myriad", sans-serif;
  font-size: calc(0.9 * 14px);
  color: #2e3b48;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08ch;
}
@media (min-width: 2000px) {
  #TaskReview ulabel {
    font-size: calc(1.4 * 14px);
  }
}
@media (max-width: 1280px) {
  #TaskReview ulabel {
    font-size: calc(0.8 * 14px);
  }
}
#TaskReview ulabel.sm {
  font-size: calc(0.9 * 10px);
}
@media (min-width: 2000px) {
  #TaskReview ulabel.sm {
    font-size: calc(1.4 * 10px);
  }
}
@media (max-width: 1280px) {
  #TaskReview ulabel.sm {
    font-size: calc(0.8 * 10px);
  }
}
#TaskReview small {
  font-size: calc(0.9 * 0.64em);
}
@media (min-width: 2000px) {
  #TaskReview small {
    font-size: calc(1.4 * 0.64em);
  }
}
@media (max-width: 1280px) {
  #TaskReview small {
    font-size: calc(0.8 * 0.64em);
  }
}
#TaskReview a {
  color: #1a6bc6;
  font-weight: 600;
  transition: all 200ms ease;
  cursor: pointer;
}
#TaskReview a:hover {
  color: #114682;
}

.login--page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background: url("../../../img/PPG-Leanne-Ford-Pure-White.jpg");
}
.login--page.reviews {
  background: url("https://i.imgur.com/zYollE5.jpg");
  background-size: cover;
  background-position: center;
}
.login--page .login--container {
  min-height: 100vh;
  width: 100%;
  max-width: 450px;
  padding: 0.5em;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (min-width: 2000px) {
  .login--page .login--container {
    max-width: calc(1.4 * 350px);
  }
}
.login--page .login--container .login--block {
  width: 100%;
  text-align: center;
  background: white;
  padding: 4em 1em;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(149, 163, 184, 0.5);
}
@media (min-width: 2000px) {
  .login--page .login--container .login--block {
    padding-top: calc(1.4 * 4em);
    padding-bottom: calc(1.4 * 4em);
    padding-left: calc(1.4 * 1em);
    padding-right: calc(1.4 * 1em);
  }
}
.login--page .login--container input[type=number]::-webkit-inner-spin-button,
.login--page .login--container input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login--page .login--container input,
.login--page .login--container textarea {
  width: 100%;
  border-radius: 2px;
  padding: 16px;
  outline: none;
  position: relative;
  -webkit-transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
  transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
  color: #39364f;
  font-weight: 500;
  letter-spacing: 0.05ch;
  font-family: "Myriad", sans-serif;
  font-size: calc(0.9 * 1em);
  outline: none;
  border: 1px solid #e3e8ee;
}
@media (min-width: 2000px) {
  .login--page .login--container input,
.login--page .login--container textarea {
    font-size: calc(1.4 * 1em);
    padding: calc(1.4 * 16px);
  }
}
.login--page .login--container input:disabled,
.login--page .login--container textarea:disabled {
  background-color: #f8f7fa !important;
  color: #9499a3 !important;
  border-color: #e3e8ee !important;
}
.login--page .login--container input::placeholder,
.login--page .login--container textarea::placeholder {
  color: #afb3bb !important;
}
.login--page .login--container input:hover,
.login--page .login--container textarea:hover {
  color: #232131;
  border-color: #c3cedb;
}
.login--page .login--container input:active, .login--page .login--container input:focus,
.login--page .login--container textarea:active,
.login--page .login--container textarea:focus {
  color: #2e3b48;
  border-color: #c3cedb;
}
.login--page .login--container button {
  background: #2453d5;
  padding: 16px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: "Myriad", sans-serif;
  font-size: calc(0.9 * 1em);
  color: white;
  font-weight: 600 !important;
  outline: none;
  border: none;
  -webkit-transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
  transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
  /*&.fb{
      background-color: #4267B2;
      border-color: #4267B2;

      &:hover{
        background: darken(#4267B2, 5%);
        border-color: darken(#4267B2, 5%);
      }
    }

      &.google{
        background-color: #C63D2D;
        border-color: #C63D2D;

        &:hover{
          background: darken(#C63D2D, 5%);
          border-color: darken(#C63D2D, 5%);
        }
      }
    */
}
.login--page .login--container button.disabled {
  background: #d3d9e1;
}
.login--page .login--container button.disabled:hover {
  background: #c4ccd7;
}
@media (min-width: 2000px) {
  .login--page .login--container button {
    font-size: calc(1.4 * 1em);
  }
}
@media (max-width: 1280px) {
  .login--page .login--container button {
    font-size: calc(0.8 * 1em);
  }
}
.login--page .login--container button:hover {
  background: #204bbf;
}
.login--page .login--container button.fb, .login--page .login--container button.google {
  padding: 0px 8px;
  height: 50px;
  background: white;
  border: 1px solid #dee1e5;
  color: #5c6b7e;
  font-weight: 600;
}
@media (min-width: 2000px) {
  .login--page .login--container button.fb, .login--page .login--container button.google {
    height: calc(1.4 * 41px);
    padding: 0px 16px;
  }
}
.login--page .login--container button.fb:hover span, .login--page .login--container button.google:hover span {
  color: #2e3b48 !important;
}
.login--page .login--container button.fb i {
  color: #4267b2;
}
.login--page .login--container button.google i {
  color: #c63d2d;
}
.login--page .login--container .star-row {
  padding: 1em 0em;
}
.login--page .login--container .star-row .star-item {
  padding: 0.5em 0.2em;
  cursor: pointer;
  transition: all 200ms ease;
}
.login--page .login--container .star-row .star-item:hover i {
  color: #a7abb5;
}
.login--page .login--container .star-row .star-item i {
  transition: all 200ms ease;
  font-size: 25px;
  color: #b5b8c1;
}
.login--page .login--container .checklist {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-bottom: 1em;
}
.login--page .login--container .checklist .badge-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 0.2em;
  background: #f2f5f9;
  border: 1px solid #4670b2;
  border-radius: 4px;
  padding: 0.4em;
  cursor: pointer;
  transition: all 200ms ease;
}
.login--page .login--container .checklist .badge-item:hover {
  background: #e1e8f1;
}
.login--page .login--container .checklist .badge-item p {
  color: #4670b2;
  font-weight: 600;
}
.login--page .login--container .checklist .badge-item .select-letter {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #4670b2;
  background: white;
  font-family: "Myriad", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #4670b2;
}
.login--page .login--container .checklist .badge-item.active .select-letter {
  background: #4670b2;
  color: white;
}
