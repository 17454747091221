// cleaned
//@import '~slick-carousel/slick/slick.css';
//@import '~slick-carousel/slick/slick-theme.css';

#howItWorks {
  padding: 4em 0em;
  h3 {
    font-weight: 400 !important;
    font-size: 32px !important;
    margin-bottom: 20px !important;
    letter-spacing: -0.02em !important;
    line-height: 1.22222 !important;

    @media (max-width: 567px) {
      font-size: 22px !important;
      margin-bottom: 16px !important;
    }
  }
  h5 {
    color: #000;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 17px !important;

    @media (max-width: 567px) {
      font-size: 18px !important;
    }
  }
  p {
    color: #484848 !important;
    text-rendering: optimizeLegibility !important;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.5rem !important;
    font-size: 14px !important;
    -webkit-font-smoothing: antialiased !important;
  }

  .flex-row {
    @media (max-width: 767px) {
      margin-bottom: 2em;
    }
  }

  .num {
    opacity: 0.7;
    color: #86cfdc;
    margin-right: 1em;
  }
}

#rateModal.modal-dialog {
  height: 100vh;
}

#rateModal {
  .rateBtn {
    background: linear-gradient(
      to right,
      #f45c43,
      #eb3349
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    cursor: pointer;
    outline: none;
    border: none;
    line-height: 2em;

    &:hover {
      opacity: 0.9;
    }
  }
  .modal-content {
    top: 30%;
    transform: translateY(-30%);

    button {
      cursor: pointer;
    }
  }

  .modal-header {
    background: black;
    color: white;

    .modal-title {
      width: 100%;
    }
    .close {
      color: white;
    }
  }

  .modal-body {
    background: white;
    padding: 0;

    .closer {
      position: relative;
      display: block;

      .closeBtn {
        position: absolute;
        right: 10px;
        top: 10px;
        line-height: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        text-align: center;
        display: block;
        -webkit-transition: 0.233s ease;
        transition: 0.233s ease;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.8);

        &:hover {
          color: rgba(255, 255, 255, 1);
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }

    .header {
      color: white;
      background: #000;
      padding-top: 3em;
      padding-bottom: 2em;

      .container {
        max-width: 90%;
        margin: 0px auto;
      }
    }

    #initial-avatar {
      font-size: 100% !important;
      background: #36d1dc; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to top,
        #5b86e5,
        #36d1dc
      ) !important; /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to top,
        #5b86e5,
        #36d1dc
      ) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
  }

  label.dv-star-rating-star.dv-star-rating-full-star,
  label.dv-star-rating-star.dv-star-rating-empty-star {
    font-size: 25px;
    letter-spacing: 0.5em;
  }
}

.ratingText {
  text-align: center;
  padding: 1em;

  .wrapper {
    border: 1px solid #ddd;
    border-radius: 5px;

    textarea {
      width: 100%;
      border: none;
      font-weight: 500;
      padding: 1em;
      border-radius: 5px;
      resize: none;

      &:focus {
        outline: none;
      }
    }
  }

  .badges {
    background: #f2f2f2;
    width: 100%;
    padding: 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;

    &.negative {
      .badge-item {
        background: #fff;
        display: inline-block;
        padding: 1em 2em;
        border: 1px solid #b4b4b4;
        border-radius: 50px;
        color: #000;
        font-weight: 500;
        line-height: 2em;
        letter-spacing: 0.02em;
        font-size: 0.75rem;
        margin: 0.2em;
        cursor: pointer;
        -webkit-transition: 0.233s ease;
        transition: 0.233s ease;

        &:hover {
          color: black;
          border-color: black;
        }

        &.active {
          background: #eb3349; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #f45c43,
            #eb3349
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #f45c43,
            #eb3349
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
          color: white;
          border: 1px solid transparent;

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    .badge-item {
      background: #fff;
      display: inline-block;
      padding: 1em 2em;
      border: 1px solid #b4b4b4;
      border-radius: 50px;
      color: #000;
      font-weight: 500;
      line-height: 2em;
      letter-spacing: 0.02em;
      font-size: 0.75rem;
      margin: 0.2em;
      cursor: pointer;
      -webkit-transition: 0.233s ease;
      transition: 0.233s ease;

      &:hover {
        color: #2d2e31;
        border-color: #2d2e31;
      }

      &.active {
        background: linear-gradient(to right, #9287ff, #32c1ff 100%);
        color: white;
        border: 1px solid rgba(255, 255, 255, 0);

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
