$muted: #3c577b;
$borderColor: #dbdde1;
$blue: #3b9cff;
$green: #1dc09b;
$darkGreen: #27ae60;
$red: #d23544;
$orange: #e67e22;
$pink: #ee6a94;
$even-bg: #f1f3f6;
$odd-bg: #ffffff;

$header-top-height: 50px;
$header-bottom-height: 50px;
$header-total-height: $header-top-height + $header-bottom-height;

.lastLogin {
  &:hover {
    .green-icon {
      display: none;
    }
    .time {
      display: block;
    }
  }
  .time {
    display: none;
  }
}

.map-popover {
  font-size: 16px;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
  z-index: 99 !important;

  .pp-modal-2 {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    label,
    em,
    b,
    strong {
      font-family: 'Myriad', sans-serif !important;
      margin: 0;
      font-weight: 500;
    }
    width: 100% !important;

    .modal-body {
      background: white;
      padding: 0;
    }

    .pp-container {
      width: 100%;
      max-width: 95%;
      margin: 0px auto;
    }

    .pp-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1em 15px;
      //border-bottom: 1px solid #e4e4e4;

      .pp-container {
        justify-content: space-between;
      }

      .pp-head--title {
        font-family: 'Myriad', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .pp-head--btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #fafafa;
        transition: all 200ms ease;

        &:hover {
          background: #222;
          .fa-map-marker {
            color: white !important;
          }
        }
      }
    }

    .pp-body {
      padding: 1em 15px;
      border-bottom: none;

      .pp-job-list {
        li {
          margin-bottom: 0.2em;
        }

        .pp-job-item {
          width: 100%;
          display: block;
          border-radius: 4px;
          padding: 1em 2em;
        }

        .hover-info {
          position: relative;
          overflow: visible;

          &:hover {
            &::after {
              content: '' !important;
              background: none !important;
              display: none !important;
            }
          }
        }

        //assigned card
        .bg-primary {
          background: darken($blue, 10%) !important;
          border: none !important;
          color: white !important;
          transition: all 200ms ease;
          font-weight: 600;

          &:hover {
            background: darken($blue, 15%) !important;
            box-shadow:
              0 7px 14px 0 rgba(50, 50, 93, 0.1),
              0 3px 6px 0 rgba(0, 0, 0, 0.07);
          }
        }

        //pending card
        .bg-danger {
          background: $red !important;
          transition: all 200ms ease;
          border: none !important;
          font-weight: 600;

          &:hover {
            background: darken(red, 15%) !important;
            box-shadow:
              0 7px 14px 0 rgba(50, 50, 93, 0.1),
              0 3px 6px 0 rgba(0, 0, 0, 0.07);
          }
        }
        //completed card
        .bg-success {
          background: $green !important;
          transition: all 200ms ease;
          border: none !important;
          font-weight: 600;

          &:hover {
            background: darken($green, 5%) !important;
            box-shadow:
              0 7px 14px 0 rgba(50, 50, 93, 0.1),
              0 3px 6px 0 rgba(0, 0, 0, 0.07);
          }
        }

        //cancelled card
        .bg-white {
          box-shadow:
            0 7px 14px 0 rgba(50, 50, 93, 0.1),
            0 3px 6px 0 rgba(0, 0, 0, 0.07);
          border: 1px solid #ddd !important;
          font-weight: 500;
          transition: all 200ms ease;
          color: $muted !important;

          &:hover {
            color: black;
            background: #f1f3f8 !important;
            box-shadow:
              0 7px 14px 0 rgba(50, 50, 93, 0.1),
              0 3px 6px 0 rgba(0, 0, 0, 0.07);
          }
        }
      }
    }

    .pp-btn {
      width: 100%;
      border-radius: 4px;
      padding: 1em 1em;
      font-family: 'Myriad', sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;

      &.primary {
        background: $blue;
        color: white;
      }

      &:hover {
        background: darken($blue, 10%);
      }
    }
  }
}

.schedule {
  .schedule-slot {
    font-size: 18px;

    &.visitCancelled {
      background-color: grey !important;
    }
  }

  .bg-purple {
    background-color: #8e44ad !important;
    border: 1px solid #8e44ad !important;
  }

  .dark-nav {
    background: #222;
    padding: 1em;
  }
  .version2 {
    position: relative;
    height: 100vh;
    display: flex;
    flex-flow: column;

    .Select-menu-outer {
      z-index: 999999999;
    }

    .map-title {
      h2 {
        font-family: 'Myriad', sans-serif !important;
        font-weight: 400 !important;
        color: white;
        margin: 0;

        strong {
          font-weight: 500 !important;
        }
      }

      h4 {
        font-size: 18px !important;
        font-family: 'Myriad', sans-serif !important;
        font-weight: 400 !important;
        //color: $muted !important;
        color: #eee;
        margin: 0;
      }
    }

    .badge-default {
      background: $red;
      font-weight: 600;
      font-size: 16px;
      font-family: 'Myriad', sans-serif;
      padding: 0.2em 0.5em;
      padding-top: 0.4em;
      border-radius: 4px;
      color: white;
    }

    .map-popover {
      b,
      p,
      label {
        line-height: 1.5em;
        font-family: 'Myriad', sans-serif;
        display: block;
        margin: 0;
      }
      b {
        font-weight: 600;
      }
      label {
        color: $muted !important;
        font-weight: 500;
      }
    }
    .map-legend {
      position: absolute;
      top: 0px;
      margin-top: 66px;
      left: 1rem;
      padding: 1em;
      padding-left: 0;
      border-radius: 5px;
      border: 1px solid $borderColor;
      width: 200px;
      background: white;
      font-family: 'Myriad', sans-serif !important;
      font-weight: 600;
      color: $muted;

      .map-legend-icon {
        width: 40px;
      }
    }
    .header-left {
      overflow-x: auto;
      white-space: nowrap;
    }

    #map {
      height: 100%;
    }

    .rct-header {
      height: $header-total-height !important;

      .top-header {
        height: $header-top-height !important;
        background: #0275d8;

        .rct-label-group {
          border-right: 1px solid rgba(255, 255, 255, 0.25) !important;
          &:nth-child(even) {
            background: rgba(0, 0, 0, 0.2) !important;
          }
        }
      }

      .bottom-header {
        height: $header-bottom-height !important;
        background: #f2f2f2;
      }
    }
    //assigned card
    .bg-primary {
      //background-color: rgb(33, 150, 243) !important;
      background: $blue !important;
      border: none !important;
      color: white !important;
      transition: all 200ms ease;
      font-weight: 600;

      &:hover {
        background: darken($blue, 5%) !important;
        box-shadow:
          0 7px 14px 0 rgba(50, 50, 93, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.07);
      }

      &.hover-info {
        &:hover {
          &::after {
            background: linear-gradient(
              to right,
              rgba(59, 156, 255, 0) 0%,
              rgba(59, 156, 255, 1) 50%,
              rgba(59, 156, 255, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
        }
      }
    }

    //pending card
    .bg-danger {
      background: $red !important;
      transition: all 200ms ease;
      border: none !important;
      font-weight: 600;

      &:hover {
        background: darken(red, 15%) !important;
        box-shadow:
          0 7px 14px 0 rgba(50, 50, 93, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.07);
      }

      &.hover-info {
        &:hover {
          &::after {
            background: linear-gradient(
              to right,
              rgba(210, 53, 68, 0) 0%,
              rgba(210, 53, 68, 1) 50%,
              rgba(210, 53, 68, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
        }
      }
    }

    //completed card
    .bg-success {
      background: $green !important;
      transition: all 200ms ease;
      border: none !important;
      font-weight: 600;

      &:hover {
        background: darken($green, 5%) !important;
        box-shadow:
          0 7px 14px 0 rgba(50, 50, 93, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.07);
      }

      &.hover-info {
        &:hover {
          &::after {
            background: linear-gradient(
              to right,
              rgba(29, 192, 155, 0) 0%,
              rgba(29, 192, 155, 1) 50%,
              rgba(29, 192, 155, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
        }
      }
    }

    //cancelled card
    .bg-white {
      box-shadow:
        0 7px 14px 0 rgba(50, 50, 93, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
      border: 1px solid #ddd !important;
      font-weight: 500;
      transition: all 200ms ease;
      color: $muted !important;

      &:hover {
        color: black;
        background: #f1f3f8 !important;
        box-shadow:
          0 7px 14px 0 rgba(50, 50, 93, 0.1),
          0 3px 6px 0 rgba(0, 0, 0, 0.07);
      }

      &.hover-info {
        &:hover {
          &::after {
            color: black;
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 50%,
              rgba(255, 255, 255, 1) 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          }
        }
      }
    }

    .custom-group {
      position: relative;
      cursor: pointer;

      &:hover,
      &.active {
        .icon-wrapper {
          display: table-cell;
          vertical-align: middle;
        }
      }
      &.active {
        .icon-wrapper {
          opacity: 1;
        }
      }

      .icon-wrapper {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        display: none;
        font-size: 20px;
        opacity: 0.5;
      }
    }

    //top header
    .top-header {
      background: white;
      box-shadow:
        0 7px 14px 0 rgba(50, 50, 93, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
      padding: 0em;
      border-bottom: 1px solid $borderColor;

      h2 {
        font-family: 'Myriad', sans-serif !important;
        font-weight: 400 !important;
        color: black;
        margin: 0;

        strong {
          font-weight: 500 !important;
        }
      }

      h4 {
        font-size: 18px !important;
        font-family: 'Myriad', sans-serif !important;
        font-weight: 400 !important;
        color: $muted !important;
        margin: 0;
      }

      .container {
        max-width: 1440px;
      }

      .header-1 {
        padding: 1em 0em;
        padding-top: 2em;
      }

      .header-2 {
        font-family: 'Myriad', sans-serif;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        width: 100%;
        //border-bottom: 1px solid #e4e4e4;
        //padding: 0em 1em;

        .header-col {
          text-align: left;
          padding: 1em 0.5em;

          &.black {
            border-bottom: 2px solid black;
          }

          &.purple {
            border-bottom: 2px solid #8e44ad;
          }
          &.blue {
            border-bottom: 2px solid $blue;
          }

          &.red {
            border-bottom: 2px solid $red;
          }

          &.green {
            border-bottom: 2px solid $green;
          }

          &.cancelled {
            border-bottom: 2px solid $borderColor;
          }

          &.orange {
            border-bottom: 2px solid #f4982c;
          }

          &.info {
            border-bottom: 2px solid #17a2b8;
          }

          p {
            font-family: 'Myriad', sans-serif !important;
            font-weight: 600 !important;
            color: $muted;
            display: inline-block;
            margin-left: 0.5em;
          }

          .hcircle-icon {
            width: 15px !important;
            height: 15px !important;
            display: inline-block;
            border-radius: 50%;
          }
        }
      }
    }

    //header
    .header {
      background: white;
      min-height: 57px;
      overflow-y: hidden;
      display: flex;
      box-shadow:
        0 7px 14px 0 rgba(50, 50, 93, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);

      .header-left {
        width: 150px;
      }
      .header-right {
        overflow-x: auto;
        display: flex;
      }
    }

    .react-calendar-timeline .rct-vertical-lines .rct-vl {
      border-color: $borderColor;

      &.rct-vl-first {
        border-left-color: #7fa0c0 !important;
        border-left-width: 1px !important;
      }
    }

    .rct-horizontal-lines {
      border: none !important;
    }

    .rct-hl-even {
      background: $even-bg;
      transition: all 200ms ease;

      &:hover {
        background: darken($even-bg, 5%) !important;
        transition: all 200ms ease;
      }
    }

    .rct-hl-odd {
      background: $odd-bg;
      transition: all 200ms ease;

      &:hover {
        background: #fafafa !important;
        transition: all 200ms ease;
      }
    }

    .react-calendar-timeline .rct-sidebar-header {
      background: white;
    }

    .react-calendar-timeline .rct-header .rct-label-group {
      line-height: 54px !important;
      border: none;
      height: $header-top-height !important;
      span {
        font-family: 'Myriad', sans-serif;
        font-weight: 600 !important;
        font-size: 16px;
      }
      background: none;
    }

    .rct-label {
      line-height: $header-bottom-height !important;
      font-family: 'Myriad', sans-serif;
      font-weight: 600 !important;
      color: $muted;
      height: $header-bottom-height !important;
      background: none !important;
      border-color: $borderColor;
      //border-left: none !important;
    }

    .rct-item {
      overflow: hidden;
      border-radius: 4px;
      padding-left: 8px;

      &:hover {
        .title {
          display: none;
        }
        .status {
          display: inline;
        }
      }

      .status {
        display: none;
        text-transform: uppercase;
      }

      .rct-item-content {
        padding: 0 !important;

        .slotTitle {
          color: inherit;
          white-space: nowrap;
          font-family: 'Myriad', sans-serif;
          font-weight: 600 !important;
          text-transform: inherit !important;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }

    .rct-outer {
      .rct-sidebar {
        .rct-sidebar-row {
          border-bottom: 1px solid $borderColor !important;

          &.rct-sidebar-row-even {
            background: $even-bg;
          }

          &.rct-sidebar-row-odd {
            background: $odd-bg;
          }

          .title,
          .title-text {
            font-family: 'Myriad', sans-serif !important;
            font-weight: 600 !important;
            color: $muted;
          }
        }
      }
    }

    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      border-bottom: 1px solid $borderColor !important;
    }
  }
}

.hover-info {
  position: relative;
  overflow: visible;

  &:hover {
    &::after {
      font-family: 'Myriad', sans-serif;
      font-weight: 600;
      color: white;
      padding: 0em 0.5em;
      content: '(Double click to open)';
      text-align: right;
      transition: all 200ms ease;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
      background: -moz-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.85) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.85) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.85) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 ); /* IE6-9 */
      width: 100%;
      //background: rgba(0,0,0,0.3);
      position: absolute;
      right: 0;
      top: 0;
      overflow: visible;
      z-index: 9999;
    }
  }
}

$muted: #3c577b;
$borderColor: #dbdde1;
$blue: #3b9cff;
$green: #1dc09b;
$red: #d23544;
$even-bg: #f1f3f6;
$odd-bg: #ffffff;
$yellow: #ffc107;
$purple: #8e44ad;

.modal-dialog.edit_route_modal {
  font-size: 16px;
  max-width: 1000px !important;
}

.schedule {
  .btn-secondary.active {
    background-color: #eee !important;
    color: black !important;
  }

  .timeline {
    table {
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      white-space: nowrap;
      font-weight: 600;
      font-family: 'Myriad', sans-serif !important;
      font-size: '18px';
    }
    td + td,
    th + th {
      width: auto;
    }
  }

  a.pro {
    color: #212529 !important;
  }

  font-size: 16px;

  th {
    text-align: center;
  }

  .dropdown {
    &:hover > .dropdown-body {
      display: block;
    }
    .dropdown-body {
      position: absolute;
      top: 0;
      display: none;
      background: white;
      z-index: 999999999;
      box-shadow:
        0 7px 14px 0 rgba(0, 0, 0, 0.1),
        0 3px 6px 0 rgba(0, 0, 0, 0.07);
      transition: all 200ms ease;

      > * {
        color: #212529;
        display: block;
        padding: 8px;

        &:hover {
          background: #fafafa;
        }
      }
    }
  }

  .cell {
    white-space: nowrap;
    overflow-x: hidden;
    display: block;
    width: 100%;

    a:hover {
      -webkit-box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
      -moz-box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
      box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
    }

    p {
      font-family: 'Myriad', sans-serif !important;
      font-size: 18px;
    }

    a {
      color: white;
      font-weight: 600;
      display: block;
      margin-bottom: 4px;
      border-radius: 4px;
      font-family: 'Myriad', sans-serif !important;

      .title,
      .title-text {
        font-family: 'Myriad', sans-serif !important;
      }

      .map-button {
        border: 1px solid #fff;
        border-radius: 5px;
        padding: 0px 5px;
        margin-right: 5px;
        text-decoration: none;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        background: white;
        transition: all 200ms ease;

        &:hover {
          opacity: 0.5;
        }
      }

      .status {
        display: none;
        font-family: 'Myriad', sans-serif !important;
      }

      &:hover {
        .status {
          display: inline;
        }
        .title {
          display: none;
        }
      }

      &.cancelled {
        color: #212529;
        border-color: #e4e4e4;
      }

      .fa-star {
        color: white;
      }
    }

    .abandoned {
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      background: white;

      &:hover {
        background: #fafafa;
        color: $blue;
      }
    }

    .cancelled {
      background: white;

      &:hover {
        background: #fafafa;
        color: $blue;
      }

      .fa-star {
        color: #6e849a !important;
      }

      .map-button {
        border: 1px solid #999999;
        text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
        background-color: #ffffff;
        background-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#ffffff),
          to(#cfcfcf)
        );
        background-image: -webkit-linear-gradient(top, #ffffff, #cfcfcf);
        background-image: -moz-linear-gradient(top, #ffffff, #cfcfcf);
        background-image: -ms-linear-gradient(top, #ffffff, #cfcfcf);
        background-image: -o-linear-gradient(top, #ffffff, #cfcfcf);
        background-image: linear-gradient(to bottom, #ffffff, #cfcfcf);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#FFFFFF, endColorstr=#CFCFCF);

        i {
          color: #000;
        }
      }
    }

    .claimed {
      background-color: $blue;
      border-color: darken($blue, 5%);

      &:hover {
        background: darken($blue, 20%);
      }
      .fa-map-marker {
        color: $blue;
      }
    }

    .visitCancelled .fa-map-marker {
      color: grey !important;
    }

    .completed {
      background: $green;
      border-color: darken($green, 5%);

      &:hover {
        background: darken($green, 20%);
      }
      .fa-map-marker {
        color: $green;
      }
    }

    .visitCompleted {
      background: $darkGreen;
      border-color: darken($darkGreen, 5%);

      &:hover {
        background: darken($darkGreen, 20%);
      }
      .fa-map-marker {
        color: $darkGreen;
      }
    }

    .inProgress {
      background: $purple;
      border-color: darken($purple, 5%);

      &:hover {
        background: darken($purple, 20%);
      }

      .fa-map-marker {
        color: $purple;
      }
    }

    .pending,
    .suggested {
      background: $orange;
      border-color: darken($orange, 5%);

      &:hover {
        background: darken($orange, 20%);
      }

      .fa-map-marker {
        color: $orange;
      }

      &.preferred {
        background: $red;
        border-color: darken($red, 5%);
        .fa-map-marker {
          color: $red;
        }

        &:hover {
          background: darken($red, 20%);
        }
      }
    }

    .waitingOnSchedule {
      color: white;
      background: $pink;
      border-color: darken($pink, 5%);

      &:hover {
        background: darken($pink, 20%);
      }

      .fa-map-marker {
        color: $pink;
      }
    }

    .recall {
      background: $yellow;
      border-color: darken($yellow, 5%);
      &:hover {
        background: darken($yellow, 20%);
      }
      .fa-map-marker {
        color: $yellow;
      }
    }
  }
}

.tool-popper {
  opacity: 1 !important;
  margin-top: -5px;

  .tool-tip {
    background: white !important;
    opacity: 1 !important;
    color: black;
    -webkit-box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
    -moz-box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
    box-shadow: 0px 7px 19px -3px rgba(73, 73, 79, 1);
    padding: 16px;

    h2 {
      font-family: 'Myriad', sans-serif !important;
      font-weight: 600;
      font-size: 16px;
      margin: 0px !important;
    }

    p,
    .text-blue {
      font-family: 'Myriad', sans-serif !important;
      font-weight: 600;
      font-size: 16px;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent white transparent;
      left: 50%;
      top: -10px;
      transform: translateX(-50%);
    }
  }
}
