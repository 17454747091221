$font-1: 'Benton', sans-serif;
$font-2: 'Calibre', sans-serif;
$bg: #f7fafc;
$text: #878787;
$bold: #2e3b48;
$muted: #9499a3;
$border: #e3e8ee;
$blue: #1a6bc6;
$c-black: #2e3c49;
$c-green: #40d78f;
$c-gray: #dfe2e8;
$c-red: #e90f09;
$c-yellow: #fcd240;
$c-pink: #e84f89;
$c-orange: #fd9926;
$c-purple: #8e69ec;
$c-teal: #34cbdb;
$c-blue: #2453d5;
$c-darkBlue: #021635;
$c-royalBlue: #0f183e;
$c-lightBlue: #5195ff;

.read-more-button {
  font-weight: 600 !important;
  color: $blue !important;
  transition: all 200ms ease;
  cursor: pointer;

  &:hover {
    color: darken($blue, 15%) !important;
  }
}

#V8 {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a {
    font-family: $font-1;
    font-weight: 500;
    line-height: 1.5em;
    margin: 0px;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: 3.052em;
    font-weight: 500;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (max-width: 540px) {
      font-size: 34.84px;
    }
  }

  h2 {
    font-size: 2.441em;
    font-weight: 500 !important;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (max-width: 540px) {
      font-size: 29.03px;
    }
  }

  h3 {
    font-size: 1.953em;

    @media (max-width: 540px) {
      font-size: 24.19px;
    }
  }

  h4 {
    font-size: 1.763em;
    font-weight: 500 !important;

    @media (max-width: 540px) {
      font-size: 20.16px;
    }
  }

  h5 {
    font-size: 1.25em;

    @media (max-width: 540px) {
      font-size: 18px;
    }
  }

  p {
    color: $text;
  }

  ulabel {
    font-family: $font-1;
    font-size: 14px;
    color: #2e3b48;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08ch;
  }

  small {
    font-size: 0.64em;
  }

  a {
    color: $blue;
    font-weight: 600;
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      color: darken($blue, 15%);
    }
  }
}
