// cleaned

$font: 'Myriad', sans-serif;

//
// MOBILE ONLY WIDTH VARIABLES
//
//widthmobile-width
.wm-100 {
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

.text-center-sm {
  @media (max-width: 767px) {
    text-align: center !important;
  }
}

.mb-sm-1 {
  @media (max-width: 767px) {
    margin-bottom: 1em !important;
  }
}

.mt-sm-1 {
  @media (max-width: 767px) {
    margin-top: 1rem !important;
  }
}

.mr-sm-0 {
  @media (max-width: 767px) {
    margin-right: 0px;
  }
}

.m-sm-light {
  @media (max-width: 767px) {
    background: white !important;
    color: black !important;
  }
}

.vertical-center {
  align-content: center !important;
  align-items: center !important;
}

//
// LINE HEIGHT
//
.lh-4 {
  line-height: 4em !important;
}

.lh-2 {
  line-height: 2em !important;
}

.lh-15 {
  line-height: 1.5em !important;
}

.lh-12 {
  line-height: 1.2em !important;
}

.lh-1 {
  line-height: 1em !important;
}

//
// FONT-SIZE
//

.font-size-xl {
  font-size: 1.75em !important;
}

.font-size-xxs {
  font-size: 0.75em !important;
}

.font-size-xs {
  font-size: 0.9em !important;
}

.font-size-sm {
  font-size: 1em !important;
}

.font-sm-sm {
  @media (max-width: 767px) {
    font-size: 1em !important;
  }
}

.Gotham {
  font-family: 'Gotham', sans-serif !important;
}

.text-thin {
  font-weight: 400 !important;
}

.text-normal {
  font-weight: 500 !important;
  letter-spacing: 0.05em;
}

.text-medium {
  font-weight: 600 !important;
  letter-spacing: 0.05em;
}

.text-bold {
  font-weight: 600 !important;
}

.text-underline {
  padding-bottom: 1em;
  border-bottom: 1px solid #000;
}

.text-spacegray {
  color: #363636;
}
//
// LETTER-SPACING
//
.ls-xxs {
  letter-spacing: 0.02em;
}
.ls-xs {
  letter-spacing: 0.2em;
}

.ls-sm {
  letter-spacing: 0.5em;
}

//
// Input
//

.input-theme-1 {
  .form-group {
    .form-control {
      color: black;
      padding: 1em;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
}

//Layout
.py-10 {
  padding-top: 5em;
  padding-bottom: 5em;
}

.py-20 {
  padding-top: 10em;
  padding-bottom: 10em;
}

.center-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.center-all {
  position: absolute;
  top: 50%;
  left: 50%;
  width: inherit;
  height: inherit;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.center-m {
  margin: 0px auto;
  text-align: center;
}

.text-xs-c {
  @media (max-width: 576px) {
    text-align: center;
  }
}
.no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.btn-link {
  text-decoration: none !important;
}

.outline {
  border: 1px solid #f0f0f0;
}

.fixed-bottom {
  width: 100%;
  background: #fafafa;
  padding: 10px;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  z-index: 99;
}

//buttons
.btn-hollow {
  border: 1px solid #000;
  background: transparent;
  font-weight: 500;
  letter-spacing: 0.2em;
  font-size: 12px;
  color: #363636;
  display: inline-block;
  position: relative;
  padding: 20px 38px 20px 40px;
  transition: 0.2s ease-in;
  background-clip: border-box;
  cursor: pointer;

  &:hover {
    background-size:
      100% 0,
      100% 100%;
    background-color: #222425;
    border-color: #222425 !important;
    color: #fff !important;
    text-decoration: none;
  }

  &.active {
    background-size:
      100% 0,
      100% 100%;
    background-color: #222425;
    border-color: #222425 !important;
    color: #fff !important;
    text-decoration: none;
  }

  &.white {
    background-size:
      100% 0,
      100% 100%;
    background-color: #fff;
    border-color: #fff !important;
    color: #000 !important;
    text-decoration: none;
  }

  &.light {
    background: none;
    color: white;
    border: 1px solid #fff;

    &:hover {
      background: white !important;
      color: #222425 !important;
      border: 1px solid #ffffff !important;
    }
  }
}
.btn-rounded {
  border-radius: 40px !important;
}
.back-step-btn {
  background: #fff;
  border: 1px solid #ddd !important;
  width: 50px;
  line-height: 50px;
  padding: 0;
  font-family: $font;
  border-radius: 5px !important;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-transform: uppercase;
}

.back-step-btninactive {
  background: #fff;
  border: 1px solid #ddd !important;
  width: 50px;
  line-height: 50px;
  padding: 0;
  font-family: $font;
  border-radius: 5px !important;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-weight: 300;
  display: none !important;
  text-transform: uppercase;
}

//border
.border-bottom {
  border-bottom: 1px solid #ddd;
}

.border-none {
  border: none !important;
}

.center-c {
  margin: 0px auto;
}

//modal-backdrop
.alert-modal {
  .modal-title {
    font-size: 16px !important;
    text-transform: uppercase;
  }
  .modal-body {
    height: auto !important;
    padding-bottom: 3em;
  }

  .modal-footer {
    padding: 0;

    .btn {
      width: 50%;
      display: inline-block;
      padding: 1em 0em !important;
    }
  }
}

.dark-themed {
  background-image: -ms-radial-gradient(
    right bottom,
    ellipse farthest-corner,
    #1c1c1c 0%,
    #000000 100%
  );
  background-image: -moz-radial-gradient(
    right bottom,
    ellipse farthest-corner,
    #1c1c1c 0%,
    #000000 100%
  );
  background-image: -o-radial-gradient(
    right bottom,
    ellipse farthest-corner,
    #1c1c1c 0%,
    #000000 100%
  );
  background-image: -webkit-gradient(
    radial,
    right bottom,
    0,
    right bottom,
    572,
    color-stop(0, #1c1c1c),
    color-stop(100, #000000)
  );
  background-image: -webkit-radial-gradient(
    right bottom,
    ellipse farthest-corner,
    #1c1c1c 0%,
    #000000 100%
  );
  background-image: radial-gradient(
    ellipse farthest-corner at right bottom,
    #1c1c1c 0%,
    #000000 100%
  );
  color: white;
}

.wrapper-sm {
  width: 100%;
  max-width: 700px;
  margin: 0px auto;
}

.z1 {
  z-index: 1;
}

.wrapper-md {
  width: 100%;
  max-width: 900px;
  margin: 0px auto;
  padding: 2em;
}

.wrapper-lg {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 2em;
}

.ml-border--1 {
  .col {
    margin-left: -1px;
  }
}

.hvr-underline-from-left::before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #222 !important;
  height: 2px !important;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.descTitle {
  text-transform: uppercase !important;
  font-weight: 600 !important;
  color: #3e3e3e !important;
  font-size: 14px !important;
}

.descText {
  color: #797979 !important;
  font-size: 13px !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: $font;
}

.btn {
  font-weight: 600;
  border-radius: 3px;
  padding: 0.5rem 0.5rem;
  line-height: 1.25rem;
  font-family: 'Myriad';
  text-transform: capitalize !important;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &.btn-no-capitalize {
    text-transform: none !important;
  }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  background-color: white !important;
  border-color: #0080ff;
  color: #0080ff !important;
}
.badge-city {
  color: white;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa;
  color: black;
}

.btn,
.form-control {
  border-radius: 0;
}

.form-control {
  color: #000;
  padding: 10px;
  font-weight: 500;
  font-size: 16px !important;

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: inherit;
  }

  &::placeholder {
    font-family: $font;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

/*.CalendarDay--blocked-calendar {
  color: #ccc !important;
  background: transparent !important;
}*/

select.form-control {
  padding: 0 10px;
}
