#ServicesStyle {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0px;
  /*============  TEXT STYLES =============*/
  /*============  BUTTON =============*/
  /*============  LAYOUT =============*/
}
#ServicesStyle.fixed {
  transition: all 200ms ease;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden !important;
  z-index: 1 !important;
}
#ServicesStyle .text-link {
  cursor: pointer !important;
  transition: all 200ms ease;
}
#ServicesStyle .text-link:hover {
  opacity: 0.8;
}
#ServicesStyle .hidden-s-down {
  display: inherit;
}
@media (max-width: 700px) {
  #ServicesStyle .hidden-s-down {
    display: none !important;
  }
}
#ServicesStyle .hidden-s-up {
  display: none !important;
}
@media (max-width: 700px) {
  #ServicesStyle .hidden-s-up {
    display: inherit !important;
  }
}
#ServicesStyle .hidden-md-down {
  display: block;
}
@media (max-width: 997px) {
  #ServicesStyle .hidden-md-down {
    display: none !important;
  }
}
#ServicesStyle .hidden-md-up {
  display: none !important;
}
@media (max-width: 997px) {
  #ServicesStyle .hidden-md-up {
    display: block !important;
  }
}
#ServicesStyle .text-red {
  color: #ff5350 !important;
}
#ServicesStyle .container {
  max-width: 1440px;
  margin: 0px auto !important;
  padding-bottom: 5vh;
}
@media (max-width: 1440px) {
  #ServicesStyle .container {
    max-width: 1300px;
    padding: 0px;
    padding-bottom: 5vh;
  }
}
#ServicesStyle .box {
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  padding: 16px;
}
#ServicesStyle .box.blue {
  background: #f4f8fa;
  border-color: #e0e8ec;
}
#ServicesStyle p,
#ServicesStyle b,
#ServicesStyle h1,
#ServicesStyle h2,
#ServicesStyle h3,
#ServicesStyle h4,
#ServicesStyle h5,
#ServicesStyle h6,
#ServicesStyle label,
#ServicesStyle span,
#ServicesStyle small,
#ServicesStyle button,
#ServicesStyle a,
#ServicesStyle td,
#ServicesStyle tr,
#ServicesStyle input {
  font-family: "Myriad", sans-serif;
  margin: 0px;
  line-height: 1.35em;
  text-rendering: optimizeLegibility;
}
#ServicesStyle b {
  font-weight: 800 !important;
}
#ServicesStyle a {
  text-decoration: none !important;
}
#ServicesStyle h1 {
  font-size: calc(1 * 2.488em) !important;
  font-weight: 500;
  color: #0f0f44;
}
@media (min-width: 2000px) {
  #ServicesStyle h1 {
    font-size: calc(1 * 2.488em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h1 {
    font-size: calc(0.8 * 2.488em) !important;
  }
}
@media (max-width: 375px) {
  #ServicesStyle h1 {
    font-size: calc(0.7 * 2.2em) !important;
  }
}
#ServicesStyle h2 {
  font-size: calc(1 * 2.074em) !important;
  font-weight: 600 !important;
  color: #0f0f44;
}
@media (min-width: 2000px) {
  #ServicesStyle h2 {
    font-size: calc(1 * 2.074em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h2 {
    font-size: calc(0.8 * 2.074em) !important;
  }
}
@media (max-width: 375px) {
  #ServicesStyle h2 {
    font-size: calc(0.7 * 2.074em) !important;
  }
}
#ServicesStyle h3 {
  font-size: calc(1 * 1.728em) !important;
}
@media (min-width: 2000px) {
  #ServicesStyle h3 {
    font-size: calc(1 * 1.728em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h3 {
    font-size: calc(0.8 * 1.728em) !important;
  }
}
@media (max-width: 375px) {
  #ServicesStyle h3 {
    font-size: calc(0.7 * 1.728em) !important;
  }
}
#ServicesStyle h4 {
  font-size: calc(1 * 1.44em) !important;
  font-weight: 500;
}
@media (min-width: 2000px) {
  #ServicesStyle h4 {
    font-size: calc(1 * 1.44em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h4 {
    font-size: calc(0.8 * 1.44em) !important;
  }
}
@media (max-width: 375px) {
  #ServicesStyle h4 {
    font-size: calc(0.7 * 1.44em) !important;
  }
}
#ServicesStyle h5 {
  font-size: calc(1 * 1.35em) !important;
  line-height: 1.5em;
}
@media (min-width: 2000px) {
  #ServicesStyle h5 {
    font-size: calc(1 * 1.35em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h5 {
    font-size: calc(0.8 * 1.35em) !important;
  }
}
#ServicesStyle h6 {
  font-size: calc(1 * 1.15em) !important;
  line-height: 1.5em;
}
@media (min-width: 2000px) {
  #ServicesStyle h6 {
    font-size: calc(1 * 1.15em) !important;
  }
}
@media (max-width: 997px) {
  #ServicesStyle h6 {
    font-size: calc(0.8 * 1.15em) !important;
  }
}
#ServicesStyle p {
  font-size: calc(1 * 1em) !important;
  font-weight: 700;
  color: #0f0f44;
  line-height: 1.5em;
}
@media (min-width: 2000px) {
  #ServicesStyle p {
    font-size: calc(1 * 1em) !important;
  }
}
#ServicesStyle label {
  font-size: calc(1 * 1em) !important;
}
#ServicesStyle .text-green {
  color: #62af68 !important;
}
#ServicesStyle .text-gray {
  color: #7b8a9e !important;
}
#ServicesStyle .text-blue {
  color: #1598cb !important;
}
#ServicesStyle .text-darkblue {
  color: #235aa1 !important;
}
#ServicesStyle .purple-700 {
  color: #0f0f44 !important;
}
#ServicesStyle .muted-dark {
  color: #30435c !important;
}
#ServicesStyle .text-muted-link {
  color: #7b8a9e !important;
  cursor: pointer;
  transition: all 200ms ease;
}
#ServicesStyle .text-muted-link:hover {
  color: #363f4a !important;
}
#ServicesStyle .weight-500 {
  font-weight: 500 !important;
}
#ServicesStyle .weight-600 {
  font-weight: 600 !important;
}
#ServicesStyle .weight-700 {
  font-weight: 700 !important;
}
#ServicesStyle .weight-800 {
  font-weight: 800 !important;
}
#ServicesStyle .lh-17 {
  line-height: 1.7em !important;
}
#ServicesStyle .small-label {
  font-size: 12px;
  color: #7b8a9e !important;
  letter-spacing: 0.1ch;
}
#ServicesStyle .num-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e5eefb;
  width: 50px;
  height: 50px;
  padding-top: 4px;
  border-radius: 50%;
  margin: 0px auto;
}
#ServicesStyle button {
  background: #1598cb;
  padding: 16px;
  border-radius: 4px;
  transition: all 200ms ease;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ServicesStyle button.white {
  background: white;
  border: 1px solid #e4e4e4;
}
#ServicesStyle button.white:hover {
  background: white;
  border-color: #d7d7d7;
}
#ServicesStyle button:hover {
  background: #1387b4;
}
#ServicesStyle #inline_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  border: 0.5px solid #e4e4e4 !important;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.05), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07) !important;
}
#ServicesStyle #inline_input input,
#ServicesStyle #inline_input textarea {
  outline: none;
  border: none;
  flex: 1;
  height: 60px;
  padding: 0px;
  background: none;
  font-family: "Myriad", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
}
#ServicesStyle #inline_input input::placeholder,
#ServicesStyle #inline_input textarea::placeholder {
  color: #7b8a9e !important;
}
#ServicesStyle #inline_input button {
  width: auto;
  height: 60px;
  background: #1598cb;
  border: none;
  outline: none;
  border-radius: 0px 4px 4px 0px;
  transition: all 200ms ease;
}
#ServicesStyle #inline_input button:hover {
  background: #1387b4;
}
#ServicesStyle .block_input .block_input--field {
  position: relative;
}
#ServicesStyle .block_input .block_input--field .input--icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#ServicesStyle .block_input .block_input--field input {
  width: 100%;
  padding-left: 35px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4 !important;
  border-radius: 4px;
  background: white;
  box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.05), 0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07) !important;
  height: 50px;
  font-family: "Myriad", sans-serif !important;
  font-weight: 700;
  font-size: 18px;
}
#ServicesStyle .block_input .block_input--field input::placeholder {
  color: #7b8a9e !important;
}
#ServicesStyle .jumbotron {
  background: none;
  margin: 0px;
  padding-top: 100px;
  padding-bottom: 50px;
  padding-right: 0px;
  padding-left: 0px;
}
@media (max-width: 997px) {
  #ServicesStyle .jumbotron {
    padding-bottom: 0px;
  }
}
#ServicesStyle .jumbotron .jumbo-title {
  display: flex;
  flex-direction: row;
  padding: 50px 50px 80px 50px;
  flex: 1;
}
@media (max-width: 979px) {
  #ServicesStyle .jumbotron .jumbo-title {
    padding: 0px 24px 32px 24px;
  }
  #ServicesStyle .jumbotron .jumbo-title .jumbo-title-container {
    max-width: none !important;
    margin: 0px auto;
  }
}
#ServicesStyle .jumbotron .jumbo-title .jumbo-title-container {
  max-width: 680px;
}
#ServicesStyle .jumbotron .jumbo-photo {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 375px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 997px) {
  #ServicesStyle .jumbotron .jumbo-photo {
    min-height: 400px;
  }
}
@media (max-width: 576px) {
  #ServicesStyle .jumbotron .jumbo-photo {
    min-height: 320px;
  }
}
#ServicesStyle .jumbotron .jumbo-photo .jumbo-photo--container {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  padding: 16px;
}
#ServicesStyle .jumbotron .jumbo-photo .jumbo-photo--container .jumbo-press {
  height: calc(100% - 60px);
}
#ServicesStyle .jumbotron .jumbo-photo .jumbo-photo--container .jumbo-press img {
  height: 50px;
  margin: 0em 0.5em;
}
@media (max-width: 576px) {
  #ServicesStyle .jumbotron .jumbo-photo .jumbo-photo--container .jumbo-press img {
    height: 45px;
  }
}
#ServicesStyle .cta-why {
  padding: 16px 0px;
}
#ServicesStyle .cta-why .cta-why--text {
  padding: 100px 50px;
}
@media (max-width: 997px) {
  #ServicesStyle .cta-why .cta-why--text {
    padding: 32px 24px;
  }
}
#ServicesStyle .cta-why .cta-why--photo {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
}
@media (max-width: 767px) {
  #ServicesStyle .cta-why .cta-why--photo {
    background: url("../../img/servicepage/founder-collage-mobile.webp") no-repeat;
    background-size: cover;
  }
}
@media (max-width: 576px) {
  #ServicesStyle .cta-why .cta-why--photo {
    min-height: 100vw;
  }
}
#ServicesStyle .cta-blurb-1 {
  background: #fcfdfd;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
  align-items: "stretch";
}
@media (max-width: 997px) {
  #ServicesStyle .cta-blurb-1 {
    border: none;
    background: #fafafa;
  }
  #ServicesStyle .cta-blurb-1 .cta-text {
    padding: 50px 24px !important;
  }
}
@media (max-width: 700px) {
  #ServicesStyle .cta-blurb-1 {
    text-align: center !important;
  }
}
#ServicesStyle .cta-blurb-1 .cta-text {
  flex: 1;
  width: 100%;
  max-width: 700px;
  padding: 50px;
}
#ServicesStyle .cta-blurb-1 .cta-img {
  position: relative;
  flex: 1;
  align-self: stretch;
  background: url("../../img/servicepage/customersupport.webp");
  background-size: cover;
  background-position: center;
}
@media (max-width: 997px) {
  #ServicesStyle .cta-blurb-1 small {
    text-align: center !important;
  }
}
#ServicesStyle .cta-cities {
  padding: 50px 0px;
  margin-top: 1em;
}
@media (max-width: 997px) {
  #ServicesStyle .cta-cities {
    padding: 0px 16px;
    border-bottom: 1px solid #e4e4e4;
  }
}
#ServicesStyle .cta-cities .cta-cities--map iframe {
  height: 400px;
}
@media (max-width: 700px) {
  #ServicesStyle .cta-cities .cta-cities--map {
    width: 100%;
  }
  #ServicesStyle .cta-cities .cta-cities--map iframe {
    height: 250px;
  }
}
#ServicesStyle .cta-cities .cta-cities-text {
  padding: 80px 16px;
}
@media (max-width: 997px) {
  #ServicesStyle .cta-cities .cta-cities-text {
    padding: 24px 16px;
  }
}
#ServicesStyle .cta-cities .cta-cities-text .grid-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: pre-wrap;
}
#ServicesStyle .cta-cities .cta-cities-text .grid-list .grid-item {
  display: block;
  background: red;
  padding-bottom: 16px;
}
#ServicesStyle .cta-cities .cta-cities-text .grid-list .grid-3-item {
  width: 33.3333333333%;
  padding-bottom: 16px;
  cursor: default !important;
}
@media (max-width: 700px) {
  #ServicesStyle .cta-cities .cta-cities-text .grid-list .grid-3-item {
    width: 50%;
  }
}
#ServicesStyle .cta-conclusion {
  border: 1px solid #e4e4e4;
  background: #fcfdfd;
  padding: 80px 32px;
  border-radius: 4px;
}
#ServicesStyle .cta-conclusion .cta-conclusion-container {
  max-width: 600px;
  margin: 0px auto;
}

#DMenu {
  position: relative;
}
#DMenu.no-highlight .dropdown:hover .btn-secondary {
  background: none !important;
}
#DMenu .dropdown:hover .btn-secondary {
  transition: all 200ms ease;
  border-radius: 4px;
  background: rgba(18, 96, 108, 0.15) !important;
}
#DMenu .dropdown .btn-secondary {
  padding: 0px !important;
  color: inherit !important;
  text-transform: none !important;
  text-align: inherit;
  font-size: inherit;
}
#DMenu .dropdown .dropdown-menu {
  margin-top: -3px;
  -webkit-animation: slideInToTop 200ms ease forwards;
  animation: slideInToTop 200ms ease forwards;
  padding: 0em;
  z-index: 99999;
  border: none;
  box-shadow: 0 12px 60px rgba(10, 10, 10, 0.3);
  width: 100%;
  border-radius: 4px !important;
  overflow: hidden;
  padding: 0.2em;
  min-width: 250px;
}
@keyframes slideInToTop {
  0% {
    opacity: 0;
    margin-top: 25px;
  }
  100% {
    opacity: 1;
    margin-top: -3px;
  }
}
#DMenu .dropdown .dropdown-menu .dropdown-item {
  font-weight: 700 !important;
  padding: 0.75em 1em !important;
  background: white;
  transition: all 200ms ease;
  cursor: pointer;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  transition: all 200ms ease !important;
  z-index: 999999;
}
.ReactModalPortal .ReactModal__Content {
  border: none !important;
  box-shadow: 0 12px 60px rgba(10, 10, 10, 0.3);
  -webkit-animation: slideInToTop 200ms ease forwards;
  animation: slideInToTop 200ms ease forwards;
  width: 98%;
  height: 100%;
  max-width: 800px;
  max-height: 600px;
  z-index: 999999;
}
@media (max-width: 700px) {
  .ReactModalPortal .ReactModal__Content {
    max-height: 95%;
  }
}
.ReactModalPortal .ReactModal__Content p,
.ReactModalPortal .ReactModal__Content b,
.ReactModalPortal .ReactModal__Content h1,
.ReactModalPortal .ReactModal__Content h2,
.ReactModalPortal .ReactModal__Content h3,
.ReactModalPortal .ReactModal__Content h4,
.ReactModalPortal .ReactModal__Content h5,
.ReactModalPortal .ReactModal__Content h6,
.ReactModalPortal .ReactModal__Content label,
.ReactModalPortal .ReactModal__Content span,
.ReactModalPortal .ReactModal__Content small,
.ReactModalPortal .ReactModal__Content button,
.ReactModalPortal .ReactModal__Content a,
.ReactModalPortal .ReactModal__Content td,
.ReactModalPortal .ReactModal__Content tr,
.ReactModalPortal .ReactModal__Content input {
  font-family: "Myriad", sans-serif;
  margin: 0px;
  line-height: 1.35em;
  text-rendering: optimizeLegibility;
}
.ReactModalPortal .ReactModal__Content .close-button {
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  padding: 0px;
  border-radius: 0px;
  background: none !important;
}
.ReactModalPortal .ReactModal__Content .close-button i {
  color: #d3d8df !important;
  transition: all 200ms ease;
}
.ReactModalPortal .ReactModal__Content .close-button:hover i {
  color: #7b8a9e !important;
}
