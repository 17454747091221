// cleaned
$maxWidth: 1600px;
$minxWidth: 1000px;
$thead-bg: #ffffff;

$thead-height: 170px;
$thead-margin-top: 125px;
$border-radius: 5px;

$thead-color: #62646d;
$thead-weight: 500;
$thead-size: 0.85rem;

$tbody-font: 'Myriad', sans-serif;
$tbody-bg: #fff;
$tbody-hover: #f8f9fa;
$tbody-color: #000000;
$tbody-weight: 500;
$tbody-size: 0.9rem;

$row-height: 70px;
$divider: #edeef3;

$mp-bg: #f2f2f2;
$mp-pro-bg: #f2f2f2;
$tab-bg: #fdfdfd;
$tabitem-active: #5db0c6;
$tabitem-inactive: #8c9396;

$slot-minwidth: 200px;
$fieldslot-bg: #fcfcfc;
$fieldslot-border: #f8f9f9;
$fieldslot-hover-bg: #f8f9f9;
$fieldslot-color: #5db0c6;
$fieldslot-color-2: #627490;
$fieldslot-border-left: #32ce64;

$sb-width: 180px;
$sb-width-xs: 60px;
$sb-bg: #000000;
$sb-bg-hover: #333333;
$sb-border: #222222;
$sb-i: #8c9396;
$sb-i-hover: #5db0c6;
$sb-p: rgba(255, 255, 255, 0.5);
$sb-p-hover: rgba(255, 255, 255, 1);

$primary: #000000;
$primary-900: lighten($primary, 10%);
$primary-800: lighten($primary, 20%);
$primary-700: lighten($primary, 30%);
$primary-200: lighten($primary, 80%);
$blue: #1469e6;
$blue-sky: #00b8f4;
$green: #3ecf8e;
$red: #fc4236;
$purple: #6772e5;
$purple-darker: darken($purple, 20%);
$text: #222;
$text-darker: darken($text, 30%);
$font-primary: 'Myriad', sans-serif;
$dark-blue: #2f3441;
$darker-blue: #212531;
$pop: #{$red};
$pop-light: lighten($red, 10%);
$pop-darker: darken($red, 10%);
$pop-dark: darken($red, 20%);
$muted: #a8b1b5;
$green_100: #edffed;
$green_200: #e0ffdf;
$green_300: #66d762;

$teal_100: #e5f8f9;
$teal_200: #d2f6f8;
$teal_300: #39c5cb;

$blue_100: #e7f2fe;
$blue_200: #d7e7f8;
$blue_300: #1469e6;

$purple_100: #f2e4fe;
$purple_200: #ead6fb;
$purple_300: #9c4ae9;

$orange_100: #fde9d3;
$orange_200: #fbdfc1;
$orange_300: #fd8224;

$pink_100: #faeae9;
$pink_200: #fcdbd9;
$pink_300: #ff8880;

$input-bg: #fff;
$input-border-width: '1px';
$input-border-radius: '4px';
$input-border-color: '#E6EBF1';
$input-border-active: #5a6ddf;

.modal-dialog {
  max-width: 600px !important;
}

#adminBooking {
  background: #e6ebf1;
  padding-bottom: 300px;

  .alert {
    &.alert-success {
      background: white;
      color: #7795f8;
      font-family: 'Myriad', sans-serif;
      font-weight: 600 !important;
      border: 1px solid #e0e2ea;
      border-radius: 5px;
      font-size: 16px;
    }
  }

  .common-Button {
    white-space: nowrap;
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 14px;
    -webkit-box-shadow:
      0 4px 6px rgba(50, 50, 93, 0.11),
      0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow:
      0 4px 6px rgba(50, 50, 93, 0.11),
      0 1px 3px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    cursor: pointer !important;

    &:hover {
      color: #7795f8;
      background-color: #fafafa;
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
      -webkit-box-shadow:
        0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
      box-shadow:
        0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }

    &.secondary {
      color: #fff !important;
      background: #6772e5 !important;

      &:hover {
        color: #fff;
        background-color: #7795f8;
      }
    }
  }
  .header {
    background: #222;
    color: white;
    padding: 1em 0em;
    h4 {
      font-size: 18px;
      //font-family: $font-primary !important;
      text-align: center;
      font-weight: 600;
      margin: 0;
      //color: #39C5CB;
    }
  }

  .form-control {
    background: none;
    border: $input-border-width solid $input-border-color;
    //border-bottom: 1px solid #e0e0e0;
    padding: 0.5em;
    font-size: 16px;
    font-family: $font-primary !important;
    background: $input-bg;
    border-radius: 4px;
    font-weight: 400;
    color: #6e7780 !important;
    transition: all 200ms ease;

    &::placeholder {
      font-size: 16px !important;
      font-family: $font-primary !important;
    }

    &:active,
    &:hover,
    &:focus {
      color: black !important;
      border-color: $input-border-active !important;
    }
  }

  select {
    color: #797979;
    font-size: 16px !important;
    font-weight: 300;
    border: $input-border-width solid $input-border-color;
    //border-bottom: 1px solid #ddd;
    -webkit-appearance: none;
    background: url('https://maxcdn.icons8.com/Share/icon/Arrows//angle_down1600.png') right 1em
      center !important;
    background-repeat: no-repeat !important;
    background-size: 12px !important;
    background-color: $input-bg !important;
    transition: all 200ms ease;
    height: 50px;
    padding: 0.5em;

    &:focus {
      color: #000 !important;
      border-color: $input-border-active;
    }
  }
  .title {
    font-family: 'Myriad', sans-serif !important;
    color: #525f7f;
    font-weight: 400;
    font-size: 20px;

    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      color: #fff;
      background: #5a6ddf;
      text-align: center;
      margin-right: 1em;
      border-radius: 50%;
      //display: none;
      i {
        font-size: 15px;
        line-height: 24px;
      }
    }
  }
  .alert-container {
    position: fixed;
    top: 0;
    left: 1rem;
    right: 1rem;
    text-align: center;
    z-index: 10000;
  }
  .alert {
    font-weight: 500;
    //font-size: 0.825rem;
    letter-spacing: 0.5px;
    text-align: center;
  }
  label {
    color: #707084;
    font-family: $font-primary !important;
    font-weight: 600;
    font-size: 1em;
    letter-spacing: normal;
    text-transform: capitalize;
  }
  input:focus,
  select:focus {
    border-color: $input-border-active !important;
    color: #525f7f !important;
  }
  textarea {
    padding: 0.5em;
    font-size: 14px;
    font-family: $font-primary !important;
    background: $input-bg;
    border-radius: 4px;
    font-weight: 400;
    color: #6e7780 !important;
    &:focus {
      color: #000 !important;
    }
  }
  .saveButton {
    width: 100%;
    background: black;
    color: white;
    line-height: 4em;
    text-align: center;
    font-family: 'Gotham', sans-serif;
    font-weight: 500;
    font-size: 0.825em;
    text-transform: uppercase !important;
    cursor: pointer;
  }

  .btn {
    text-transform: uppercase !important;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 2em;
    letter-spacing: 0.02rem;
  }

  .info {
    background: white;
    //padding: 2em;
    border-radius: 5px;
    margin: 1em 0em;
    box-shadow:
      0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    //overflow: hidden;
  }

  .card-head {
    padding: 1em;
    background: #f6f9fc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .card-form {
    padding: 0.5em 1em;
    background: #fff;
  }

  #mainBody {
    width: auto;
    //width: 90%;
    //max-width: 1500px;
    margin: 0px auto;
  }

  .flex-col {
    margin: 0px auto;
    flex-direction: column;
    width: auto !important;
  }
  #leftCol {
    background: #fff;
    min-height: 100vh;
    padding: 2em;
    display: flex;
    flex: 1;
    float: left;
    .wrapper {
      max-width: 600px;
      margin: 0px auto;
    }

    .catalogInfo {
      label {
        display: none !important;
      }
    }
  }

  #rightCol {
    background: #fff;
    min-height: 100vh;
    display: flex;
    padding: 2em;
    .form-group .item {
      font-family: $font-primary !important;
      font-size: 0.825rem !important;
      font-weight: 400 !important;
    }

    .detailsBlock {
      background: white;
      padding: 2em;
      padding-bottom: 1em;
      border-radius: 5px;
      margin: 1em 0em;
      margin-top: 0;
      box-shadow:
        0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }

    .catalogTable {
      background: white;
      padding: 2em;
      padding-bottom: 1em;
      border-radius: 5px;
      margin: 1em 0em;
      margin-top: 0;
      box-shadow:
        0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);

      td,
      th {
        font-family: $font-primary;
        border: none;
        text-align: left;
        background: white;
        font-size: 14px;
        font-weight: 500;
        color: #6b7c93;
      }

      td:nth-child(even) {
        color: black;
      }

      tr {
        border-bottom: 1px solid #f0f0f0;
        &:last-child {
          border: none;
        }
      }
      .price {
        font-weight: 600;
        color: black !important;
      }
    }
  }
}

#tableSearch {
  .form-control {
    border: none !important;
    border-bottom: none !important;
    background-color: #fff !important;
  }

  input {
    padding-left: 50px !important;
    background-image: url('https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic//search1600.png');
    background-position: 15px 20px !important;
    background-repeat: no-repeat;
    background-size: 20px;
  }
}

.hvr-forward {
  transform: perspective(0px) translateZ(0);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

#pagination {
  width: 100%;
  //background: white;
  text-align: center;
}

//New Modal Style
.modal {
  z-index: 1000000000000;
}
.modal-dialog {
  .modal-header {
    h4 {
      font-size: 16px;
    }
  }

  .modal-body {
    background: #fafafa;

    .form-group {
      label {
        color: #808080;
      }

      .form-control {
        -webkit-appearance: none;
        border: none !important;
        background-color: white;
        color: black;
        font-weight: 500 !important;
      }

      select {
        background: url('https://maxcdn.icons8.com/Share/icon/Arrows//angle_down1600.png') right
          center;
        background-repeat: no-repeat;
        background-position: 98%;
        background-size: 10px;
        background-color: white;
      }

      .input-group-addon {
        border: none;
        background: white;
      }
    }
  }

  .modal-footer {
    padding: 0;
    .btn-col {
      border: 0.5px solid #f0f0f0;
      border-top: none;
      border-bottom: none;
      padding: 1em 0em;
      cursor: pointer;

      &.save {
        color: green;
      }

      &.cancel {
        opacity: 0.8;
      }
    }
  }
}

.active.item {
  color: white;
  font-weight: 600 !important;
  border-left: 3px solid #65d3e4;
  background: none !important;
}
