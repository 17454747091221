/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #67b23c;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
//$light-blue: #479DC8;
$dark-blue: #394b64;
$dark-gray: #646b73;

/*** Button Styles ***/
$btn-primary: $light-blue;
$btn-secondary: $light-blue;

.no-style-button {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  font-family: inherit;
  cursor: pointer;
}
/*** Text Styles ***/

.hidden-sm-up {
  display: none;

  @media (max-width: 575px) {
    display: block;
  }
}

.hidden-sm-down {
  display: block;

  @media (max-width: 576px) {
    display: none;
  }
}

.hidden-ll-up {
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
}

.hidden-ll-down {
  display: block;

  @media (max-width: 1025px) {
    display: none;
  }
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
  white-space: pre-wrap;
}

.text-600 {
  font-weight: 600 !important;
}
.text-red {
  color: $red !important;
}
.border-right {
  border-right: 1px dotted #ddd;
}
.flex-start {
  justify-content: flex-start !important;
}
.flex-md-center {
  @media (max-width: 767px) {
    justify-content: center !important;
  }
}
.py-8 {
  padding-top: 5em;
  padding-bottom: 5em;
}

.alert-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $teal;
  border-radius: 50%;
  margin-left: 4px;
  position: absolute;
  margin-top: 3px;
}

.text-black {
  color: black !important;
}

.font-normal {
  font-weight: 500 !important;
}

.blue-text {
  color: $light-blue !important;
}

.border-top {
  border-top: 1px solid #e4e4e4;
}

.border-bottom {
  border-bottom: 1px solid #e4e4e4;
}

.lh-15 {
  line-height: 1.5em !important;
}

.lh-2 {
  line-height: 2em !important;
}

/*** List Styles***/
.no-margin-list {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 16px;
}
.no-list {
  list-style: none !important;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.inline-list {
  li {
    display: inline-block;
  }
}

.styled-list {
  list-style: none;
  li {
    margin-bottom: 1em;
    display: flex;
    align-items: top;

    p {
      flex: 1;
    }

    &::before {
      background-color: $teal;
      border-radius: 50%;
      content: '';
      display: inline-block;
      margin-right: 8px;
      margin-top: 8px;
      height: 10px;
      width: 10px;
    }
  }
}
/*** Media Queries ***/

.align-center {
  align-items: center !important;
}

.hidden-md-down {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.hidden-md-up {
  @media (min-width: 767px) {
    display: none !important;
  }
}

/*** Modal Styles***/
div.modal-dialog.infoModal {
  width: 80% !important;
  max-width: 800px !important;
  height: 100%;
  margin: 0px auto;
  align-items: center;
  display: flex;

  @media (max-width: 567px) {
    width: 95% !important;
  }

  @media (max-width: 320px) {
    width: 95% !important;
  }

  .modal-content {
    padding: 0 !important;
    border: none !important;
    border-radius: 3px !important;
  }
}
