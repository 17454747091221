#ledger {
  #ledgerTable {
    background: white;
    -webkit-box-shadow: 0px 0px 11px -1px rgba(135, 140, 150, 0.44);
    -moz-box-shadow: 0px 0px 11px -1px rgba(135, 140, 150, 0.44);
    box-shadow: 0px 0px 11px -1px rgba(135, 140, 150, 0.44);
    max-width: 100%;

    .ledgerHead {
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.05em;
      background: #222;
      border: none !important;
    }
  }

  .timestamp {
    font-size: 12px;
  }
}
