.modal-dialog {
  max-width: 600px !important;
}

#adminBooking {
  background: #e6ebf1;
  padding-bottom: 300px;
}
#adminBooking .alert.alert-success {
  background: white;
  color: #7795f8;
  font-family: "Myriad", sans-serif;
  font-weight: 600 !important;
  border: 1px solid #e0e2ea;
  border-radius: 5px;
  font-size: 16px;
}
#adminBooking .common-Button {
  white-space: nowrap;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  cursor: pointer !important;
}
#adminBooking .common-Button:hover {
  color: #7795f8;
  background-color: #fafafa;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  -webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
#adminBooking .common-Button.secondary {
  color: #fff !important;
  background: #6772e5 !important;
}
#adminBooking .common-Button.secondary:hover {
  color: #fff;
  background-color: #7795f8;
}
#adminBooking .header {
  background: #222;
  color: white;
  padding: 1em 0em;
}
#adminBooking .header h4 {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin: 0;
}
#adminBooking .form-control {
  background: none;
  border: "1px" solid "#E6EBF1";
  padding: 0.5em;
  font-size: 16px;
  font-family: "Myriad", sans-serif !important;
  background: #fff;
  border-radius: 4px;
  font-weight: 400;
  color: #6e7780 !important;
  transition: all 200ms ease;
}
#adminBooking .form-control::placeholder {
  font-size: 16px !important;
  font-family: "Myriad", sans-serif !important;
}
#adminBooking .form-control:active, #adminBooking .form-control:hover, #adminBooking .form-control:focus {
  color: black !important;
  border-color: #5a6ddf !important;
}
#adminBooking select {
  color: #797979;
  font-size: 16px !important;
  font-weight: 300;
  border: "1px" solid "#E6EBF1";
  -webkit-appearance: none;
  background: url("https://maxcdn.icons8.com/Share/icon/Arrows//angle_down1600.png") right 1em center !important;
  background-repeat: no-repeat !important;
  background-size: 12px !important;
  background-color: #fff !important;
  transition: all 200ms ease;
  height: 50px;
  padding: 0.5em;
}
#adminBooking select:focus {
  color: #000 !important;
  border-color: #5a6ddf;
}
#adminBooking .title {
  font-family: "Myriad", sans-serif !important;
  color: #525f7f;
  font-weight: 400;
  font-size: 20px;
}
#adminBooking .title .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #fff;
  background: #5a6ddf;
  text-align: center;
  margin-right: 1em;
  border-radius: 50%;
}
#adminBooking .title .icon i {
  font-size: 15px;
  line-height: 24px;
}
#adminBooking .alert-container {
  position: fixed;
  top: 0;
  left: 1rem;
  right: 1rem;
  text-align: center;
  z-index: 10000;
}
#adminBooking .alert {
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
}
#adminBooking label {
  color: #707084;
  font-family: "Myriad", sans-serif !important;
  font-weight: 600;
  font-size: 1em;
  letter-spacing: normal;
  text-transform: capitalize;
}
#adminBooking input:focus,
#adminBooking select:focus {
  border-color: #5a6ddf !important;
  color: #525f7f !important;
}
#adminBooking textarea {
  padding: 0.5em;
  font-size: 14px;
  font-family: "Myriad", sans-serif !important;
  background: #fff;
  border-radius: 4px;
  font-weight: 400;
  color: #6e7780 !important;
}
#adminBooking textarea:focus {
  color: #000 !important;
}
#adminBooking .saveButton {
  width: 100%;
  background: black;
  color: white;
  line-height: 4em;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-size: 0.825em;
  text-transform: uppercase !important;
  cursor: pointer;
}
#adminBooking .btn {
  text-transform: uppercase !important;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 2em;
  letter-spacing: 0.02rem;
}
#adminBooking .info {
  background: white;
  border-radius: 5px;
  margin: 1em 0em;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}
#adminBooking .card-head {
  padding: 1em;
  background: #f6f9fc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
#adminBooking .card-form {
  padding: 0.5em 1em;
  background: #fff;
}
#adminBooking #mainBody {
  width: auto;
  margin: 0px auto;
}
#adminBooking .flex-col {
  margin: 0px auto;
  flex-direction: column;
  width: auto !important;
}
#adminBooking #leftCol {
  background: #fff;
  min-height: 100vh;
  padding: 2em;
  display: flex;
  flex: 1;
  float: left;
}
#adminBooking #leftCol .wrapper {
  max-width: 600px;
  margin: 0px auto;
}
#adminBooking #leftCol .catalogInfo label {
  display: none !important;
}
#adminBooking #rightCol {
  background: #fff;
  min-height: 100vh;
  display: flex;
  padding: 2em;
}
#adminBooking #rightCol .form-group .item {
  font-family: "Myriad", sans-serif !important;
  font-size: 0.825rem !important;
  font-weight: 400 !important;
}
#adminBooking #rightCol .detailsBlock {
  background: white;
  padding: 2em;
  padding-bottom: 1em;
  border-radius: 5px;
  margin: 1em 0em;
  margin-top: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
#adminBooking #rightCol .catalogTable {
  background: white;
  padding: 2em;
  padding-bottom: 1em;
  border-radius: 5px;
  margin: 1em 0em;
  margin-top: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
#adminBooking #rightCol .catalogTable td,
#adminBooking #rightCol .catalogTable th {
  font-family: "Myriad", sans-serif;
  border: none;
  text-align: left;
  background: white;
  font-size: 14px;
  font-weight: 500;
  color: #6b7c93;
}
#adminBooking #rightCol .catalogTable td:nth-child(even) {
  color: black;
}
#adminBooking #rightCol .catalogTable tr {
  border-bottom: 1px solid #f0f0f0;
}
#adminBooking #rightCol .catalogTable tr:last-child {
  border: none;
}
#adminBooking #rightCol .catalogTable .price {
  font-weight: 600;
  color: black !important;
}

#tableSearch .form-control {
  border: none !important;
  border-bottom: none !important;
  background-color: #fff !important;
}
#tableSearch input {
  padding-left: 50px !important;
  background-image: url("https://maxcdn.icons8.com/Share/icon/p1em/Very_Basic//search1600.png");
  background-position: 15px 20px !important;
  background-repeat: no-repeat;
  background-size: 20px;
}

.hvr-forward {
  transform: perspective(0px) translateZ(0);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

#pagination {
  width: 100%;
  text-align: center;
}

.modal {
  z-index: 1000000000000;
}

.modal-dialog .modal-header h4 {
  font-size: 16px;
}
.modal-dialog .modal-body {
  background: #fafafa;
}
.modal-dialog .modal-body .form-group label {
  color: #808080;
}
.modal-dialog .modal-body .form-group .form-control {
  -webkit-appearance: none;
  border: none !important;
  background-color: white;
  color: black;
  font-weight: 500 !important;
}
.modal-dialog .modal-body .form-group select {
  background: url("https://maxcdn.icons8.com/Share/icon/Arrows//angle_down1600.png") right center;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 10px;
  background-color: white;
}
.modal-dialog .modal-body .form-group .input-group-addon {
  border: none;
  background: white;
}
.modal-dialog .modal-footer {
  padding: 0;
}
.modal-dialog .modal-footer .btn-col {
  border: 0.5px solid #f0f0f0;
  border-top: none;
  border-bottom: none;
  padding: 1em 0em;
  cursor: pointer;
}
.modal-dialog .modal-footer .btn-col.save {
  color: green;
}
.modal-dialog .modal-footer .btn-col.cancel {
  opacity: 0.8;
}

.active.item {
  color: white;
  font-weight: 600 !important;
  border-left: 3px solid #65d3e4;
  background: none !important;
}
