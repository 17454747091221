// cleaned

/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;
/*** Color Guide ***/
$black: #1f2831;
$white: #f4f9fc;
$gray: #a5acb3;
$teal: #1cb2af;
$green: #23d192;
$blue: #39678e;
$light-blue: #1069c9;
$red: #ee4639;
$dark-blue: #394b64;
$dark-gray: #646b73;
$pop: #6772e5;
$border-color: #e6ebf1;

$s-green: #24b47e;
$s-purple: #6772e5;
$s-blue: #3297d3;
$s-yellow: #e39f48;
$s-red: #d9453d;
/*** Button Styles ***/
$btn-primary: $light-blue;
$btn-secondary: $light-blue;

.ReactVirtualized__Grid {
  outline: none !important;
}

.span-600 {
  font-weight: 600 !important;
  span {
    font-weight: 600 !important;
  }
}

avatar {
  display: inline-flex;
  flex-direction: row;
  width: 50px;
  height: 50px;
  border-radius: 5%;
  //background: #EFF6FF;
  justify-content: center;
  align-content: center;
  align-items: center;
  line-height: 1em;
}

badge {
  padding: 0.5em 1em;
  border-radius: 4px;
  font-family: $font-primary;
  background: $light-blue;
  color: white;
  font-size: 14px;
  font-weight: 600;
  color: white;
  display: inline-block;

  &.muted {
    background: #eff3f7 !important;
    color: #32475c !important;
  }
}

.Toastify {
  font-family: $font-primary !important;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.toastify-text {
  font-family: $font-primary !important;
  font-size: 14px;
  font-weight: 600;
  color: black;
  text-align: center;

  &.white {
    color: white;
  }
}

.mb-bottom {
  li {
    margin-bottom: 0.3em !important;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.border-bottom-none {
  li {
    border-bottom: 1px solid #e4e4e4 !important;

    &:last-child {
      border-bottom-width: 0px;
    }
  }
}

.last-no-border {
  &:last-child {
    border: none !important;
  }
}

.hidden-l-down {
  display: none;

  @media (min-width: 1256px) {
    display: block;
  }
}

.hidden-m-down {
  display: none !important;

  @media (min-width: 1150px) {
    display: flex !important;
  }
}

.bg-white {
  background: white;
}

.text-link {
  color: $pop !important;
  font-weight: 500 !important;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    color: darken($pop, 20%);
  }
}

.text-blue {
  color: #3297d3 !important;
}

.blue-badge {
  background: #eaf7fe !important;
  color: #3898d1 !important;
  border: 1px solid #3898d1;
  vertical-align: middle;
  align-items: center;
  font-weight: 600 !important;
}

.border-all {
  border: 1px solid #e4e4e4 !important;
}
.border-bottom {
  border-bottom: 1px solid #e4e4e4 !important;
}
.border-right {
  border-right: 1px solid #e4e4e4 !important;
}
.weight-600 {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1em !important;
  margin: none !important;
}

.align-top {
  align-items: flex-start;
  vertical-align: top;
}

cbutton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #222;
  cursor: pointer;
  transition: all 200ms ease;

  i {
    color: black;
  }

  &:hover {
    background: #000;
  }

  &.red {
    background: #db1818;
  }

  &.gray {
    background: #b3bac8;
  }
}
.letter-persona {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-radius: 50%;
  background: $light-blue;
  color: white;
  font-weight: 600;
  text-align: center;
  font-family: $font-primary;

  &.icon {
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
    font-weight: 700;
    font-size: 10px;
  }
}

.bg-light {
  background: #f6f9fc;
}

#AdminTaskDetails {
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;
  }

  label {
    font-weight: 600;
    color: #6b7c93 !important;
  }

  .t-photo-gallery {
    .form-group {
      margin: 0 !important;
    }

    .form-control {
      background: #fafafa;
      border: 1px solid $border-color !important;
      padding: 0em !important;
      border-radius: 4px;
    }

    .file-uploader {
      padding: 0.5em;
      //border-bottom: 1px solid $border-color;
    }

    .t-grid {
      .t-grid-item {
        display: inline-block;
        margin: 0.2em;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        overflow: hidden;

        &.sm {
          img {
            width: 60px;
            height: 60px;
          }
        }

        &.empty {
          background: url('../img/empty-photo.jpg');
          background-size: cover;
          background-position: center;
          width: 60px;
          height: 60px;
        }
        &:hover {
          img {
            opacity: 0.6;
          }
        }

        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          object-position: center;
          transition: all 200ms ease;
        }
      }
    }
  }

  .Select.form-control {
    border: none !important;
    padding: 0 !important;

    .Select-control {
      height: 46px;

      .Select-value {
        line-height: 46px;
      }
    }
  }
  input.form-control,
  textarea {
    border: 1px solid #9ca6af;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background: white;
    font-family: $font-primary;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: black;
      border-color: darken(#9ca6af, 10%);
      box-shadow: inset 0 1px #e0e6e8;
    }

    &::-webkit-input-placeholder {
      color: $gray;
      font-family: $font-primary;
    }
  }

  .select-tag-item {
    font-family: $font-primary !important;
    font-weight: 500;
    margin: 0;
    padding: 0.5em;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin: 2px;
  }

  .btn-text {
    color: #6772e5 !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.6;
  }

  .btn {
    font-weight: 600 !important;
    border-radius: 3px;
    transition: all 200ms ease;
    font-family: $font-primary !important;

    svg.feather.feather-plus {
      width: 14px !important;
      height: 14px !important;
    }

    &.btn-pop-blue {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light-blue !important;
      color: white !important;
      padding: 4px 9px 2px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.6;
      text-align: left;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
      word-break: keep-all;
      transition:
        box-shadow 0.08s ease-in,
        color 0.08s ease-in;
      box-shadow:
        0 0 0 1px rgba(50, 50, 93, 0.1),
        0 2px 5px 0 rgba(50, 50, 93, 0.1),
        0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08),
        0 0 0 0 transparent;

      &.lg {
        font-size: 16px;
        font-weight: 600;
        padding: 0.75em 1em;
      }

      &:hover {
        background: darken($light-blue, 5%) !important;
        color: white !important;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &.btn-default {
      justify-content: center;
      align-items: center;
      background: #ffffff !important;
      color: #6772e5 !important;
      padding: 4px 9px 2px;
      background-color: #fff;
      border-radius: 4px;
      color: #525f7f;
      cursor: pointer;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 1.6;
      text-align: left;
      text-decoration: none;
      vertical-align: middle;
      white-space: nowrap;
      word-break: keep-all;
      transition:
        box-shadow 0.08s ease-in,
        color 0.08s ease-in;
      box-shadow:
        0 0 0 1px rgba(50, 50, 93, 0.1),
        0 2px 5px 0 rgba(50, 50, 93, 0.1),
        0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.08),
        0 0 0 0 transparent;

      &:hover {
        background: #fff !important;
        color: darken(#6772e5, 5%) !important;
      }

      &.icon {
        i {
          color: $light-blue !important;
        }

        &:hover {
          i {
            color: darken($light-blue, 15%) !important;
          }
        }
      }
    }
  }

  .badge-red {
    color: #fff !important;
    font-weight: 600 !important;
    background-color: #c73b3b !important;
  }

  .badge-dark {
    color: #6f727d !important;
    font-weight: 600 !important;
    background-color: #e8e9ef !important;
  }

  .badge-green {
    color: #fff !important;
    font-weight: 600 !important;
    background-color: $green !important;
  }

  .card--block {
    border: 1px solid $border-color;
    border-radius: 0px;
    padding: 1em;
  }

  .card-block--border {
    border: 1px solid $border-color;
    border-radius: 5px;
    //overflow: hidden;
    padding: 1em;
    transition: all 200ms ease;

    &.clickable {
      cursor: pointer;
      &:hover {
        border: 1px solid darken($border-color, 10%);
        transition: scale(1.02);
      }
    }
  }

  .t-alert {
    padding: 1em;
    background: #222;

    p {
      font-weight: 600;
      color: white;
      font-size: 16px;
    }

    .ms-Link {
      font-weight: 600;
    }

    .card-block--border {
      padding: 0.5em;
      cursor: pointer;
      transition: all 200ms ease;

      label {
        font-size: 13px;
      }

      &:hover {
        label {
          color: black !important;
        }
      }
    }

    &.completed,
    &.leftSite {
      background-image: linear-gradient(-90deg, #71f5bc 2%, #02ce7b 99%);
    }

    &.assigned,
    &.onSite,
    &.enRoute,
    &.pending,
    &.pendingConfirmation {
      background: #00c6ff; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to left,
        #0072ff,
        #00c6ff
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to left,
        #0072ff,
        #00c6ff
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    &.waitingOnApproval,
    &.waitingOnSchedule,
    &.waitingOnReply {
      background: #fe8c00; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to left,
        #f83600,
        #fe8c00
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to left,
        #f83600,
        #fe8c00
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    &.cancelled,
    &.closed,
    &.customerNoShow,
    &.proNoShow,
    &.needsFollowup {
      background: #eb3349; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to left,
        #f45c43,
        #eb3349
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to left,
        #f45c43,
        #eb3349
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    &.partsShipped,
    &.partsNeeded,
    &.partsRequested,
    &.partsOrdered,
    &.partsReceived {
      background: #a770ef; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to right,
        #fdb99b,
        #cf8bf3,
        #a770ef
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to right,
        #fdb99b,
        #cf8bf3,
        #a770ef
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
  }

  .task-column-side {
    padding: 1em;
  }

  .pro-bar {
    .card-block--border {
      background: #fff;
    }
  }

  .pay-feature {
    background: url('../img/wallpaper-blur.png');
    background-size: cover;
    background-position: center;
    padding: 2em;
    text-align: center;

    .pay-icon {
      img {
        height: 25px;
      }
    }

    label {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7) !important;
    }
    h1 {
      font-size: 32px;
      font-weight: 500;
      color: white;
      line-height: 1.5em;
    }
  }

  .client-bar {
    .card-block--border {
      background: #fff;
    }
  }
}

#sortList {
  .sort-item {
    font-family: $font-primary !important;
    font-size: 16px;
    padding: 0.5em 1em;
    border-radius: 4px;
    margin-bottom: 2px;
    border: 1px solid #e4e4e4;
    cursor: move;
    transition: all 200ms ease;

    &:hover,
    &:focus,
    &:active {
      border-color: $light-blue !important;
    }

    .sort-time {
      padding-right: 18px;
      border-right: 1px solid #e4e4e4;
      margin-right: 1em;
    }

    b {
      font-weight: 600;
      line-height: 1.5em;
    }

    p {
      color: $blue;
    }
  }
}

.pp-modal {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  em,
  b,
  strong {
    font-family: $font-primary !important;
    margin: 0;
    font-weight: 500;
  }

  .modal-body {
    background: white;
    padding: 0;
  }

  .pp-container {
    width: 100%;
    max-width: 90%;
    margin: 0px auto;
  }

  .pay-statement {
    background: #f2f2f2;
    padding: 4em 0em;

    b,
    p {
      font-size: 14px !important;
    }

    .invoice-body {
      max-height: 60vh;
      overflow-y: scroll;
      padding: 1em;
      padding-bottom: 8em;
    }
    .invoice-group {
      background: white;
      padding: 1em;

      .invoice-group--title {
        padding: 0.5em;

        h3 {
          font-weight: 600 !important;
          font-size: 18px;
          color: black;
        }
      }

      .invoice-group--container {
        padding: 0.5em;
      }

      .invoice-item {
        padding: 0.5em 0em;
        border-top: 1px solid $border-color;
        &:first-child {
          border: none;
        }

        b,
        p {
          color: #8898aa !important;
        }

        &.total {
          border-top: 2px solid black;
          b,
          p {
            color: #000 !important;
            font-weight: 600 !important;
          }
        }
      }
    }
  }

  .pp-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 15px;
    border-bottom: 1px solid #e4e4e4;

    .pp-container {
      justify-content: space-between;
    }

    .pp-head--title {
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }

    .pp-head--btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #fafafa;
      transition: all 200ms ease;

      &:hover {
        background: #222;
        i {
          color: white !important;
        }
      }
    }
  }

  .pp-body {
    padding: 1em 15px;
    border-bottom: 1px solid #e4e4e4;
  }

  .pp-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .pp-btn {
    width: 100%;
    border-radius: 4px;
    padding: 1em 1em;
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;

    &.sm {
      //padding: 0.5em;
      font-size: 13px;
      max-width: 150px;
    }

    &.primary {
      background: $light-blue;
      color: white;
    }

    &:hover {
      background: darken($light-blue, 10%);
    }

    &.outline {
      background: white;
      color: $light-blue;
      border: 1px solid $border-color;

      &:hover {
        color: darken($light-blue, 10%);
        border-color: darken($border-color, 10%);
      }
    }
  }
}

#innerJobTabs {
  .nav {
    background: #394b64;
    padding: 1em;

    .nav-item {
      border: none;

      .nav-link {
        color: white;
        font-weight: 600;
        border: none;
        padding: 1em 1.5em;
        border-radius: 5px;
        transition: all 200ms ease;

        &:hover {
          color: rgba(255, 255, 255, 0.5);
        }
        &.active {
          background: #479dc8;
        }
      }
    }
  }
}

.NewProPay {
  padding-top: 2em;
  background: #f6f9fc;
  height: 100%;
  padding-bottom: 10vh;

  @media (max-width: 767px) {
    padding-top: 0;
  }

  .container {
    max-width: 95%;
    margin: 0px auto;

    @media (max-width: 576px) {
      max-width: 100%;
      padding: 1em;
    }
  }

  .npp-header {
    padding: 1em 0em;

    @media (max-width: 767px) {
      background: white;
      border-bottom: 1px solid $border-color;
    }
  }

  .npp-summary {
    background: white;
    padding: 2em 1em;
    border-radius: 5px;
    border: 1px solid $border-color;

    .text-blue {
      color: #143c8e !important;
      line-height: 1.5em;
    }
  }

  .npp-daily {
    margin-top: -1px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    white-space: pre-wrap;

    .npp-daily-item {
      border: 1px solid $border-color;
      padding: 1em;
      margin-left: -1px;
      border-top: 2px solid $border-color;
      flex: 1;
      height: 80px;
      vertical-align: middle;

      label {
        width: 100%;
      }
      &.today {
        border-top-color: $light-blue;
      }
    }

    @media (max-width: 767px) {
      display: block;
      .npp-daily-item {
        width: 100%;
        border-top: 1px solid $border-color;
        border-left: 3px solid transparent;
        display: inline-flex;
        align-items: center;
        height: 60px;

        &.today {
          border-top-color: $border-color;
          border-left-color: $light-blue;
        }
      }
    }
  }

  .dropdown {
    .text {
      font-weight: 500 !important;
      font-size: 28px;

      @media (max-width: 767px) {
        font-size: 24px;
        font-weight: 600;
      }
    }

    .icon {
      font-size: 18px !important;
    }
    .menu {
      .item {
        .text {
          font-weight: 500;
          font-size: 16px;
        }
        &.selected {
          color: black !important;
          border: none !important;

          .text {
            color: $light-blue;
            font-weight: 600 !important;
          }
        }
      }
    }
  }
}

.text-muted {
  color: #868e96 !important;
  span {
    color: #868e96 !important;
  }
}

#LedgerProPay {
  background: white;

  .NewProPay {
    padding-top: 2em !important;
  }

  .ledger-summary {
    padding: 2em 1em;
    display: inline-flex;

    .ledger-sum-item {
      width: 150px;
      //padding-right: 3em;

      label {
        font-size: 14px;
      }
      &:nth-child(1) {
        width: 80px;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6) {
      }
    }
  }

  .checked-icon {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    line-height: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    box-shadow: 0px 0px 0px 3px $border-color inset;
    background-color: white;
    transition: all 200ms ease;
    position: relative;
    z-index: 99;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 3px darken($border-color, 10%) inset;
    }

    i {
      color: transparent;
      font-size: 5px;
      transition-delay: 0.2s;
      z-index: 999;
    }

    &.checked {
      box-shadow: 0px 0px 0px 15px #2b7aed inset;
      transition: all 200ms ease;
      background-color: #2b7aed;

      &:before {
        content: '';
        width: 35px;
        height: 35px;
        border-radius: 35px;
        background: rgba(43, 122, 237, 0.4);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        -webkit-animation: zoomIn 2s ease forwards;
        animation: zoomIn 2s ease forwards;
      }

      @keyframes zoomIn {
        0% {
          opacity: 0;
          width: 0px;
          height: 0px;
        }
        25% {
          opacity: 1;
          width: 35px;
          height: 35px;
        }
        100% {
          opacity: 0;
          width: 50px;
          height: 50px;
        }
      }

      i {
        color: white;
        font-size: 20px;
      }
    }
  }

  .NewProPay {
    padding: 0;
    background: white;
  }
  .container {
    max-width: 100%;
    margin: 0px auto;
    padding: 0 !important;
    background: white;
  }

  .header-row,
  .exact-filters {
    background: white;
  }

  .filterable-table {
    th {
      span {
        padding-right: 0.5em;
        font-weight: 600 !important;
        color: #525f7f;
        font-size: 12px !important;
        line-height: 12px !important;
        white-space: nowrap;
      }
    }

    td {
      span {
        font-weight: 600 !important;
        font-size: 13px !important;
      }

      .filterable {
        cursor: pointer !important;
      }

      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(8) {
        color: black;
        font-weight: 600 !important;
      }

      &:nth-child(4),
      &:nth-child(6) {
        span {
          color: #000 !important;
        }
      }

      &:nth-child(7) {
        color: #3975f6 !important;
        text-decoration: underline;
        text-decoration-style: dotted;
        &:before {
          content: '$';
          font-weight: 600;
          font-family: $font-primary;
          font-size: 12px !important;
        }
      }

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(9) {
        //color: $dark-gray !important;
        //span{font-weight: 500 !important;}
      }
    }

    tbody {
      tr:nth-child(odd) {
        background: #f8fafb;
      }
    }

    .proCreditText {
      span {
        color: #2aa63f;
        &:before {
          content: '+ ';
        }
      }
    }

    .proDueText {
      span {
        color: #8469ea;

        &:before {
          content: '+ ';
        }
      }
    }

    .proOweText {
      span {
        color: #d93017;
      }
    }

    .refundText {
      span {
        color: #e4841b;
      }
    }

    .proDebitText {
      span {
        color: #ff8d21;
        &:before {
          content: '- ';
        }
      }
    }

    .dueText {
      span {
        color: #d93017 !important;
      }
    }
  }
}

.button-purple {
  background-color: #3f367b !important;
  border-radius: 4px !important;
  color: white !important;
  transition: all 200ms ease;
  cursor: pointer;
  border: none !important;
  padding: 1em 1.5em;
  font-weight: 600 !important;
  display: inline-flex;
  font-family: $font-primary !important;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background: darken(#3f367b, 5%) !important;
  }

  &.disabled {
    cursor: not-allowed;
    background: transparentize(#3f367b, 0.5) !important;
  }
}

.button-purple-outline {
  color: black !important;
  border: 1px solid $border-color;
  border-radius: 4px;
  transition: all 200ms ease;
  font-family: $font-primary !important;
  padding: 1em 1.5em;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    border-color: #3f367b;
    color: #3f367b !important;
  }
}

.crm-nana {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    font-weight: 500;
  }

  .text-black {
    color: $black !important;
  }
  .cp-body {
    background: white;
    flex: 1;
  }
  .container {
    max-width: 95% !important;
  }
  .crm-user-head {
    background: #fcfcfc;
    padding-bottom: 2em;
    //border-bottom: 1px solid $border-color;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500 !important;
    }

    .crm-user-pic {
      width: 100px;
      height: 100px;
      border-radius: 100px;
      background: url('../img/user-avatar-2.png');
      background-size: cover;
      background-position: center;
    }

    .pro-summary {
      padding: 1em 0em;

      .ps-item {
        padding-bottom: 0.5em;

        p,
        b {
          font-size: 14px;
        }
      }
    }

    .pro-text {
      &.inactive,
      &.rejected,
      &.terminated {
        color: $red !important;
      }
      &.notServingNow {
        color: #e2872a !important;
      }
      &.active {
        color: #41b370 !important;
      }
      &.applicant,
      &.backgroundScreening {
        color: $blue !important;
      }
    }
    .pro-badge {
      padding: 0.35em;
      border: 1.5px solid $blue;
      color: $blue;
      font-family: $font-primary !important;
      font-weight: 600;
      font-size: 14px;
      text-transform: capitalize;

      &.inactive,
      &.rejected,
      &.terminated {
        border-color: $red;
        color: $red;
      }
      &.notServingNow {
        border-color: #e2872a;
        color: #e2872a;
      }
      &.active {
        border-color: #41b370;
        color: #41b370;
      }
      &.applicant,
      &.backgroundScreening {
        border-color: $blue;
        color: $blue;
      }
    }
  }

  .cp-tabs {
    background: #fcfcfc;
    position: relative;
    display: block;
    transition: all 200ms ease;
    border: none;
    border-bottom: 1px solid $border-color;

    ul {
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;
      display: flex;
      flex-direction: row;

      li {
        list-style: none;
        display: inline-block;
        padding: 0em;
        flex: 1;
        &:first-child {
          padding: 0em;
        }

        .nav-link {
          //padding: 1em 0;
          background: #f8f8f8;
          outline: 1px solid $border-color;
          border-top: 4px solid transparent;
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0.5em 0em;

          font-size: 14px;
          font-weight: 500;
          color: lighten($black, 20%);
          text-align: center;
          position: relative;
          transition: all 200ms ease;
          cursor: pointer;

          &:hover {
            color: $black;
          }
          &.active {
            background: white;
            color: $black !important;
            font-weight: 600;
            border-top: 4px solid $light-blue;

            &:after {
              content: '';
              background: white;
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -1px;
              left: 0;
            }
          }
        }
      }
    }
  }

  .btn-primary {
    background-color: #3f367b !important;
    border-radius: 4px !important;
    color: white !important;
    transition: all 200ms ease;
    cursor: pointer;
    border: none !important;
    padding: 1em 1.5em;

    &:hover {
      background: darken(#3f367b, 5%) !important;
    }
  }

  .crm-profile {
    padding: 2em 1em;
    max-width: 95%;
    margin: 0px auto;

    .user--profile {
      background: white;
      padding: 0em;

      .cp-card {
        max-width: none;
        padding: 0;

        .simple-title {
          display: none;
        }
      }
    }
  }
}

#crm-activity {
  p,
  b,
  label {
    font-family: $font-primary !important;
    font-size: 14px;
  }

  b {
    font-weight: 600;
  }

  label {
    font-weight: 500;
  }

  .activity-item {
    padding: 1em;
  }

  .crm-activity-container {
    max-width: 95%;
    margin: 0px auto;
  }
}

#ProCenter {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  span,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;
  }

  h1 {
    font-size: 36px;
    font-weight: 400;
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
    color: #8898aa;
  }

  .pro-container {
    padding: 10vh 0vh;
    text-align: center;
    min-height: 60vh;
    background: #f4f9fc;
    background-size: cover;
    background-position: center;

    &.apply {
      background: #f4f9fc;
      background-size: cover;

      .container {
        max-width: 800px;
        margin: 0px auto;
        text-align: center;
      }

      .pro-apply-box {
        background: white;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        overflow: hidden;

        .apply-jumbo {
          width: 100%;
          height: 20vh;
          background: url('../img/academy-jum-2.jpg');
          background-size: cover;
          background-position: top;
        }

        .apply-body {
          .apply-img {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            border: 5px solid white;
            background: url('../img/academy-icon.png');
            background-size: cover;
            background-position: center;
            margin: 0px auto;
            margin-top: -5vh;
            box-shadow:
              0 3px 3 0 rgba(50, 50, 93, 0.1),
              0 3px 6px 0 rgba(0, 0, 0, 0.07);
            z-index: 3;
          }

          .apply-text {
            background: white;
            padding: 2em;
            z-index: 1;
            h1 {
              color: black;
            }

            h3 {
              color: black;
            }
          }
        }
      }
    }
  }

  .card-block {
    width: 200px;
    height: 200px;
    border-radius: 5px;
    border: 1px solid #e4e4e4;
    background: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0.2em;
    cursor: pointer;

    @media (max-width: 500px) {
      width: 100%;
    }

    img {
      height: 100px;
      margin-bottom: 1em;
    }

    p {
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.part-loader {
  position: fixed;
  left: 0;
  top: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gray-blurb {
  padding: 1em;
  width: 100%;
  background: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.box-select {
  display: block;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  text-align: left;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition:
    box-shadow 0.08s ease-in,
    color 0.08s ease-in;
  box-shadow:
    0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08),
    0 0 0 0 transparent;

  &.active {
    background: #f4f7fe !important;
  }

  span {
    display: block;
    font-family: $font-primary;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 1.5em;
    transition: all 200ms ease;
  }

  svg {
    opacity: 0;
    margin-right: 1em;
    transition: all 200ms ease;
  }

  &:hover {
    span {
      color: #3297d3;
    }

    svg {
      opacity: 1;
      margin-right: 0em;
      transition: all 200ms ease;
    }
  }
}

.user-layout-container {
  background: white;
  height: 100%;
  padding-bottom: 15vh;

  .user-layout-header {
    background: #eff3f6;
    padding: 1em 0.5em;

    p {
      font-weight: 600 !important;
      font-family: $font-primary;
      color: #1d1e39 !important;
      font-size: 16px;
    }
  }

  .user-layout-box {
    padding: 2em;

    @media (max-width: 767px) {
      padding: 1em;
      padding-top: 3em;
    }

    .user-avatar {
      background: url('../img/user-avatar-2.png');
      background-position: center;
      background-size: cover;
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }

    .form-group label {
      margin-bottom: 0.5em;
      color: #3e3e47 !important;
      font-weight: 600 !important;
    }

    input {
      border: 1px solid #cad1d7 !important;
      border-radius: 0.2em !important;
      -webkit-appearance: none;
      color: #475a71 !important;
      background: white;
      font-family: $font-primary !important;
      font-weight: 500;

      &:hover,
      &:focus,
      &:active,
      &:visited {
        color: black !important;
        border-color: darken(#cad1d7, 20%) !important;
        box-shadow: inset 0 1px #e0e6e8 !important;
      }

      &::-webkit-input-placeholder {
        color: #8898aa !important;
        font-family: $font-primary !important;
      }
    }
  }
}

#smartLock {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  span,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    margin: 0;
  }

  .jumbtotron {
    background-image: url('../img/smart-locks-4.png') !important;
    background-color: white;
    background-size: cover;
    background-position: center;
    //height: 80vh;
    padding-top: 15vh;
    padding-bottom: 5vh;

    &.thermostat {
      background-image: url('../img/smart-thermo-lg.png');
      background-size: cover;
      background-position: center;
    }

    &.camera {
      background-image: url('../img/smart-camera-lg.png');
      background-size: cover;
      background-position: center;
    }

    @media (max-width: 576px) {
      background-image: url('../img/smart-locks-6.png');
      background-size: cover;
      background-position: center;
      text-align: center !important;
      justify-content: center !important;
    }

    .jumbo-price {
      @media (max-width: 576px) {
        margin-top: 2em;
      }
    }
    .pre-line {
      @media (max-width: 576px) {
        text-align: center !important;
        justify-content: center !important;
      }
    }

    h1 {
      font-size: 55px !important;
      font-weight: 600 !important;

      @media (max-width: 576px) {
        font-size: 32px !important;
        text-align: center;
      }
    }

    h3 {
      font-weight: 400 !important;
      font-size: 20px;
      color: #8898aa;

      @media (max-width: 576px) {
        font-size: 16px !important;
      }
    }

    label {
      font-weight: 600;
      margin-left: 0.2em;
    }

    .container--text {
      max-width: 700px;
    }
  }

  .hw {
    position: relative;
    max-width: 600px;
    margin: 0px auto;

    .hw-item {
      padding-bottom: 3em;
      padding-left: 5vw;
      position: relative;

      &::before {
        content: ' ';
        position: absolute;
        left: 2.5px;
        top: 6px;
        display: block;
        width: 1px;
        height: 100%;
        background: #000;
        margin-right: 102px;
      }

      &:last-child {
        &::before {
          content: none !important;
          display: none !important;
        }
      }

      label {
        color: #888 !important;
        font-size: 12px !important;
        line-height: 1 !important;
        text-transform: uppercase !important;
        margin-bottom: 0.5em;

        &::before {
          content: ' ';
          position: absolute;
          left: 0;
          display: block;
          width: 6px;
          height: 6px;
          background: black;
          margin-right: 100px;
        }
      }

      .hw-text {
        position: relative;
        margin-top: 0.5em;

        h3 {
          font-weight: 600 !important;
        }

        p {
          font-size: 15px !important;
        }
      }
    }
  }

  .jumbo-footer {
    background: #fafafa;

    @media (max-width: 576px) {
      background: white;
    }

    .jumbo-footer--item {
      padding: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-weight: 600;
        margin-left: 0.5em;
      }

      @media (max-width: 576px) {
        background: white;
        border-bottom: 1px solid #e4e4e4;
        padding: 1.5em 0em;
      }
    }
  }

  .text-content {
    padding: 10vh 0vh;

    &.footer {
      background: url('../img/jumbo-bg.jpg');
      background-size: cover;
      background-position: center;
    }

    &.bg-pro {
      background: url('../img/stock-lg-4.jpg');
      background-size: cover;
      background-position: center;
    }

    h1 {
      font-size: 36px !important;
      font-weight: 500 !important;

      @media (max-width: 576px) {
        font-size: 28px !important;
      }
    }

    p {
      line-height: 1.75em !important;
    }
  }
}

#fadeInEffect {
  -webkit-animation: fadeInEffect 2s ease forwards;
  animation: fadeInEffect 2s ease forwards;

  @keyframes fadeInEffect {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

#JoinPage {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: $font-primary;
    margin: 0px;
  }

  .container {
    &.sm {
      max-width: 40em;
      margin: 0px auto;
    }
    &.md {
      max-width: 60em;
      margin: 0px auto;
    }
  }

  .section-quote {
    padding: 15vh 0vh;

    .bg-text {
      font-size: 40px;
      font-weight: 500;
    }
    h2 {
      font-weight: 400 !important;
      font-size: 28px;

      @media (max-width: 567px) {
        font-size: 20px;
      }
    }

    p {
      line-height: 1.9em !important;
      color: #767676 !important;
      font-size: 18px !important;
      letter-spacing: 0.025ch !important;
      max-width: 600px;
      margin: 0px auto;

      @media (max-width: 567px) {
        font-size: 16px !important;
      }
    }
  }

  hr {
    width: 100%;
    max-width: 500px;
    border-color: #e4e4e4;
  }

  .bg-filler {
    width: 100%;
    height: 500px;
    background: url('../img/office.jpg');
    background-size: cover;
    background-position: center;
  }

  .section-openings {
    padding: 15vh 0vh;
    text-align: center;

    h2 {
      font-weight: 600 !important;
      font-size: 36px;
      padding: 0.5em 0em;

      @media (max-width: 567px) {
        font-size: 24px;
      }
    }

    p {
      line-height: 1.9em !important;
      color: #767676 !important;
      font-size: 18px !important;
      letter-spacing: 0.025ch !important;
      @media (max-width: 567px) {
        font-size: 16px !important;
        line-height: 1.5em !important;
      }
    }

    .card-block--border {
      border: 1px solid $border-color;
      border-radius: 5px;
      //overflow: hidden;
      padding: 2em !important;
      transition: all 200ms ease;
      font-family: $font-primary;
      text-align: left;
      min-height: 200px;

      .divider {
        text-align: left;
        width: 100%;
        height: 1px;
        background: #e4e4e4;
        max-width: 100px;
        display: block;
        margin: 1em 0em;
      }

      .num-block {
        font-size: 32px;
        font-weight: 400;
      }

      h3 {
        font-weight: 600 !important;
        font-size: 24px;
      }

      p {
        color: #8898aa;
      }

      &.clickable {
        cursor: pointer;
        &:hover {
          border: 1px solid darken($border-color, 10%);
          transition: scale(1.02);
        }
      }
    }
  }

  .section-perks {
    padding: 15vh 0vh;
    text-align: left;

    .perk-item {
      padding: 2em;
    }

    h2 {
      font-weight: 600 !important;
      font-size: 36px;
      padding: 0.5em 0em;

      @media (max-width: 567px) {
        font-size: 24px;
      }
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0.5em;

      @media (max-width: 567px) {
        font-size: 18px;
      }
    }

    p {
      line-height: 1.9em !important;
      color: #767676 !important;
      font-size: 18px !important;
      letter-spacing: 0.025ch !important;

      @media (max-width: 567px) {
        font-size: 16px;
        line-height: 1.5em !important;
      }
    }
  }

  .section-nav {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;

    ul li {
      font-weight: 600;
      padding: 2em 2.5em;
      cursor: pointer;
      transition: all 200ms ease;

      p {
        line-height: 1.9em !important;
        color: #767676 !important;
        font-size: 18px !important;
        font-weight: 500;
        letter-spacing: 0.025ch !important;
      }

      &:hover {
        p {
          color: rgba(0, 0, 0, 0.6) !important;
        }
      }
    }
  }
  .join-jumbo {
    padding-top: 30vh;
    padding-bottom: 25vh;
    background-image: url('../img/group-selfie-3.jpg');
    background-size: cover;
    background-position: top center;

    @media (max-width: 1024px) {
      background-image: url('../img/stock-lg.jpg');
      padding-top: 30vh;
    }

    @media (max-width: 767px) {
      background-image: url('../img/stock-lg.jpg');
      padding-top: 20vh;
      padding-bottom: 25vh;

      h1 {
        font-size: 36px !important;
      }

      h4 {
      }
    }

    h1 {
      font-size: 42px;
      font-weight: 600;
      color: #000;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #8898aa;
      max-width: 500px;
      margin: 0px auto;
    }
  }
}

.modal-backdrop {
  background: rgba(103, 115, 134, 0.9) !important;
}

.partModal {
  &.fullScreen {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    padding: 0px;
    margin: 0px;

    &.modal-dialog {
      min-width: 100vw !important;
      max-width: 100vw;
      max-height: 100vh;
      overflow: hidden;

      .modal-header {
      }

      .modal-body {
        max-height: calc(100vh - 55px - 68px);
        overflow-y: auto;
        padding-bottom: 15vh;
      }

      .modal-content {
        height: 100%;
        width: 100%;
        overflow-y: auto;
      }
    }
  }

  &.sm {
    &.modal-dialog {
      width: 100%;
      max-width: 500px !important;
    }
  }

  &.md {
    &.modal-dialog {
      width: 100%;
      max-width: 700px !important;
    }
  }

  &.modal-dialog {
    width: 100%;
    max-width: 1000px !important;
  }

  .modal-content {
    border: none;
    box-shadow: 0px 0px 32px -8px rgba(75, 94, 110, 0.62);

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    small,
    span,
    button,
    ul,
    li,
    .badge {
      font-family: $font-primary;
      font-weight: 500;
      margin: 0;
    }

    label {
      color: #556b8c !important;
      font-size: 15px;
      letter-spacing: 0.01ch;
    }

    .modal-header {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      padding: 8px;

      .modal-title {
        flex: 1;
        width: 100%;
        text-align: center;
        padding-left: 43px;
        span {
          font-size: 18px !important;
          text-align: center;
        }
      }
    }

    .modal-body {
      background: white;

      .p-blurb {
        margin-bottom: 0.5em;
        label {
          color: #556b8c !important;
          font-size: 15px;
          letter-spacing: 0.01ch;
        }
        p {
          font-style: italic;
          font-size: 14px;
          color: #878787;
        }

        blue {
          display: inline-block;
          color: #607594;
          font-family: inherit;
          font-size: inherit;
          font-style: inherit;
          font-weight: 500;
        }
      }

      .section--title {
        background: #f4f6f9;
        padding: 0.5em;
        border-radius: 4px;
        color: #1e3a5e;
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 1em;
        font-family: $font-primary;
      }
    }

    .form-group {
      label {
        color: #556b8c !important;
        font-size: 15px;
        letter-spacing: 0.01ch;
      }

      .form-control,
      input {
        -webkit-appearance: none;
        background-color: white;
        color: #1e3a5e;
        font-weight: 500 !important;
        border: 1px solid #dde2e9 !important;
        border-radius: 3px;
        font-family: $font-primary;
        font-size: 16px !important;
        height: 45px;
        display: flex;
        align-items: center;
        padding: 8px;
        transition: all 200ms ease;

        &:hover,
        &:focus,
        &:active,
        &:visited {
          box-shadow:
            0 2px 3px 0 rgba(50, 50, 93, 0.1),
            0 0px 1px 0 rgba(0, 0, 0, 0.07);
        }
      }
    }
  }

  .modal-footer {
    font-family: $font-primary !important;
    background: #fff;
    .btn-col {
      font-family: $font-primary !important;
      cursor: pointer;
      transition: all 200ms ease;

      &.save {
        color: #2972af;
        font-weight: 600;
        letter-spacing: 0.02ch;
      }

      &:hover {
        background: #f4f6f9;
      }
    }
  }
}

.section--title {
  background: #f4f6f9;
  padding: 0.5em 1em;
  border-radius: 4px;
  color: #1e3a5e;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 1em;
  font-family: $font-primary;

  &.sm {
    font-size: 15px;
    font-weight: 600;
  }
}

.invoice-editable-table {
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  span,
  button,
  ul,
  li,
  .badge {
    font-family: $font-primary;
    font-weight: 500;
    margin: 0;
    line-height: 1.5em;
  }

  .text-blue {
    color: #06263a !important;
  }

  .ledger-container {
    padding: 0.5em;

    .ledger-text {
      &.paid {
        color: $s-green !important;

        i {
          color: $s-green !important;
        }
      }

      &.refund {
        color: $s-purple !important;

        i {
          color: $s-purple !important;
        }
      }

      &.discount {
        color: $s-blue !important;

        i {
          color: $s-blue !important;
        }
      }
    }
  }

  .m-invoice-btn {
    &.charge {
      background: $s-red;
      -webkit-box-shadow: 0px 10px 19px -11px rgba(0, 0, 0, 0.62);
      -moz-box-shadow: 0px 10px 19px -11px rgba(0, 0, 0, 0.62);
      box-shadow: 0px 10px 19px -11px rgba(0, 0, 0, 0.62);
      transition: all 200ms ease;

      &:hover {
        background: darken($s-red, 8%);
      }
    }

    &.return {
      i {
        color: $s-purple !important;
      }
    }

    &.discount {
      i {
        //color: #D9453D !important
      }
    }
  }

  tbody {
    .invoiceHeader {
      //background: #E9EEF1;
      background: #f4f6f9;
      border-radius: 5px;
      overflow: hidden;

      td {
        font-family: $font-primary;
        color: #1e3a5e;
        font-size: 15px;
        font-weight: 600;
        padding: 1em 0.5em;
      }
    }

    .invoiceRow {
      align-self: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid #d8dde6 !important;

      &:last-child {
        border-bottom: none;
      }

      td {
        font-family: $font-primary;
        font-size: 14px;
        align-self: flex-start;
        align-items: flex-start;
        padding: 1em 0.5em;
        p {
          font-weight: 600 !important;
        }

        label {
          color: #787878 !important;
          font-size: 14px;
          font-weight: 500 !important;
        }
      }

      .invoiceBadge {
        background: #e9eef1;
        color: #06263a;
        font-size: 14px;
        font-weight: 600;
        padding: 0.5em 1em;
        border-radius: 4px;
        display: inline-flex;

        &.paid {
          background: $s-green;
          color: white;
        }

        &.pending {
          background: #e9edf3;
          color: #1e3a5e;
        }
      }
    }
  }
}

.m-invoice-btn {
  font-family: $font-primary !important;
  font-weight: 600 !important;
  transition: all 200ms ease;

  span {
    font-weight: 600 !important;
    left: 0.02ch;
  }

  &.dark {
    background: white !important;

    i {
      color: #1e3a5e !important;
    }
    span {
      color: #1e3a5e !important;
    }

    &:hover {
      background: #fafafa !important;
      color: darken(#1e3a5e, 5%) !important;
    }
  }

  &.refund {
    background: white;

    i {
      color: $s-blue !important;
    }
    span {
      color: $s-blue !important;
    }

    &:hover {
      color: darken($s-blue, 5%) !important;
    }
  }

  &.refund {
    background: white;

    i {
      color: $s-blue !important;
    }
    span {
      color: $s-blue !important;
    }

    &:hover {
      color: darken($s-blue, 5%) !important;
    }
  }

  &.charge {
    background: $s-red;
    span {
      color: white !important;
    }
    i {
      color: white;
    }

    &:hover {
      background: darken($s-red, 5%) !important;
    }
  }

  &.blue {
    background: $s-blue;
    span {
      color: white !important;
    }
    i {
      color: white;
      margin-right: 0.3em;
    }

    &:hover {
      background: darken($s-blue, 5%) !important;
    }
  }

  &.mark {
    background: white;
    i {
      color: $s-purple !important;
    }
    span {
      color: $s-purple !important;
    }

    &:hover {
      color: darken($s-purple, 5%) !important;
    }
  }
}

#new-tabContent {
  #task_notes {
    padding: 2em;
  }
}

#CleaningService {
  label,
  small,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Myriad';
    font-weight: 500;
    margin: 0px;
    line-height: 1em;
  }

  hlink {
    display: inline-flex;
    font-family: 'Myriad';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.4px;
    line-height: 1.1em;
    position: relative;
    height: 40px;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease;

    &:hover {
      &:after {
        height: 20px;
        bottom: 0px;
        background: transparentize(#1a6bc6, 0.8);
      }
    }

    &:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 4px;
      background: #1a6bc6;
      position: absolute;
      bottom: 0px;
      transition: all 200ms ease;
    }
  }

  big {
    font-family: 'Myriad';
    font-size: 90px;
    font-weight: 600;
    line-height: 1.1em;
  }

  md {
    font-family: 'Myriad';
    font-size: 54px;
    font-weight: 600;
    line-height: 1.5em;
  }

  .jumbotron {
    padding-top: 18vh !important;
    padding-bottom: 8vh !important;
    background: white;

    h1 {
      font-size: 2.5rem !important;
      line-height: 1.5em !important;
      letter-spacing: 1px;
      color: #111;
      font-weight: 400;
    }
  }

  .jumbo-bg {
    height: 50vh;
    width: 100%;
    background: url('../img/kitchen.jpg');
    background-size: cover;
    background-position: center;
    border: 1em solid #fff;
  }

  .cs-cleaning {
    padding: 20vh 0vh;

    .cs-img {
      width: 80px;
      height: 80px;
      margin-right: 1em;
      img {
        height: 100%;
      }
    }
    h1 {
      font-size: 3rem !important;
      line-height: 1.5em !important;
      letter-spacing: 1px;
      color: #111;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      line-height: 2em;
      letter-spacing: 0.5px;
    }

    h3 {
      font-size: 20px;
      line-height: 1.5em;
      color: #787878;
      margin-bottom: 1em !important;
    }

    .cs-blurb {
      padding: 1em 0em;
    }

    .cs-blurb-images {
      padding: 0em 1em;
      margin-top: 50px;

      .cs-img {
        background: #f0f0f0;
        width: 100%;
        height: 240px;
        margin-top: -50px;
      }
    }
  }

  .cs-otherServices {
    h1 {
      font-size: 3rem !important;
      line-height: 1.5em !important;
      letter-spacing: 1px;
      color: #111;
      font-weight: 400;
    }

    p {
      font-size: 18px;
      line-height: 2em;
      letter-spacing: 0.5px;
    }
  }

  .os-img {
    width: 100%;
    height: 100%;
    background: url('../img/academy-jum.jpg');
    background-size: cover;
    background-position: center;
    min-height: 300px;
  }

  .os-text {
    max-width: 100%;
    padding: 20vh 4em;
    background: #fafafa;

    md {
      font-size: 40px;
    }
  }
}
