// cleaned
/*** Font Weight ***/
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Myriad', sans-serif;
$thin: 100;
$light: 300;
$book: 400;
$normal: 500;
$medium: 600;
$semi-bold: 700;
$bold: 800;

.fadeInRight {
  animation-duration: 0.4s;
}

div[style='position: relative; z-index: 1050;'] {
  position: inherit !important;
}

d {
  display: block;
}

/***************** USER LAYOUT ***********************/

#ApplicationTracker2 {
  background: none !important;
  //margin-top: -100px;

  h1 {
    font-weight: 400;
  }

  .result-text {
    background: white;
    color: black;
    padding: 1em;
    border-radius: 5px;
  }
  .applicationStep {
    margin-bottom: 1.5em;
    z-index: 2;
    position: relative;

    &.active {
      .tracker-icon {
        border: 1px solid #1dabd0;
        color: #1dabd0;
      }

      .sub-heading-3 {
        color: black !important;
      }
    }

    &.success {
      .tracker-icon {
        border: 1px solid #1cb77a;
        background: #1cb77a;
        color: white;
      }
    }

    .sub-text-2 {
      font-weight: 500;
      //color: black;
    }
    .tracker-icon {
      background: white;
      border-radius: 5px;
      color: #94a2b9;
      line-height: 40px;
      padding: 0;
      text-align: center;
      width: 40px;
      height: 40px;
      border: 1px solid #e0e0e0;
      display: inline-block;
      margin: 0px auto;
      margin-right: 1em;
    }

    .tracker-text {
      line-height: 1.5em;

      .sub-heading-3 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 1rem;
        color: #94a2b9;
      }
    }
  }

  .applicationLine {
    width: 1px;
    z-index: 1;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    top: 0;
    left: 35px;
  }

  .infocards {
    max-width: 90%;
    margin: 0px auto;
    font-weight: 500;
    font-size: 0.9rem;
    .card {
      padding: 1em !important;
    }

    .link {
      color: #1dabd0;
    }
  }
}

#new-tabContent {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
}

#task_notes {
  .form-group {
    background: white;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding: 1em;

    label {
      display: none !important;
    }

    .form-control {
      border: none;
      padding: 0;
    }
  }
  .note_card {
    background: white;
    width: 380px;
    border-radius: 5px;
    display: inline-block;
    margin: 0.5em;
    -webkit-transition: 0.333s ease;
    transition: 0.333s ease;

    @media (max-width: 1120px) {
      width: 100%;
      margin: 0;
      margin-bottom: 1em;
    }
    &:hover {
      -webkit-box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.51);
      -moz-box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.51);
      box-shadow: 0px 0px 13px -4px rgba(0, 0, 0, 0.51);
    }

    p {
      font-family: 'Myriad', sans-serif !important;
      font-weight: 400;
    }

    .note_header {
      border-bottom: 1px solid #f0f0f0;
      height: 60px;
      padding: 0em 1em;
      vertical-align: middle;
      align-items: center;

      .avi {
        margin-right: 0.5em !important;
      }
      img {
        margin-right: 1em;
      }

      .edit_button {
        cursor: pointer !important;
        color: #8e9097;
        transition: color 0.2s ease;

        &:hover {
          color: #136296;
        }
      }
    }

    .note_body {
      padding: 1em;
      height: 270px;
      overflow-y: auto;
      p {
        font-weight: 300;
      }
      @media (max-width: 1120px) {
        height: auto;
      }
    }

    .note_footer {
      padding: 1em;
      border-top: 1px solid #f0f0f0;
      //font-size: 14px;
      color: #8f929a;

      .time_stamp {
        color: #136296;
        margin-right: 0.5em;
      }
    }
  }
}

#privacy {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  small,
  button,
  input {
    font-family: 'Myriad', sans-serif !important;
  }

  h1 {
    font-size: 2.4em !important;
    font-weight: 300 !important;

    @media (max-width: 576px) {
      font-size: 1.8em !important;
    }
  }

  .header {
    text-align: center;
    label {
      color: #8898aa !important;
    }
  }
  .container {
    max-width: 800px;
  }

  .highlight {
    background: white;
    box-shadow:
      0 7px 14px 0 rgba(50, 50, 93, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 0em !important;
    overflow: hidden;

    h2 {
      margin: 0 !important;
      font-weight: 500 !important;
      text-align: center;
      color: #32325d;
      @media (max-width: 576px) {
        font-size: 1em !important;
        font-weight: 600 !important;
      }
    }
    .head {
      padding: 1em;
      background: #f6f9fc;
    }

    .bod {
      padding: 0.5em 1em;
      background: #fff;
    }
  }
  .section,
  .highlight {
    padding: 3em 0em;
    margin-bottom: 2em;

    b {
      color: black !important;
      font-weight: 500 !important;
    }

    p {
      color: #4f4f4f !important;
      line-height: 1.5em !important;
      //font-size: 14px !important;
      margin-left: 0em !important;

      @media (max-width: 576px) {
        font-size: 12px !important;
      }
    }

    h2 {
      font-size: 1.5em;
      margin-bottom: 1em;

      @media (max-width: 576px) {
        font-size: 1em !important;
      }
    }

    h4 {
      font-size: 1.2em;
      margin-bottom: 1em;
      margin-top: 1em;
      font-weight: 600 !important;

      @media (max-width: 576px) {
        font-size: 1em !important;
      }
    }

    .sub-section {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }
}
