.popover.bs-tether-element-attached-right::before,
.popover.popover-left::before,
.popover.bs-tether-element-attached-right::after,
.popover.popover-left::after {
  display: none;
}

.Button {
  background: #000;
  border: none;
  color: white;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0 2rem;
  text-align: center;
  min-height: 3rem;
  line-height: 3rem;
  cursor: pointer;
  position: relative;
  display: inline-block;

  &:hover {
    background: #2b3039;
  }

  .sk-cube,
  .sk-folding-cube .sk-cube:before {
    background: white;
  }

  .sk-spinner {
    width: 1.5rem;
    height: 1.5rem;
  }

  .load {
    position: absolute;
    left: 50%;
    transform: translate(-0.75rem, 0.75rem);
  }
}

.userIconEmpty {
  font-weight: 500;
  text-align: center;
  display: inline-block;
}

.navbar .userIconEmpty {
  color: #fff;
}
