// used for address autocompete dropdown
.pac-container {
  font-family: 'Myriad', sans-serif !important;
  z-index: 9999999999;
  min-width: 300px !important;
  //padding: 1em;

  .pac-icon {
    background-image: url('../../../img/location-symbol.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    border: none;
    opacity: 0.5;
  }

  .pac-matched {
    font-weight: 500 !important;
    color: #000 !important;
    font-size: 14px !important;
  }

  .pac-item {
    line-height: 2em !important;
    padding: 0.5em;
    font-weight: 500 !important;
    font-size: 14px;
    &:first-child {
      border: none !important;
    }
  }

  .pac-icon {
    float: left;
  }

  .pac-item-query {
    display: inline-block !important;
    font-weight: 500 !important;
    color: #9d9da3;
    font-size: 14px;
  }
}

.Select {
  width: 100%;
}
