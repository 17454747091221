.bold-hover {
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.data {
  background: white;
  &.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  }
  .chart {
    td {
      vertical-align: bottom;
      .bar {
        background: #4e54c8; /* fallback for old browsers */
        background: -webkit-linear-gradient(
          to bottom,
          #8f94fb,
          #4e54c8
        ); /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(
          to bottom,
          #8f94fb,
          #4e54c8
        ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
    }
  }

  .full-width {
    max-width: 100%;
    overflow-x: auto;
  }

  table {
    tr:nth-child(1),
    tr:nth-child(2),
    tr:nth-child(3) {
      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

.report2 {
  display: flex;
  //padding: 1em;
  background: #e5ebf3;
  .left {
    width: 100px;
    //background: #182c43;
    //background: white;
    //border: 1px solid #f0f0f0;
    border-radius: 5px;
    margin-right: 1em;
    font-family: 'Gotham', sans-serif;
    font-size: 13px;
    font-weight: 500;
    max-height: 600px;
    color: #516982;
    //border-left: 1px solid white;
    overflow: auto;
    .active {
      color: black !important;
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        color: black !important;
      }
    }

    .pointer {
      cursor: pointer !important;
      transition: all 200ms ease;

      &:hover {
        color: darken(#516982, 40%);
      }
    }
  }
  .right {
    height: 100vh;
    overflow: auto;
    width: 100%;
    position: relative;
    background: white;
    //padding: 1em;
    //padding-bottom: 50px;
    //border-radius: 0.5em;
    border: 1px solid lighten(#516982, 40%);
  }
  .f4 {
    font-weight: 400;
  }
  .f5 {
    font-weight: 500;
  }
  .blue {
    color: #2980b9;
  }
  .green {
    color: #27ae60;
  }
  .purple {
    color: #8e44ad;
  }
  .fa-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
  }
}

.report_container {
  max-height: 100vh;
  overflow-y: hidden;
  .top {
    background: #f6f9fe;
    width: 100%;
    height: 56px;
    padding: 0em 1em;
    h5 {
      font-family: 'Gotham', sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin: 0;
      padding: 0;
      line-height: 56px;
    }
  }
}

#table_v2 {
  $text-muted: #6c757d;
  padding-bottom: 8em;
  display: block;
  float: none;
  position: relative;
  min-height: 80vh;
  * {
    font-family: 'Karla', sans-serif !important;
  }

  .table {
    padding-bottom: 50vh;
    height: 100%;
  }
  thead {
    tr {
      background: white;
      color: inherit;
      text-align: center;

      td {
        border: none;

        &.month {
          color: darken($text-muted, 20%);
          padding-top: 1em;
          &.Sat,
          &.Sun {
            color: $text-muted !important;
          }

          &.Sun {
            border-left: 1px dashed $text-muted;
          }
        }

        &.week {
          text-transform: uppercase;
          font-size: 12px;
          color: darken($text-muted, 20%);
          padding-bottom: 1em;
          &.Sat,
          &.Sun {
            color: $text-muted !important;
          }

          &.Sun {
            border-left: 1px dashed $text-muted;
          }
        }
        &.day {
          font-size: 18px;
          color: black;

          &.Sat,
          &.Sun {
            color: $text-muted !important;
          }

          &.Sun {
            border-left: 1px dashed $text-muted;
          }
        }
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid #f0f0f0;

      &:nth-child(even) {
        background: #f2f5f7 !important;
      }

      &:hover {
        background: inherit;
      }
    }
    td {
      background: none;
      border: none;
      text-align: center;
      text-transform: capitalize;
      padding: 1em 2em;
      &.Sat,
      &.Sun {
        //background: #fafafa;
      }

      &.Sun {
        border-left: 1px dashed $text-muted;
      }
      .gray {
        color: $text-muted !important;
      }
      .red {
        color: #dc3b3b;
      }

      .badge {
        display: block;
        background: #f2f2f2;
        color: black;
        border-radius: 5px;
        padding: 0.5em;
        margin-top: 0.5em;
      }
    }
  }
}

#reports {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  label {
    font-size: 12px;
  }

  .max-min-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    margin-left: 1rem;
    cursor: pointer;
    //position: absolute;
    //top: 10;
    //right: 1%;
    &:hover {
      background: rgba(0, 0, 0, 1);
    }

    &.theme {
      right: 5%;
    }
  }

  .lastupdate {
    //margin-right: 150px;
  }
  /***** FORM GROUP STYLES *********/

  .form-group label {
    color: #96a5b5 !important;
    text-transform: uppercase;
    font-size: 13px;
  }
  .form-group {
    width: 100%;
    //max-width: 200px;
    float: left;
    display: inline-block;
    margin-right: 0.5em;
  }
  input.form-control {
    font-family: 'Karla', sans-serif;
    font-size: 16px !important;
    border: 1px solid #e0e7ee;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background: white;

    &:hover {
      border-color: darken(#e0e7ee, 10%);
    }

    &:focus,
    &:active,
    &:visited {
      color: black;
    }
  }

  select.form-control {
    font-family: 'Karla', sans-serif;
    font-size: 16px !important;
    border: 1px solid #e0e7ee;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background-image: url('../../../img/down-angle.svg');
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 12px;
    &:hover {
      border-color: darken(#e0e7ee, 10%);
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    font-family: 'Karla', sans-serif;
  }

  .light,
  .dark {
    //padding: 3em;
    border: none;
  }

  .dark {
    $darker: #141318;
    $dark: #20202a;
    $border: rgba(255, 255, 255, 0.2);

    background: $dark !important;

    #table_v2 {
      $text-muted: #fff;
      padding-bottom: 8em;
      display: block;
      float: none;
      position: relative;
      * {
        font-family: 'Karla', sans-serif !important;
      }

      .table {
        padding-bottom: 50vh;
      }
      thead {
        tr {
          background: $dark;
          color: inherit;
          text-align: center;

          td {
            border: none;

            &.month {
              color: darken($text-muted, 20%);
              padding-top: 1em;
              &.Sat,
              &.Sun {
                color: $text-muted !important;
              }

              &.Sun {
                border-left: 1px dashed $border;
              }
            }

            &.week {
              text-transform: uppercase;
              font-size: 12px;
              color: darken($text-muted, 20%);
              padding-bottom: 1em;
              &.Sat,
              &.Sun {
                color: $border !important;
              }

              &.Sun {
                border-left: 1px dashed $border;
              }
            }
            &.day {
              font-size: 18px;
              color: #fff;

              &.Sat,
              &.Sun {
                color: $text-muted !important;
              }

              &.Sun {
                border-left: 1px dashed $border;
              }
            }
          }
        }
      }
      tbody {
        tr {
          border-top: none;
          background: $dark;
          transition: all 200ms ease;
          &:nth-child(odd) {
            background: $darker !important;
          }

          &:nth-child(even) {
            background: $dark !important;
          }

          &:hover {
            &:nth-child(odd) {
              background: darken($darker, 10%) !important;
            }

            &:nth-child(even) {
              background: darken($dark, 2%) !important;
            }

            td {
              .red {
                color: #ff5555;
                text-shadow: 0px 0px 1px #ff5555;
              }
              .green {
                color: #8de044;
                text-shadow: 0px 0px 1px #a0e031;
              }
            }
          }
        }
        td {
          background: none;
          border: none;
          text-align: center;
          text-transform: capitalize;
          padding: 1em 2em;
          &.Sat,
          &.Sun {
            //background: #fafafa;
          }

          &.Sun {
            border-left: 1px dashed $border;
          }
          .gray {
            color: #ddd !important;
          }
          .red {
            color: #dc3b3b;
          }

          .badge {
            display: block;
            background: rgba(255, 255, 255, 0.2);
            color: white;
            border-radius: 5px;
            padding: 0.5em;
            margin-top: 0.5em;
          }
        }
      }
    }
  }
}
