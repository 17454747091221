// cleaned
@import '../css/var.scss';
$font-primary: 'Myriad', sans-serif;
$font-secondary: 'Gotham', sans-serif;

.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline;
}

.Select-menu-outer {
  z-index: 999999 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px !important;
  }
}

.q-title {
  text-align: center;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 2rem;
  color: #989898;
  i {
    color: #737e91;
    margin-right: 0.5em;
  }

  @media (max-width: 414px) {
    padding: 0;
    font-size: 12px;
    line-height: 1.2rem;
  }
}

.form-group .item {
  line-height: 1em;
  padding: 15px 0px;
  border: 1px solid #f0f0f0;
  margin-top: -1px;
  background: #fff;
  display: block;
  cursor: pointer;
}
.form-group .item .label {
  text-transform: none !important;
  padding-left: 1.25rem;
  padding-top: 0.4rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 14px;
  color: #666 !important;
  font-family: $font-primary;
}
.form-group .item:hover {
  background: #f6f6f6;
  -webkit-transition: 0.333s ease;
  transition: 0.333s ease;
}
.form-group .item img {
  height: 24px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}

/**************** Form Group Styles *********************/
.check .form-control,
.radio .form-control {
  border: 1px solid #f0f0f0;
  border-top: 0;
}

.form-group {
  .item {
    font-weight: 500 !important;
    font-size: 15px !important;
    color: #000000 !important;
    font-family: $font-primary;
    letter-spacing: 0.02em !important;
    line-height: 2em;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: -1px;
    background: #fff !important;
    display: block;
    text-transform: capitalize !important;
    cursor: pointer;

    @media (max-width: 414px) {
      font-size: 0.825rem !important;
    }

    @media (max-width: 320px) {
      font-size: 0.8rem !important;
    }

    &:hover {
      background: #f6f6f6 !important;
      -webkit-transition: 0.333s ease !important;
      transition: 0.333s ease !important;
    }
    img {
      height: 24px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .label {
    color: #7b7d88 !important;
    font-family: $font-primary !important;
    font-weight: 500;
    line-height: 2.5em !important;
    letter-spacing: normal;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
label {
  color: #000 !important;
}
