$font: 'Myriad', sans-serif;
$font-2: 'Myriad', sans-serif;
$bg: #f7fafc;
$text: #878787;
$bold: #2e3b48;
$muted: #9499a3;
$less-muted: #798192;
$border: #e3e8ee;
$blue: #1a6bc6;
$r-blue: #1558a3;
$c-black: #2e3c49;
$c-green: #40d78f;
$c-gray: #dfe2e8;
$c-red: #e90f09;
$c-yellow: #fcd240;
$c-pink: #e84f89;
$c-orange: #fd9926;
$c-purple: #8e69ec;
$c-teal: #34cbdb;
$c-blue: #2453d5;
$c-darkBlue: #021635;
$c-royalBlue: #0f183e;
$c-lightBlue: #5195ff;

$scale-xl: 1.1;
$scale-lg: 1;
$scale-md: 1;

#Login2019 {
  .rounded-btn {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #eceff1;
    transition: all 200ms ease;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0px;
    &.sm {
      width: 25px;
      height: 25px;
    }

    &:hover {
      background: darken(#eceff1, 10%);
    }

    &.green {
      background: $c-green;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-green, 5%);
      }
    }

    &.red {
      background: $c-red;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-red, 5%);
      }
    }

    &.orange {
      background: $c-orange;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($c-orange, 5%);
      }
    }

    &.blue {
      background: $r-blue;
      i {
        color: white !important;
      }

      &:hover {
        background: darken($r-blue, 5%);
      }
    }
  }

  .text-blue {
    font-weight: 500;
    color: $c-blue !important;
  }
  @-webkit-keyframes fadeInA {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .fadeInAnim {
    animation: fadeInA ease 400ms;
    -webkit-animation: fadeInA ease 400ms;
    -moz-animation: fadeInA ease 400ms;
    -o-animation: fadeInA ease 400ms;
    -ms-animation: fadeInA ease 400ms;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a {
    font-family: $font;
    font-weight: 500;
    line-height: 1.5em;
    margin: 0px;
    text-rendering: optimizeLegibility;
  }

  h1 {
    font-size: calc(#{$scale-lg} * 3.052em);
    font-weight: 500;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 3.052em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 3.052em);
    }
    @media (max-width: 540px) {
      font-size: 34.84px;
    }
  }

  h2 {
    font-size: calc(#{$scale-lg} * 2.441em);
    font-weight: 500;
    margin-bottom: 18px;
    color: #2e3b48;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 2.441em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 2.441em);
    }

    @media (max-width: 540px) {
      font-size: 29.03px;
    }
  }

  h3 {
    font-size: calc(#{$scale-lg} * 1.953em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.953em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.953em);
    }

    @media (max-width: 540px) {
      font-size: 24.19px;
    }
  }

  h4 {
    font-size: calc(#{$scale-lg} * 1.763em);
    font-weight: 500;
    color: $bold;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.763em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.763em);
    }

    @media (max-width: 540px) {
      font-size: 20.16px;
    }
  }

  h45 {
    font-family: $font;
    font-size: calc(#{$scale-lg} * 1.45em);
    font-weight: 500;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.45em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.45em);
    }

    @media (max-width: 540px) {
      font-size: 20.16px;
    }
  }

  h5 {
    font-size: calc(#{$scale-lg} * 1.25em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1.25em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1.25em);
    }

    @media (max-width: 540px) {
      font-size: 18px;
    }
  }

  p {
    font-size: calc(#{$scale-lg} * 1em);
    color: $text;
    font-weight: 500;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 1em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 1em);
    }
  }

  label {
    font-size: calc(#{$scale-lg} * 0.8em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 0.8em);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 0.8em);
    }
  }

  ulabel {
    font-family: $font;
    font-size: calc(#{$scale-lg} * 14px);
    color: #2e3b48;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08ch;

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 14px);
    }

    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 14px);
    }

    &.sm {
      font-size: calc(#{$scale-lg} * 10px);
      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 10px);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 10px);
      }
    }
  }

  small {
    font-size: calc(#{$scale-lg} * 0.64em);

    @media (min-width: 2000px) {
      font-size: calc(#{$scale-xl} * 0.64em);
    }
    @media (max-width: 1280px) {
      font-size: calc(#{$scale-md} * 0.64em);
    }
  }

  a {
    color: $blue;
    font-weight: 600;
    transition: all 200ms ease;
    cursor: pointer;

    &:hover {
      color: darken($blue, 15%);
    }
  }
}

.login--page {
  //padding-top: 100px;
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../../img/login-bg.jpg');
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat !important;

  .login--container {
    height: calc(100vh - 100px);
    width: 100%;
    max-width: 460px;
    padding: 0.5em;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (min-width: 2000px) {
      max-width: calc(#{$scale-xl} * 350px);
    }

    .login--block {
      width: 100%;
      text-align: center;
      background: white;
      padding: 4em 1em;

      @media (min-width: 2000px) {
        padding-top: calc(#{$scale-xl} * 4em);
        padding-bottom: calc(#{$scale-xl} * 4em);
        padding-left: calc(#{$scale-xl} * 1em);
        padding-right: calc(#{$scale-xl} * 1em);
      }
    }

    .login--form--icon {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;

      .form-icon {
        position: absolute;
        right: 8px;
      }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input {
      width: 100%;
      border-radius: 2px;
      //background: #dbdae3;
      //background: var(--eds-ui-300, #dbdae3);
      padding: 16px;
      outline: none;
      position: relative;
      -webkit-transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
      transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
      letter-spacing: 0px !important;
      color: #39364f;
      font-family: $font !important;
      font-size: calc(#{$scale-lg} * 16px) !important;
      outline: none;
      border: 1px solid $border;

      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 16px) !important;
        padding: calc(#{$scale-xl} * 16px);
      }

      &:disabled {
        background-color: #f8f7fa !important;
        color: $muted !important;
        border-color: #e3e8ee !important;
      }

      &::placeholder {
        color: lighten($muted, 10%) !important;
      }

      &:hover {
        color: darken(#39364f, 10%);
        border-color: darken($border, 10%);
      }

      &:active,
      &:focus {
        color: $bold;
        border-color: darken($border, 10%);
      }
    }

    button {
      background: $c-blue;
      padding: 16px;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      font-family: $font !important;
      font-size: calc(#{$scale-lg} * 1.2em);
      color: white;
      font-weight: 800 !important;
      outline: none;
      border: none;
      -webkit-transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);
      transition: background 0.24s cubic-bezier(0.4, 0, 0.3, 1);

      @media (min-width: 2000px) {
        font-size: calc(#{$scale-xl} * 1.2em);
      }

      @media (max-width: 1280px) {
        font-size: calc(#{$scale-md} * 1.2em);
      }

      &:hover {
        background: darken($c-blue, 5%);
      }

      &.fb,
      &.google {
        padding: 0px 8px;
        height: 41px;
        background: white;
        border: 1px solid #dee1e5;
        color: #5c6b7e;
        font-weight: 600;
        box-shadow:
          0px 1px 3px 0px rgba(0, 0, 0, 0.2),
          0px 1px 1px 0px rgba(0, 0, 0, 0.14),
          0px 2px 1px -1px rgba(0, 0, 0, 0.12);

        @media (min-width: 2000px) {
          height: calc(#{$scale-xl} * 41px);
          padding: 0px 16px;
        }

        &:hover {
          //background: $bg;
          //border-color: darken(#dee1e5, 5%);

          span {
            color: $bold !important;
          }
        }
      }

      &.fb {
        i {
          color: #4267b2;
        }
      }
      &.google {
        i {
          color: #c63d2d;
        }
      }

      /*&.fb{
          background-color: #4267B2;
          border-color: #4267B2;

          &:hover{
            background: darken(#4267B2, 5%);
            border-color: darken(#4267B2, 5%);
          }
        }

          &.google{
            background-color: #C63D2D;
            border-color: #C63D2D;

            &:hover{
              background: darken(#C63D2D, 5%);
              border-color: darken(#C63D2D, 5%);
            }
          }
        */
    }

    .react-code-input {
      display: flex !important;
      justify-content: space-between;
      font-family: $font !important;

      input {
        width: 100% !important;
        flex: 1 !important;
        color: #39364f !important;
        font-weight: 500;
        letter-spacing: 0.05ch;
        padding: 0px !important;
        font-family: $font !important;
        font-weight: 600;
        text-align: center;
        font-size: calc(#{$scale-lg} * 1em) !important;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        margin: 0;

        &::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          -moz-appearance: none !important;
          appearance: none !important;
          margin: 0;
        }

        @media (min-width: 2000px) {
          font-size: calc(#{$scale-xl} * 1em) !important;
          padding: calc(#{$scale-xl} * 16px) !important;
        }
      }
    }
  }
}
