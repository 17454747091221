.resetpassword {
  background: #fff;
  text-align: left;
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 10em;

  @media (max-width: 320px) {
    margin-bottom: 18em;
  }
  label {
    color: #7b7d88 !important;
    font-family: 'Myriad', sans-serif !important;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 11px;
  }

  .reset-timeline {
    width: 100%;
    height: 2px;
    margin-bottom: 3em;
    background: #b9b9b9;

    .reset-step-1 {
      background: #28a745;
      width: 50%;
      height: 2px;
    }
    .reset-step-2 {
      background: #28a745;
      width: 100%;
      height: 2px;
    }
  }
}
