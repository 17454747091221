$loaderSize: 32px;
$dotColor: #133d36;
$dotSize: 8px;
$displacement: $dotSize * 3;
$transition: 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;

#Loader {
  background: transparentize(#f2f2f2, 0.1);
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn cubic-bezier(0.77, 0, 0.175, 1) ease-in;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .center {
    width: 100px;
    display: flex;
    justify-content: center;
  }

  .loader-container {
    text-align: center;

    .loader-title {
      width: 100px;
      text-align: center;
      margin-top: 1em;
      font-family: 'Myriad', 'Helvetica', sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.2ch;
      text-transform: uppercase;
      color: #878787;
      animation: titleAnimation $transition;

      @keyframes titleAnimation {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        50% {
          opacity: 0.1;
          transform: scale(1);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .loader {
      height: $loaderSize;
      width: $loaderSize;
      animation: rotate $transition;

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: auto;
        margin: auto;
        width: $dotSize;
        height: $dotSize;
        background: $dotColor;
        border-radius: 50%;
        animation: d1 $transition;

        @keyframes d1 {
          0% {
            transform: translate3d(0, 0, 0) scale(1);
          }
          50% {
            transform: translate3d($displacement, 0, 0) scale(0.5);
          }
          100% {
            transform: translate3d(0, 0, 0) scale(1);
          }
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: auto;
        bottom: 0;
        right: 0;
        margin: auto;
        width: $dotSize;
        height: $dotSize;
        background: $dotColor;
        border-radius: 50%;
        animation: d2 $transition;

        @keyframes d2 {
          0% {
            transform: translate3d(0, 0, 0) scale(1);
          }
          50% {
            transform: translate3d(-$displacement, 0, 0) scale(0.5);
          }
          100% {
            transform: translate3d(0, 0, 0) scale(1);
          }
        }
      }

      span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: $loaderSize;
        width: $loaderSize;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: auto;
          right: 0;
          margin: auto;
          width: $dotSize;
          height: $dotSize;
          background: $dotColor;
          border-radius: 50%;
          animation: d3 $transition;

          @keyframes d3 {
            0% {
              transform: translate3d(0, 0, 0) scale(1);
            }
            50% {
              transform: translate3d(0, $displacement, 0) scale(0.5);
            }
            100% {
              transform: translate3d(0, 0, 0) scale(1);
            }
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: auto;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          width: $dotSize;
          height: $dotSize;
          background: $dotColor;
          border-radius: 50%;
          animation: d4 $transition;

          @keyframes d4 {
            0% {
              transform: translate3d(0, 0, 0) scale(1);
            }
            50% {
              transform: translate3d(0, -$displacement, 0) scale(0.5);
            }
            100% {
              transform: translate3d(0, 0, 0) scale(1);
            }
          }
        }
      }
    }
  }
}
