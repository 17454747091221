$font: 'Myriad', sans-serif;
$font-2: 'Myriad', sans-serif;
$bg: #f7fafc;
$text: #878787;
$bold: #2e3b48;
$muted: #9499a3;
$less-muted: #798192;
$border: #e3e8ee;
$blue: #1a6bc6;
$r-blue: #1558a3;
$c-black: #2e3c49;
$c-green: #40d78f;
$c-gray: #dfe2e8;
$c-red: #e90f09;
$c-yellow: #fcd240;
$c-pink: #e84f89;
$c-orange: #fd9926;
$c-purple: #8e69ec;
$c-teal: #34cbdb;
$c-blue: #2453d5;
$c-darkBlue: #021635;
$c-royalBlue: #0f183e;
$c-lightBlue: #5195ff;

$scale-xl: 1;
$scale-lg: 0.9;
$scale-md: 0.8;

#report-chart {
  box-shadow:
    0px 8px 28px rgba(8, 24, 76, 0.08),
    0px 0px 8px rgba(0, 13, 52, 0.1) !important;
  border-radius: 4px !important;
  padding: 0px !important;
  border: 1px solid $border;
  margin-bottom: 1em;
  position: relative;
  height: 400px;
  overflow: hidden;
  .report-chart-nav {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 16px;
    border-bottom: 1px solid $border;
    background: white;
  }

  .report-chart-body {
    height: 100%;
    padding-top: 32px;
    width: 100%;
    overflow-x: auto;
  }

  .form-group {
    select {
      padding: 0px 2px !important;
      padding-right: 8px !important;
      height: 25px !important;
      margin: 0;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 0px;
      border-bottom: 2px solid $border !important;
      font-family: $font;
      font-size: 16px !important;
      font-weight: 700;
      color: #3366d6 !important;
      background: #fff;
      border: none;
      outline: none;
      display: inline-block;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;

      &:hover {
        background: #f6f6f6;
        border-bottom-color: #4670b2 !important;
      }
    }
  }

  .recharts-cartesian-axis-tick {
    font-size: 0.8rem;
    font-family: $font !important;
    font-weight: 700;
  }

  .report-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $font;
    font-weight: 700 !important;
    font-size: 16px;
    color: #3366d6 !important;
    padding: 0.3em 0.5em;
    border: 1px solid $border;
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms ease;

    &:hover {
      background: #f8f9fc;
    }
  }
}

.table_container_body {
  width: inherit !important;
  box-shadow:
    0px 8px 28px rgba(8, 24, 76, 0.08),
    0px 0px 8px rgba(0, 13, 52, 0.1) !important;
  border-radius: 10px !important;
  padding: 0px !important;
  border: 1px solid $border;
  margin-bottom: 2em;

  &.break-all {
    word-break: break-all;

    table {
      table-layout: inherit !important;
      border-collapse: collapse !important;
      width: 100%;
    }
  }

  table {
    margin: 0px !important;

    td,
    th {
      text-rendering: optimizeLegibility;
      border: 1px solid #e3e8ee !important;
      min-width: 50px;
      max-width: 300px;
      position: relative !important;
      background: inherit;
    }
  }
}

.reports-4 {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: auto;
  background: #fff;
  position: relative;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  span,
  small,
  button,
  a,
  td,
  tr {
    font-family: $font;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0px;
    text-rendering: optimizeLegibility;
  }

  .table {
    thead,
    tbody,
    th,
    td {
      font-family: $font;
      font-size: 14px;
      font-weight: 700 !important;
      transition: all 200ms ease;
      text-align: left;
      padding: 0.5em;
      z-index: 3;
    }

    thead,
    th {
      font-weight: 800 !important;
      background: #f3f5f7 !important;
      color: #0e2a47 !important;
      white-space: nowrap;
      vertical-align: middle;
    }

    td,
    th {
      text-rendering: optimizeLegibility;
      border: 1px solid #e3e8ee !important;
      min-width: 50px;
      position: relative !important;
      background: inherit;
    }

    tr {
      background: inherit;
      background: #fff !important;

      &:nth-child(even) {
        background: #f3f5f7 !important;
      }

      &:hover {
        td {
          background-color: rgba(223, 233, 242, 1) !important;
        }
      }
    }
    tbody {
      tr {
        td {
          color: #0e2a47 !important;
          width: auto !important;

          &:first-child {
            font-weight: 800 !important;
            color: #4670b2 !important;
          }
        }
      }
    }
  }
}
