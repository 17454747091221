@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

$font: 'Myriad', sans-serif;
$font-secondary: 'Source Sans Pro', sans-serif;

$gray: #878787;
$blue: #0d74af;

$table-width: 100%;
$tbody-height-max: 50vh;

#invoiceCss {
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  label,
  small,
  input,
  textarea,
  span,
  tr,
  td,
  th {
    font-family: $font !important;
    font-weight: 300;
    color: #252525;
    margin: 0px;
  }

  h1 {
    font-family: $font-secondary !important;
    font-weight: 300;
    font-size: 2.2em;
  }

  h3 {
    color: black;
    font-family: 'Myriad', sans-serif !important;
    font-weight: 300;
    font-size: 1.5em;
  }

  a {
    text-decoration: none;
  }

  p,
  textarea,
  input {
    font-family: $font !important;
    font-size: 1.1em !important;
    font-weight: 400 !important;
    color: #3e3e3e;

    &::placeholder {
      font-family: $font !important;
      color: #b5b5b5 !important;
    }
    &.muted {
      color: $gray;
    }
    &.bold {
      font-weight: 600 !important;
      color: black !important;
    }
    &.text-blue {
      color: $blue !important;
    }
  }

  label {
    font-family: $font-secondary !important;
    font-size: 1em;
    letter-spacing: 0.01ch;
    font-weight: 500;
    color: $gray !important;
  }

  .ic-header {
    padding: 24px;
    padding-bottom: 0px;
  }

  .ic-tabs {
    display: flex;
    flex-direction: row;
    padding: 0px 16px;

    .ic-tab {
      opacity: 0.6;
      display: inline-flex;
      padding: 12px;
      margin-right: 32px;
      transition: all 200ms ease;

      &:hover {
        background: #fafafa;
      }

      &.active {
        opacity: 1;
        border-bottom: 2px solid $blue;
        h3 {
          color: $blue !important;
        }
      }

      .ic-link {
        display: flex;
      }
    }
  }

  .ic-footer {
    display: flex;
    flex-direction: row;
    padding: 24px;
    background: white;

    .left-com {
      padding-right: 24px;
    }

    .right-com {
      width: 100%;
      max-width: 400px;
      padding-left: 24px;
      border-left: 1px solid #e4e4e4;
      overflow: hidden;
      list-style: none;

      .ic-total-item {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;

        &:before {
          font-family: $font-secondary !important;
          font-weight: 400;
          font-size: 1em;
          color: #ccc;
          float: left;
          white-space: nowrap;
          content: '. . . . . . . . . . . . . . . . . . . . '
            '. . . . . . . . . . . . . . . . . . . . ' '. . . . . . . . . . . . . . . . . . . . ';
        }
      }

      p {
        position: absolute;

        &:first-child {
          left: 0;
          padding-right: 12px;
          background: white;
        }

        &:last-child {
          right: 0;
          padding-left: 0.33em;
          background: white;
        }
      }
    }
  }
}

// how to get header and columns lined up
// and still work with text-overflow
// https://stackoverflow.com/a/41541581
@mixin table-like {
  display: table;
  table-layout: fixed;
  width: $table-width;
}

#ic-table {
  @include table-like;
  background: #f1f1f1;
  position: relative;
  border-collapse: collapse;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  th,
  td {
    background: white;
    border: 1px solid #e4e4e4 !important;
    box-sizing: border-box;
    padding: 12px 8px;
    width: 15%;

    &.product-col {
      width: 20%;
    }
    &.value-col {
      width: 10%;
    }
  }

  tr {
    &.pointer:hover td {
      background: #fafafa;
      transition: all 200ms ease;
    }

    &.disabled td {
      cursor: not-allowed;
    }
  }

  thead {
    @include table-like;

    th {
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
  }

  tbody {
    background: #f1f1f1;
    display: block;
    overflow: auto;
    width: 100%;
    max-height: $tbody-height-max;
    z-index: 1;

    tr {
      @include table-like;
    }

    &::after {
      position: absolute;
      bottom: 0px;
      pointer-events: none;
      z-index: 999;
      content: '';
      display: block;
      width: 100%;
      height: 24px;
      background: -moz-linear-gradient(
        top,
        rgba(191, 191, 191, 0) 0%,
        rgba(191, 191, 191, 0) 11%,
        rgba(191, 191, 191, 0.3) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(191, 191, 191, 0) 0%,
        rgba(191, 191, 191, 0) 11%,
        rgba(191, 191, 191, 0.3) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(191, 191, 191, 0) 0%,
        rgba(191, 191, 191, 0) 11%,
        rgba(191, 191, 191, 0.3) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00bfbfbf', endColorstr='#a6bfbfbf',GradientType=0 ); /* IE6-9 */
    }
  }
}
