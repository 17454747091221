// cleaned
$font-primary: 'Myriad', sans-serif;
$font-prim: 'Myriad', sans-serif;
$border-color: #f0f0f0;
$muted-color: #888;
$pop: #2b98f0;
$pop-text: #0863cc;
$background: #f2f2f2;

/***** RTREE PICKER STYLE***/
#ClientSkills {
  position: relative;
  width: 100%;
  height: 66vh;
  display: block;
  .cs-container {
    .gradient-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100px;
      z-index: 20;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    .left-fixed {
      position: absolute;
      width: 25%;
      margin-right: 5%;
    }
    .right-fixed {
      max-height: 100%;
      width: 70%;
      right: 0;
      overflow: auto;
      position: absolute;
      padding-bottom: 10em;
    }
  }

  .check-list {
    #check-item.selected {
      p {
        color: #0fd0b1 !important;
      }
    }

    #check-item.notSelected {
      p {
        color: #848f99 !important;
      }
    }

    #check-item {
      padding: 0.5em 0em;

      p {
        display: inline-block;
        line-height: 1px;
      }
      .check-icon {
        color: #888;
        display: inline-block;
        width: 25px;
        height: 25px;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #888;
        background: white;
        transition: all 200ms ease;
        cursor: pointer;
        margin-right: 1em;

        &:hover {
          color: #25e8c8;
          border-color: #25e8c8;
          background: #e2fffa;
        }
        &.completed {
          color: #fff;
          border-color: #25e8c8;
          background: #25e8c8;
        }
      }
    }
    .child-item {
      margin-left: 2em;
      cursor: pointer;
      .sub-item {
        background: white;
        border-bottom: 1px solid #ddd;

        .check-list {
          .child-item {
            .sub-item {
              margin-left: 1em;
              border: none;

              .check-list .child-item .sub-item .check-list {
                border: 1px solid #f0f0f0;
                border-radius: 5px;
                padding: 0.2em 1em;
                margin-bottom: 0.2em;

                &:hover {
                  background: #fafafa;
                }
              }

              .check-list .child-item .sub-item .check-list .child-item .sub-item .check-list {
                border: none;
                border-radius: 5px;
                padding: 0em;
              }

              .check-list #check-item .check-icon {
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 14px;
              }
              .check-list #check-item p {
                font-size: 16px;
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
  .cl-tags {
    margin-bottom: 3em;

    .badge {
      background: #313843;
      color: white;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      margin: 0.1em;
      padding: 0em 1em;
      line-height: 3em;
    }
  }
}
/***** END OF RTREE PICKER STYLE***/

/***** CLIENT PROFILE  STYLE***/
#ClientProfile {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.Client_UserProfile {
    .cp-title {
      display: block !important;
    }
    .cp-tabs {
      ul li {
        @media (max-width: 767px) {
          padding: 0em 2em;
          width: auto;
          text-align: center;
          white-space: nowrap;

          svg {
            display: inline-block;
            width: 18px;
            margin-right: 1em;
          }
          span {
            display: inline-block;
          }
        }
      }
    }
  }
  .fixed {
    position: absolute;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0px 0px 32px -11px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 0px 0px 32px -11px rgba(0, 0, 0, 0.63);
    box-shadow: 0px 0px 32px -11px rgba(0, 0, 0, 0.63);
  }
  .container {
    max-width: 90% !important;

    @media (max-width: 567px) {
      max-width: 95% !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label,
  small {
    font-family: $font-primary !important;
  }

  /***** FORM GROUP STYLES *********/

  .form-group label {
    color: #333 !important;
    text-transform: uppercase;
    font-size: 13px;
  }

  input.form-control {
    border: 1px solid #9ca6af;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background: white;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: black;
      border-color: darken(#9ca6af, 10%);
      box-shadow: inset 0 1px #e0e6e8 !important;
    }
  }

  select.form-control {
    border: 1px solid #e0e7ee;
    border-radius: 0.2em;
    -webkit-appearance: none;
    color: #475a71;
    background-image: url('../img/down-angle.svg');
    background-repeat: no-repeat;
    background-position: 98% 50%;
    background-size: 12px;
    &:hover {
      border-color: darken(#e0e7ee, 10%);
    }
  }

  .blue-text {
    color: $pop-text !important;
  }
  /***** FORM GROUP STYLES *********/

  .cp-header {
    border-bottom: 1px solid $border-color;
    color: $muted-color;
    background: white;
    width: 100%;

    .p-address {
      color: #888;
      font-size: 14px;
      display: inline-block;

      @media (max-width: 767px) {
        display: none;
      }
    }
    .btn-primary {
      font-family: $font-primary;
      font-size: 14px;
      border-radius: 5px;
      color: #888;
      font-weight: 600;
      border-radius: 50px;
      border: 2px solid #888 !important;
      background: white !important;
      cursor: pointer;

      &:hover {
        border-color: $pop !important;
        color: $pop !important;
      }
    }

    .name-col,
    .contact-col {
      padding: 1em;
    }

    .contact-col {
      text-align: left;
      overflow: hidden;
      border-left: 1px solid $border-color;
      white-space: wrap !important;

      @media (max-width: 767px) {
        display: none;
      }
      label {
        color: $muted-color !important;
        font-size: 12px !important;
        text-transform: uppercase;
      }
      p {
        word-break: break-all;
        font-size: 14px;
      }

      @media (max-width: 767px) {
        padding: 1em 0em;
        border: none;
        border-top: 1px solid $border-color;
      }

      .text-blue {
        color: $pop;
      }
    }
    .flex-row {
      align-items: center;
      align-self: center;
      align-content: center;
      height: 100%;
      vertical-align: middle;
      justify-content: space-between;
      width: 100%;
    }

    h5 {
      color: black;
      font-size: 18px !important;
      font-weight: 500;
      letter-spacing: normal;
      display: inline-block;
      margin: 0;
    }

    .proStatus {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
      margin-left: 1em;
      line-height: 1em;
      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 0.4em;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: #69b6d5;
      }

      &.applicant,
      &.backgroundScreening {
        &::before {
          background: #0076ff;
        }
      }

      &.inactive,
      &.notServingNow {
        &::before {
          background: #f1c57c;
        }
      }

      &.active {
        &::before {
          background: #1fba14;
        }
      }

      &.rejected,
      &.terminated {
        &::before {
          background: #d0011b;
        }
      }
    }

    .badge {
      font-family: $font-primary !important;
      background-color: rgb(240, 240, 240);
      color: rgb(164, 164, 164);
      padding: 0.5em 1em;
      border-radius: 5px;
      font-size: 12px;
      text-transform: capitalize;

      &.business {
        background: none;
        padding: 0em;
        color: black;
        font-size: 14px;
        font-weight: 500;
        margin-right: 1em;
      }
    }
  }

  .cp-tabs {
    background: white;
    position: relative;
    display: block;
    transition: all 200ms ease;

    ul {
      -webkit-margin-before: 0em;
      -webkit-margin-after: 0em;
      -webkit-margin-start: 0px;
      -webkit-margin-end: 0px;
      -webkit-padding-start: 0px;

      li {
        list-style: none;
        display: inline-block;
        padding: 0em 1em;

        img {
          display: inline-block;
        }

        svg {
          width: 12px;
          margin-right: 0.5em;
          opacity: 0.5;
        }
        @media (max-width: 767px) {
          padding: 0em;
          width: calc(100% / 5);
          text-align: center;
          white-space: nowrap;

          svg {
            display: block;
            margin: 0px auto;
            width: 18px;
          }
          span {
            display: none;
          }
        }
        .nav-link {
          font-size: 12px;
          font-weight: 800;
          text-transform: uppercase;
          padding: 1em 0;
          border-bottom: 4px solid transparent;
          display: flex;
          align-items: center;
          &.active {
            color: black;
            border-bottom: 4px solid $pop;
          }
        }
      }
    }
  }

  .cp-body {
    padding-bottom: 8em;
    padding-top: 1rem;
    background: $background;
    height: 100vh;
    overflow: auto;
  }

  .cp-body .table {
    font-family: $font-prim;
    width: 100%;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    small,
    span,
    button,
    ul,
    li,
    .badge {
      font-family: $font-prim;
      font-weight: 500;
      margin: 0;
    }

    td,
    th {
      font-size: 15px;
      padding: 0.75em 0.5em;
      color: #556b8c;
      font-family: $font-prim;
    }

    td {
      border-bottom: 1px solid #dde2e9 !important;
      color: #1e3a5e !important;
    }

    thead {
      background: #f4f6f9;
      tr {
        th {
          font-weight: 600;
          color: black;
        }
      }
    }

    tbody {
      tr {
        background: white !important;

        &:hover {
          td:first-child {
            color: #2690ec !important;
            transition: all 200ms ease;
          }
        }

        td:first-child {
          font-weight: 600;
        }

        &:nth-child(even) {
          background: #fcfcfd !important;
        }
      }
    }
  }

  .cp-profile {
    background: #fff;
    padding: 2em;
    margin-bottom: 1em;
    h5 {
      text-align: center;
    }

    &:nth-child(even) {
      margin-top: 1em;
    }
  }

  .cp-tasks {
    h5 {
      margin-bottom: 1em;
    }
    .cp-taskItem {
      padding: 2em;
      background: white;
      //border-radius: 5px;
      //border: 1px solid #ddd;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5em;
      justify-content: space-between;
      color: black !important;

      &:hover {
        background: #fafafa;
      }
    }

    .c-service,
    .timestamp {
      color: $muted-color;
      font-size: 14px;
    }

    .flex-end {
      text-align: right;
    }

    .badge {
      display: inline-block;
      font-size: inherit;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 1em;
      font-size: 14px;
      padding: 0.5em 1em;
      margin: 0;
      border: 1px solid #ddd;
      color: #222;
      border-radius: 50px;
    }
  }

  .user--profile {
    display: block;
    background: none;
    padding: 0;
    h5 {
      text-align: center;
    }

    .cp-card {
      background: white;
      max-width: none !important;
      margin: 0px auto;
      width: 100%;
      display: block;
      margin-bottom: 1em;
      padding: 2em;

      h5 {
        margin-bottom: 2em;
      }
      .existing-card {
        border: 1px solid #ddd;
        border-radius: 5px;
        background: #fff;
        display: flex;
        padding: 0.5em;
        margin: 0.5em 0em;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        img {
          height: 35px;
          margin-right: 1em;
        }
        .cp-number {
          color: #222 !important;
          .cp-brand {
            color: black !important;
            text-transform: uppercase;
            font-weight: 800;
          }
        }

        .cp-expiration {
          color: #888 !important;
        }
      }

      .empty-card {
        text-align: center;
        padding-bottom: 2em;
        img {
          width: 120px;
          text-align: center;
          margin: 0px auto;
          margin-bottom: 1em;
          margin-top: 1em;
        }

        h6 {
          text-transform: uppercase;
          color: black;
        }

        p {
          color: $muted-color;
          font-weight: 500;
        }
      }

      .cp-title {
        display: none;
      }
    }
  }
}

.copy-to-clipboard {
  position: relative;
  display: flex;
  white-space: nowrap;
  justify-content: space-between;

  &.gray {
    .copy-select {
      color: #888;
    }
  }

  &.inline {
    .copy-select {
      display: inline;
      float: left;
      margin-right: 1em;
    }
    .copy-btn {
      display: inline;
      float: none;
    }
  }

  .copy-select {
    color: $pop;
    display: inline-block;
    white-space: nowrap !important;
    flex: 1;
  }
  .copy-btn {
    background: none;
    border: none;
    color: #888;
    display: inline-block;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    border: 1px solid #ddd;
    text-align: center;
    transition: all 200ms ease;
    display: inline-flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;

    svg {
      width: 12px;
      margin-top: -2px;
    }

    &:hover {
      color: $pop;
      background: lighten($pop, 40%);
      border-color: $pop;
    }
  }
  #dAlert {
    position: absolute;
    z-index: 99;
    top: 30%;
    right: 50%;

    .dismissable-alert {
      -webkit-animation: seconds 100ms forwards;
      -webkit-animation-iteration-count: 1;
      -webkit-animation-delay: 800ms;
      animation: seconds 100ms forwards;
      animation-iteration-count: 1;
      animation-delay: 800ms;
      position: absolute;
      background: #222;
      color: white;
      font-family: 'Gotham', sans-serif;
      font-weight: 500;
      font-size: 11px;
      border-radius: 5px;
      padding: 0.5em 1em;
      z-index: 99999;
    }
    @-webkit-keyframes seconds {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        margin-top: -0.5em;
      }
    }
    @keyframes seconds {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
        margin-top: -0.5em;
      }
    }
  }
}

.adminLayout {
  .user--profile {
    display: block;
    position: relative;
    background: #f2f2f2;
    padding: 2em;

    h5 {
      text-align: center;
    }

    .cp-card {
      background: white;
      margin: 0px auto;
      width: 100%;
      display: block;
      margin-bottom: 1em;
      padding: 2em;
      max-width: 500px;

      h5 {
        margin-bottom: 2em;
      }
      .existing-card {
        border: 1px solid #ddd;
        border-radius: 5px;
        background: #fff;
        display: flex;
        padding: 0.5em;
        margin: 0.5em 0em;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        img {
          height: 35px;
          margin-right: 1em;
        }
        .cp-number {
          color: #222 !important;
          .cp-brand {
            color: black !important;
            text-transform: uppercase;
            font-weight: 800;
          }
        }

        .cp-expiration {
          color: #888 !important;
        }
      }

      .empty-card {
        text-align: center;
        padding-bottom: 2em;
        img {
          width: 120px;
          text-align: center;
          margin: 0px auto;
          margin-bottom: 1em;
          margin-top: 1em;
        }

        h6 {
          text-transform: uppercase;
          color: black;
        }

        p {
          color: $muted-color;
          font-weight: 500;
        }
      }

      .cp-title {
        display: block;
      }
    }
  }
}

.sty-f1 {
  //background: white;
  padding: 1em 1.5em;
  //border: 1px solid $border-color;
  overflow: hidden;
  margin-top: -1px;

  &.header {
    text-align: left;
    justify-content: center;
    h5 {
      font-size: 24px !important;
      margin: 0;
      line-height: 1.5em !important;
    }
    small {
      color: #939393 !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      margin: 0 !important;
      line-height: 1em !important;
    }
  }

  label {
    color: #939393 !important;
    font-weight: 400 !important;
    font-size: 0.857rem !important;
    line-height: 1.66667 !important;
    margin: 0 !important;
  }
  p {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
